import { Component, Input } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Interval, IntervalStatistics } from '../../core/models/interval'
import { PlatformService } from '../../core/services/platform.service'

export type SelectedChoice = 'TRACK_ONE' | 'TRACK_TWO' | 'SUM'

@Component({
  selector: 'app-interval-mobile',
  templateUrl: './interval-mobile.component.html',
  styleUrls: ['./interval-mobile.component.scss'],
})
export class IntervalMobileComponent {
  @Input() interval: Observable<Interval>
  @Input() intervalStatistics: Observable<IntervalStatistics>
  selectedChoice: SelectedChoice = 'SUM'
  selectedChoiceSubject: BehaviorSubject<SelectedChoice> = new BehaviorSubject<SelectedChoice>(this.selectedChoice)
  selectedChoiceObservable: Observable<SelectedChoice> = this.selectedChoiceSubject.asObservable()

  constructor(public platformService: PlatformService) {}

  toggle(selectedChoice: SelectedChoice) {
    this.selectedChoiceSubject.next(selectedChoice)
  }
}
