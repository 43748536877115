import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Display } from '../../shared/full-screen-button/full-screen-button.component'

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  titleStateSubject: BehaviorSubject<Display> = new BehaviorSubject<Display>('NORMAL')
  state$ = this.titleStateSubject.asObservable()

  constructor() {}
}
