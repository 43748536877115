<ion-card>
  <ion-card-content>
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-justify-content-between">
        <ion-col>
          <div class="cartridge-type">
            {{ 'app.shared.dashboard.production.cartridge.' + peakPeriodKey | translate }}
          </div>
        </ion-col>
      </ion-row>

      <!-- DESKTOP & TABLET -->
      <ion-row *ngIf="!platformService.isPlatformMobile(); else mobile" [class.tablet-detail]="platformService.isPlatformTablet()">
        <ion-col size="5">
          <div [ngClass]="peakPeriodStatistics?.peakPeriodLevelColor" class="cartridge-percentage">
            <span>{{ peakPeriodStatistics?.percentage * 100 | number: '2.0-0' | NonZeroDisplay }}</span>
            <span *ngIf="peakPeriodStatistics.percentage" class="percentage-symbol">%</span>
          </div>
        </ion-col>
        <ion-col class="col-detail" size="7">
          <ion-row>
            <ion-col>
              <div class="cartridge-title">
                {{ 'app.shared.dashboard.production.cartridge.theoretical' | translate }}
              </div>
            </ion-col>
            <ion-col>
              <div class="cartridge-title">
                {{ 'app.shared.dashboard.production.cartridge.real' | translate }}
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <div class="cartridge-detail">
                {{ peakPeriodStatistics?.totalTheoretical | NonZeroDisplay }}
              </div>
            </ion-col>
            <ion-col>
              <div [ngClass]="peakPeriodStatistics?.peakPeriodLevelColor" class="cartridge-detail">
                {{ peakPeriodStatistics?.totalReal | NonZeroDisplay }}
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- MOBILE -->
      <ng-template #mobile>
        <ion-row>
          <ion-col>
            <div [ngClass]="peakPeriodStatistics?.peakPeriodLevelColor" class="cartridge-percentage-mobile">
              <span class="percentage-number">{{ peakPeriodStatistics?.percentage * 100 | number: '2.0-0' | NonZeroDisplay }}</span>
              <span *ngIf="peakPeriodStatistics.percentage" class="percentage-symbol">%</span>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-row>
              <ion-col>
                <div class="cartridge-title-mobile">
                  {{ 'app.shared.dashboard.production.cartridge.theoretical' | translate }}
                </div>
              </ion-col>
              <ion-col>
                <div class="cartridge-title-mobile">
                  {{ 'app.shared.dashboard.production.cartridge.real' | translate }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="cartridge-detail-mobile">
                  {{ peakPeriodStatistics?.totalTheoretical | NonZeroDisplay }}
                </div>
              </ion-col>
              <ion-col>
                <div [ngClass]="peakPeriodStatistics?.peakPeriodLevelColor" class="cartridge-detail-mobile">
                  {{ peakPeriodStatistics?.totalReal | NonZeroDisplay }}
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ng-template>
    </ion-grid>
  </ion-card-content>
</ion-card>
