<div *ngIf="!platformService.isPlatformMobile(); else mobile">
  <div *ngFor="let trafficSituation of trafficSituations" class="disruptions">
    <ion-card
      [ngClass]="{
        'ion-card': !platformService.isPlatformMobile() && !platformService.isPlatformTablet(),
        'ion-card-tablet': platformService.isPlatformTablet()
      }"
    >
      <ion-card-content>
        <ion-row>
          <ion-col>
            <ion-row>
              <span [ngClass]="{
              'no-impact': trafficSituation.severity == 'NO_IMPACT',
              'slight': trafficSituation.severity == 'SLIGHT',
              'severe': trafficSituation.severity == 'SEVERE',
              'very-severe': trafficSituation.severity == 'VERY_SEVERE',
              'undefined': trafficSituation.severity == 'UNDEFINED',
              'unknown': trafficSituation.severity == 'UNKNOWN'
              }"
                    class="severity"
              >
                {{ trafficSituation.conditionLabel }}
              </span>
            </ion-row>
            <ion-row>
              <span class="disruption-traffic-situation-title">
              {{displayImpactedPlaces(trafficSituation)}}
            </span>
            </ion-row>
            <ion-row *ngIf="trafficSituation.messageText" class="disruption-traffic-situation-detail">
              {{ trafficSituation.messageText | truncate: [200] }}
            </ion-row>
            <ion-row class="disruption-traffic-situation-hour">
              <div>{{ trafficSituation.changed | date: 'dd/MM/yyyy à HH:mm':'ZZZZZ':'fr' }}</div>
              <a class="button-live" target="_blank" href="{{getLiveIVUrl(trafficSituation.eventId)}}">
                <span>+ d'info sur</span>
                <ion-icon src="./assets/icon/live.svg"></ion-icon>
              </a>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </div>
</div>

<ng-template #mobile>
  <div *ngFor="let trafficSituation of trafficSituations">
    <ion-card class="ion-card-mobile">
      <ion-card-content>
        <ion-row>
          <ion-col>
            <ion-row [ngClass]="{
            'no-impact': trafficSituation.severity == 'NO_IMPACT',
            'slight': trafficSituation.severity == 'SLIGHT',
            'severe': trafficSituation.severity == 'SEVERE',
            'very-severe': trafficSituation.severity == 'VERY_SEVERE',
            'undefined': trafficSituation.severity == 'UNDEFINED',
            'unknown': trafficSituation.severity == 'UNKNOWN'
            }"
                     class="severity"
            >
              {{ trafficSituation.conditionLabel }}
            </ion-row>
            <ion-row class="disruption-traffic-situation-title-mobile">
              {{displayImpactedPlaces(trafficSituation)}}
            </ion-row>
            <ion-row *ngIf="trafficSituation.messageText" class="disruption-traffic-situation-detail-mobile">
              {{ trafficSituation.messageText | truncate: [80] }}
            </ion-row>
            <ion-row class="disruption-traffic-situation-hour-mobile">
              <div>{{ trafficSituation.changed | date: 'dd/MM/yyyy à HH:mm':'ZZZZZ':'fr' }}</div>
              <a class="button-live" target="_blank" href="{{getLiveIVUrl(trafficSituation.eventId)}}">
                <span>+ d'info sur</span><ion-icon src="./assets/icon/live.svg"></ion-icon>
              </a></ion-row>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </div>
</ng-template>
