import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'NonZeroDisplay' })
export class NonZeroDisplayPipe implements PipeTransform {
  transform(value: number | string): string {
    if (typeof value === 'string') {
      value = Number.parseFloat(value)
    }
    if (value === 0) {
      return '-'
    }
    return value.toString()
  }
}
