import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Disruption } from '../../core/models/disruptions'
import { TranslateService } from '@ngx-translate/core'
import { PlatformService } from '../../core/services/platform.service'

export interface DisruptionWithTitle extends Disruption {
  title: string
}

@Component({
  selector: 'app-disruption',
  templateUrl: './disruption.component.html',
  styleUrls: ['./disruption.component.scss'],
})
export class DisruptionComponent {
  @Input() disruptionHistory: { date: string; disruptions: Disruption[] }
  @Output() disruptionClick: EventEmitter<DisruptionWithTitle> = new EventEmitter<DisruptionWithTitle>()

  constructor(public platformService: PlatformService, private translate: TranslateService) {}

  getTitleDisplay(disruption: Disruption) {
    let message = ''
    const time = disruption.eventTime ? disruption.eventTime.slice(0, 5) : null
    const cause = disruption.sanitizedCause ? disruption.sanitizedCause[0].toUpperCase() + disruption.sanitizedCause.slice(1) : null

    if (disruption.platforms.length > 0 && !!disruption.track && time && cause) {
      message += this.translate.instant('app.disruption.history.incident.message.full', {
        platform: disruption.platforms.join(' - '),
        track: disruption.track,
        cause,
        time,
      })
    } else if (disruption.platforms.length === 0 && !!disruption.track) {
      message += this.translate.instant('app.disruption.history.incident.message.track', {
        track: disruption.track,
        cause,
        time,
      })
    } else if (disruption.platforms.length > 0) {
      message += this.translate.instant('app.disruption.history.incident.message.platform', {
        platform: disruption.platforms.join(' - '),
        cause,
        time,
      })
    } else if (time && cause) {
      message += this.translate.instant('app.disruption.history.incident.message.cause.and.time', {
        cause,
        time,
      })
    } else {
      message += this.translate.instant('app.disruption.history.incident.message.cause', {
        cause,
      })
    }
    return message
  }

  disruptionClicked(disruption: Disruption) {
    if (!!disruption.detail) {
      this.disruptionClick.emit({
        ...disruption,
        title: this.getTitleDisplay(disruption),
      })
    }
  }
}
