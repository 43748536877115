<ion-grid>
  <ion-row [class.no-padding]="platformService.isPlatformMobile()">
    <ion-col [class.no-padding]="platformService.isPlatformMobile()">
      <ion-row [class.no-padding]="platformService.isPlatformMobile()">
        <ion-col size="12">
          <app-tk-statistics [tkStatistics]="(this.tkOffPeakHour$ | async) && (this.tkOffPeakHour$ | async).tkStatistics" [peakPeriodConfig]="peakPeriodConfig" [tkProductionSubject]="tkProductionSubject" [productionInvalid]="(this.tkOffPeakHour$ | async).productionInvalid"></app-tk-statistics>
        </ion-col>
      </ion-row>
      <ion-row [class.no-padding]="platformService.isPlatformMobile()">
        <ion-col size="12">
          <app-tk-detail [tk]="this.tkOffPeakHour$ | async" [peakPeriodConfig]="peakPeriodConfig" (tkProductionSubject)="tkProductionSubject.next($event)"></app-tk-detail>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-row [class.ion-justify-content-center]="platformService.isPlatformDesktop()">
  <div [class.wrapper-header]="platformService.isPlatformDesktop()" class="wrapper-header-mobile">
    <app-tk-off-peak-hour-schedule [schedules]="tkOffPeakHourConfig" [periodType]="'HC'"> </app-tk-off-peak-hour-schedule>
  </div>
</ion-row>
