import { PeakPeriodConfig } from '../core/models/peak-periodConfig'

export function getLevelColorFromPercentage(eveningPercentage: number, value: PeakPeriodConfig): string {
  const percentage = eveningPercentage * 100
  switch (true) {
    case percentage === 0:
      return ''
    case percentage < value.thresholds.alert:
      return 'red tkLine'
    case percentage < value.thresholds.warning:
      return 'yellow tkLine'
    case percentage >= value.thresholds.warning:
      return 'green tkLine'
    default:
      return ''
  }
}
