import PathFollowerPlugin from 'phaser3-rex-plugins/plugins/pathfollower-plugin'
import GesturesPlugin from 'phaser3-rex-plugins/plugins/gestures-plugin.js'

export const phaserPlugins = {
  scene: [
    {
      key: 'rexGestures',
      plugin: GesturesPlugin,
      mapping: 'rexGestures',
    },
  ],
  global: [
    {
      key: 'rexPathFollower',
      plugin: PathFollowerPlugin,
      start: true,
    },
  ],
}
