import { environment } from '../../../environments/environment'
import { StompStoreService } from '../services/sockets/stomp-store.service'
import { StompConfig } from '@stomp/stompjs'

export function stompConfig(stompStoreService: StompStoreService): StompConfig {
  return {
    heartbeatIncoming: 0,
    heartbeatOutgoing: 20000,
    reconnectDelay: 10000,
    onWebSocketClose: (message) => stompStoreService.stompStoreSubject.next(message),
    onWebSocketError: (message) => stompStoreService.stompStoreSubject.next(message),
    onChangeState: (activationState) => stompStoreService.connectionStateSubject.next(activationState),
    debug: (str) => {
      if (environment.stompDebug) {
        console.log(str)
      }
    },
  }
}
