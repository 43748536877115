<div *ngIf="!platformService.isPlatformMobile(); else mobile">
  <div class="disruption-date">
    {{ disruptionHistory.date | date: 'EEEE d MMMM' | capitalize }}
  </div>
  <div *ngFor="let disruption of disruptionHistory.disruptions" class="disruptions">
    <ng-container [ngTemplateOutletContext]="{ line: disruption.line }" [ngTemplateOutlet]="transversalFlash"></ng-container>

    <ion-card
      [ngClass]="{
        'ion-card': !platformService.isPlatformMobile() && !platformService.isPlatformTablet(),
        'ion-card-tablet': platformService.isPlatformTablet()
      }"
      (click)="disruptionClicked(disruption)"
      [class.clickable]="!!disruption.detail"
    >
      <ion-card-content>
        <ion-row>
          <ion-col class="disruption-hour" size="1">
            <ion-row>
              {{ disruption.ingestionTime | date: 'shortTime':'ZZZZZ':'fr' }}
            </ion-row>
          </ion-col>

          <ion-col>
            <ion-row class="disruption-title">
              {{ getTitleDisplay(disruption) }}
            </ion-row>

            <ion-row *ngIf="disruption.detail" class="disruption-detail">
              {{ disruption.detail | truncate: [200] }}
            </ion-row>
          </ion-col>

          <ion-col size="1">
            <ion-row *ngIf="disruption.detail" class="to-detail">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </div>
</div>

<ng-template #mobile>
  <div class="disruption-date-mobile">
    {{ disruptionHistory.date | date: 'EEEE d MMMM' | titlecase }}
  </div>
  <div *ngFor="let disruption of disruptionHistory.disruptions">
    <ng-container [ngTemplateOutletContext]="{ line: disruption.line }" [ngTemplateOutlet]="transversalFlash"></ng-container>

    <ion-card class="ion-card-mobile" (click)="disruptionClicked(disruption)">
      <ion-card-content>
        <ion-row>
          <ion-col>
            <ion-row class="disruption-title-mobile">
              {{ getTitleDisplay(disruption) }}
            </ion-row>

            <ion-row *ngIf="disruption.detail" class="disruption-detail-mobile">
              {{ disruption.detail | truncate: [80] }}
            </ion-row>

            <ion-row class="disruption-hour-mobile">
              {{ disruption.ingestionTime | date: 'HH:mm':'ZZZZZ':'fr' }}
            </ion-row>
          </ion-col>

          <ion-col size="1">
            <ion-row *ngIf="disruption.detail" class="to-detail">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </div>
</ng-template>

<ng-template #transversalFlash let-line="line">
  <div *ngIf="line === 'GLOBAL'" class="global">
    <span>{{ 'app.disruption.history.flash' | translate }}</span
    > <span class="bolder">{{ 'app.disruption.history.transversal' | translate }}</span>
  </div>
</ng-template>
