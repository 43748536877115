import { Component } from '@angular/core'
import { MenuController } from '@ionic/angular'
import { SceneType } from '../../../phaser/managers/scene.manager'
import { UrlService } from '../../core/services/url.service'
import { UserService } from '../../core/security/user.service'
import { PlatformService } from '../../core/services/platform.service'

@Component({
  selector: 'app-menu-side',
  templateUrl: './menu-side.component.html',
  styleUrls: ['./menu-side.component.scss'],
})
export class MenuSideComponent {
  constructor(private menu: MenuController, public platformService: PlatformService, public urlService: UrlService, public userService: UserService) {}

  close() {
    this.menu.close(SceneType.MAIN).then()
  }
}
