import { TIME_WINDOW_NUMBER_TEXT_FONT, TIME_WINDOW_NUMBER_TEXT_SIZE } from '../../../configs/configuration.text'
import { TrafficWindow } from '../../../modeles/lines'
import Phaser from 'phaser'
import { ColorPhaser } from '../../../modeles/color'
import { SecondsToMinutesPipe } from '../../../../app/shared/pipe/seconds-to-minutes.pipe'
import { Platform, PlatformStateType, TrafficStateType } from '../../../../app/core/models/platform-info-traffic'
import { SelectedPlatformInfo } from '../../../../app/core/configurations/user/selected.platform.info'

interface TimeDisplayElementOption {
  visible: boolean
}

const WINDOW_TEXT_INFORMATION = 'WINDOW_TEXT_INFORMATION'

export class TimeDisplayElementGeom extends Phaser.Geom.Rectangle {
  constructor() {
    super(0, 0, 44, 20)
  }
}

export class PlatformDisplayElement extends Phaser.GameObjects.Graphics {
  constructor(scene: Phaser.Scene, timeDisplayElementOption: TimeDisplayElementOption) {
    super(scene, { fillStyle: { color: ColorPhaser.TIME_DISPLAY.valueOf() } })
    this.visible = timeDisplayElementOption.visible
    this.fillRectShape(new TimeDisplayElementGeom())
  }
}

export class PlatformClosedDisplayElement extends Phaser.GameObjects.Image {
  constructor(scene: Phaser.Scene) {
    const X_OFFSET = 22
    const Y_OFFSET = 10
    super(scene, X_OFFSET, Y_OFFSET, '')
    this.setTexture('PLATFORM_CLOSED')
  }
}

export class PlatformTrack2ReferentialStationDisplayElement extends Phaser.GameObjects.Image {
  constructor(scene: Phaser.Scene, timeDisplayElementOption: TimeDisplayElementOption) {
    const X_OFFSET = -10
    const Y_OFFSET = 10
    super(scene, X_OFFSET, Y_OFFSET, '')
    this.visible = timeDisplayElementOption.visible
    this.setTexture('REFERENTIAL_STATION')
  }
}

export class PlatformTrack1ReferentialStationDisplayElement extends Phaser.GameObjects.Image {
  constructor(scene: Phaser.Scene, timeDisplayElementOption: TimeDisplayElementOption) {
    const X_OFFSET = 54
    const Y_OFFSET = 10
    super(scene, X_OFFSET, Y_OFFSET, '')
    this.visible = timeDisplayElementOption.visible
    this.setTexture('REFERENTIAL_STATION')
  }
}

export class PlatformDisplayText extends Phaser.GameObjects.BitmapText {
  private previousColor: ColorPhaser
  private previousText: string

  constructor(scene: Phaser.Scene, timeDisplayElementOption: TimeDisplayElementOption) {
    const PLATFORM_TEXT_Y_OFFSET = -3
    const PLATFORM_TEXT_X_OFFSET = 22
    super(scene, PLATFORM_TEXT_X_OFFSET, PLATFORM_TEXT_Y_OFFSET, TIME_WINDOW_NUMBER_TEXT_FONT, '', TIME_WINDOW_NUMBER_TEXT_SIZE)
    this.name = WINDOW_TEXT_INFORMATION
    this.setVisible(timeDisplayElementOption.visible)
    this.setOrigin(0.5, -0.3)
  }

  updateTextDisplay(selectedChoice: SelectedPlatformInfo, platform: Platform) {
    switch (selectedChoice) {
      case SelectedPlatformInfo.NONE:
        this.setVisible(false)
        break
      case SelectedPlatformInfo.ELAPSED:
        this.setVisible(true)
        this.updateTimeIfNeeded(platform.elapsedTime, platform.trafficState)
        this.updateColorIfNeeded(ColorPhaser.ELAPSED_INFORMATION)
        break
      case SelectedPlatformInfo.WAITING:
        this.setVisible(true)
        this.updateTimeIfNeeded(platform.timeToWait, platform.trafficState)
        this.updateColorIfNeeded(ColorPhaser.WAITING_INFORMATION)
        break
    }
  }

  private updateTimeIfNeeded(time: number, trafficState: TrafficStateType) {
    const text = trafficState === TrafficStateType.INTERRUPTED ? '/' : new SecondsToMinutesPipe().transform(time)
    if (text !== this.previousText) {
      this.setText(text)
      this.previousText = text
    }
  }

  private updateColorIfNeeded(color: ColorPhaser) {
    if (!!color && color !== this.previousColor) {
      this.setTint(Number(color.valueOf()))
      this.previousColor = color
    }
  }
}

export class PlatformDisplayContainer extends Phaser.GameObjects.Container {
  private readonly _trafficWindowId: string
  private readonly _id: string
  private readonly platformDisplayText: PlatformDisplayText
  private readonly platformDisplayElement: PlatformDisplayElement
  private readonly platformClosedDisplayElement: PlatformClosedDisplayElement
  private readonly platformTrack1ReferentialStationDisplayElement: PlatformTrack1ReferentialStationDisplayElement
  private readonly platformTrack2ReferentialStationDisplayElement: PlatformTrack2ReferentialStationDisplayElement
  private _platformState: PlatformStateType = PlatformStateType.OPEN

  constructor(scene: Phaser.Scene, y: number, trafficWindow: TrafficWindow) {
    super(scene, 0, y)

    if (trafficWindow.type === 'LOOP_WITH_PLATFORM') {
      this.setX(this.x + 46)
      this.setY(this.y - 46)
    }

    this._trafficWindowId = trafficWindow.id
    this.platformDisplayText = new PlatformDisplayText(scene, {
      visible: !!trafficWindow.platFormName && !!trafficWindow.platformTimeId,
    })
    this.platformDisplayElement = new PlatformDisplayElement(scene, {
      visible: !!trafficWindow.platFormName && !!trafficWindow.platformTimeId,
    })
    this.platformClosedDisplayElement = new PlatformClosedDisplayElement(scene)

    this.platformTrack1ReferentialStationDisplayElement = new PlatformTrack1ReferentialStationDisplayElement(scene, {
      visible: !!trafficWindow.platFormName && !!trafficWindow.platformTimeId,
    })
    this.platformTrack2ReferentialStationDisplayElement = new PlatformTrack2ReferentialStationDisplayElement(scene, {
      visible: !!trafficWindow.platFormName && !!trafficWindow.platformTimeId,
    })

    this.add([this.platformDisplayElement, this.platformDisplayText])
  }

  get id() {
    return this._id
  }

  get isOpened(): PlatformStateType {
    return this._platformState
  }

  updatePlatformDisplay(selectedChoice: SelectedPlatformInfo, platform: Platform) {
    if (platform.platformState === PlatformStateType.OPEN) {
      if (this._platformState === PlatformStateType.CLOSED) {
        this.replace(this.platformClosedDisplayElement, this.platformDisplayElement)
      }
      if (platform.isReferentialStation) {
        if (platform.track === 1) {
          this.add(this.platformTrack1ReferentialStationDisplayElement)
        }
        if (platform.track === 2) {
          this.add(this.platformTrack2ReferentialStationDisplayElement)
        }
      } else {
        this.remove(this.platformTrack1ReferentialStationDisplayElement)
        this.remove(this.platformTrack2ReferentialStationDisplayElement)
      }
      this.platformDisplayText.updateTextDisplay(selectedChoice, platform)
    } else {
      this.platformDisplayText.updateTextDisplay(SelectedPlatformInfo.NONE, null)
      this.replace(this.platformDisplayElement, this.platformClosedDisplayElement)
    }
    this._platformState = platform.platformState
  }
}
