import { NgModule, Optional, SkipSelf } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { GameProviderService } from './game/game-provider.service'
import { GameConfigurationService } from './game/game-configuration.service'
import { LocalStorageService } from './local-storage.service'
import { LinesMappingService } from './game/services/lines-mapping.service'
import { TrainsService } from './game/services/trains.service'
import { IntervalService } from './services/interval.service'
import { TcoLineMappingService } from './services/tco-line-mapping.service'
import { AnalyticsService } from './services/analytics.service'
import { PlatformService } from './services/platform.service'
import { TitleService } from './services/title.service'

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  providers: [GameProviderService, GameConfigurationService, LocalStorageService, TrainsService, LinesMappingService, IntervalService, TcoLineMappingService, PlatformService, TitleService, AnalyticsService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('Already loaded!!!!!!!!!!!!')
    }
  }
}
