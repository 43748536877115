<ion-button
  (click)="switchDisplayIncident()"
  *ngIf="platformService.isPlatformDesktop() || platformService.isPlatformTablet(); else mobile"
  [ngClass]="{
    'button-selected': userPreferences.selectedIncidents,
    'incident-selected': userPreferences.selectedIncidents
  }"
  class="incident"
>
  <ion-icon [src]="iconUrl" [ngClass]="{ 'icon-selected': userPreferences.selectedIncidents }" class="icon" slot="start"> </ion-icon>
  <ion-label [ngClass]="{ selected: userPreferences.selectedIncidents }">
    {{ 'app.realtime.traffic.platform.info.button.liveEvent' | translate }}
  </ion-label>
</ion-button>
<ng-template #mobile>
  <ion-button
    (click)="switchDisplayIncident()"
    [ngClass]="{
      'button-selected': userPreferences.selectedIncidents,
      'incident-selected': userPreferences.selectedIncidents
    }"
    class="incident-mobile"
  >
    <ion-icon [src]="iconUrl" [ngClass]="{ 'icon-selected': userPreferences.selectedIncidents }" class="icon icon-mobile"> </ion-icon>
  </ion-button>
</ng-template>
