export interface ColorLineMapping {
  id: string
  color: number
}

export const colorLine: ColorLineMapping[] = [
  { id: '1', color: 0xffbf00 },
  { id: '2', color: 0x0051cb },
  { id: '3', color: 0x6e6f00 },
  { id: '4', color: 0xa2006f },
  { id: '5', color: 0xff5900 },
  { id: '6', color: 0x85c18e },
  { id: '7', color: 0xff80b4 },
  { id: '8', color: 0xd480bf },
  { id: '9', color: 0xd2d400 },
  { id: '10', color: 0xde9700 },
  { id: '11', color: 0x6f4919 },
  { id: '12', color: 0x00653b },
  { id: '13', color: 0x7fc8e8 },
  { id: '14', color: 0x650084 },
  { id: '3bis', color: 0x7fc8e8 },
  { id: '7bis', color: 0x85c18e },
  { id: 'A', color: 0xff0a00 },
  { id: 'B', color: 0x368fdf },
]

export enum ColorPhaser {
  LATE = 0xff5b59,
  LATE_HEX = '#FF5B59',
  EARLY = 0x56bd45,
  EARLY_HEX = '#56BD45',
  WHITE = 0xffffff,
  WHITE_HEX = '#FFFFFF',
  BLACK = 0x000000,
  BLACK_HEX = '#000000',
  NO_PASSENGER = 0xededf0,
  NO_PASSENGER_HEX = '#EDEDF0',
  STATION_NAME = 0x0a0082,
  STATION_NAME_HEX = '#0A0082',
  TIME_DISPLAY = 0x666666,
  TIME_DISPLAY_HEX = '#666666',
  WAITING_INFORMATION = 0xfff500,
  WAITING_INFORMATION_HEX = '#FFF500',
  ELAPSED_INFORMATION = 0x82c8e6,
  ELAPSED_INFORMATION_HEX = '#82C8E6',
  GREY_TRAIN_TEXT = 0x303030,
  GREY_TRAIN_TEXT_HEX = '#303030',
  PLATFORM_CLOSED = 0xcc0328,
  PLATFORM_CLOSED_HEX = '#CC0328',
  INTERRUPTED_TRAFFIC = 0xdcdcdc,
  BLUE_DESTINATION = 0x3971ff,
  YELLOW_DESTINATION = 0xfff500,
  OVER_PARKING = 0xcc0328,
  ATTENDANCE_LOW_BACKGROUND = 0x004d41,
  ATTENDANCE_LOW = 0x00aa91,
  ATTENDANCE_HIGH_BACKGROUND = 0x802d00,
  ATTENDANCE_HIGH = 0xff5a00,
  ATTENDANCE_FULL = 0xe61200,
}
