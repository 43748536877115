export interface TcoInformation {
  tcoCdv: TcoCdv
  tcoCdvRer: TcoCdvRer
  tcoTrafficLight: TcoTrafficLight
  tcoSubSection: TcoSubSection
  tcoSwitch: TcoSwitch
}

export interface TcoElementInterface {
  id: number
  displayId: string
  state: any
  trainNumber?: string
}
export interface TcoInterface {
  lineName: string
  list: Array<TcoElementInterface>
}

export interface TcoCdv extends TcoInterface {
  lineName: string
  cdvs: Cdv[]
}

export interface TcoCdvRer extends TcoInterface {
  lineName: string
  cdvs: CdvRer[]
}

export interface Cdv extends TcoElementInterface {
  id: number
  displayId: string
  state: CDV_STATE
  trainNumber?: string
}

export interface CdvRer extends TcoElementInterface {
  id: number
  displayId: string
  state: CDV_STATE
}

export enum CDV_STATE {
  FREE = 'FREE',
  OCCUPIED = 'OCCUPIED',
  UNKNOWN = 'UNKNOWN',
}

// Aiguilles
export interface TcoSwitch extends TcoInterface {
  lineName: string
  switches: Switch[]
}

export interface Switch extends TcoElementInterface {
  id: number
  displayId: string
  state: SWITCH_STATE
}

export enum SWITCH_STATE {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  SPECIAL = 'SPECIAL',
  UNKNOWN = 'UNKNOWN',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  ACTIVATED = 'ACTIVATED',
}

// Feux
export interface TcoTrafficLight extends TcoInterface {
  lineName: string
  trafficLights: TrafficLight[]
}

export interface TrafficLight extends TcoElementInterface {
  id: number
  displayId: string
  state: TRAFFIC_LIGHT_STATE
}

export enum TRAFFIC_LIGHT_STATE {
  GREEN_LIGHT = 'GREEN_LIGHT',
  RED_LIGHT = 'RED_LIGHT',
  UNKNOWN = 'UNKNOWN',
  TURNED_OFF = 'TURNED_OFF',
}

// Sous-Section
export interface TcoSubSection extends TcoInterface {
  lineName: string
  subSections: SubSection[]
}

export interface SubSection extends TcoElementInterface {
  id: number
  displayId: string
  state: SUB_SECTION_STATE
}

export enum SUB_SECTION_STATE {
  POWER_ON = 'POWER_ON',
  POWER_OFF = 'POWER_OFF',
}
