<ion-grid *ngIf="this.currentLine | async as currentLine" class="no-padding">
    <ion-row [class.ion-justify-content-center]="platformService.isPlatformDesktop()" class="no-padding">
        <div class="wrapper-header-mobile">
            <ion-list *ngIf="referentialLines$ | async as referentialLines">
                <div (click)="selectCurrentLine(line)" *ngFor="let line of referentialLines.lines" class="icon">
                    <ion-item class="ion-no-padding item-space" lines="none">
                        <div [ngClass]="stateNetworkLine[line.name]"
                             [class.line-selected]="currentLine.name === line.name" class="line-selector">
                            <ion-icon [class.icon-selected]="currentLine.name === line.name"
                                      src="./assets/icon/lines/{{ line.name }}.svg"></ion-icon>
                            <div [ngClass]="{'status-line-non-selected-icon':currentLine.name !== line.name}">
                                <ion-icon *ngIf="stateTrafficInfoLine[line.name]"
                                          [ngClass]="{'status-line-selected':currentLine.name === line.name}"
                                          class="status"
                                          src="./assets/svgs/lines/{{stateTrafficInfoLine[line.name]}}.svg"></ion-icon>
                            </div>
                        </div>
                    </ion-item>
                </div>
            </ion-list>
        </div>
    </ion-row>
</ion-grid>
