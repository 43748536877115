import Phaser from 'phaser'
import { Train } from '../../../modeles/trains'
import { isMostDelayedTrainOnCourse, isMostDelayedTrainOnDay, toMinute } from './delay.utils'
import { TrainType } from '../../../modeles/line.type.model'
import { TRAIN_ELEMENTS_OFFSET_X } from '../train/train.view.config'
import { SelectedTrainDelay } from '../../../../app/core/configurations/user/selected.train.delay'
import { SvgImagesType } from '../../../managers/image.manager'

export class DelayDisplayElement extends Phaser.GameObjects.Image {
  private _selectedDelay: SelectedTrainDelay
  private _trackPosition: number
  private _train: Train
  private _trainType: TrainType

  constructor(scene: Phaser.Scene, train: Train, selectedDelay: SelectedTrainDelay, trainType: TrainType, trackNumber: number) {
    super(scene, 0, 0, '')
    this.updateDelayElementImage(train, selectedDelay, trainType, trackNumber)
  }

  updateDelayElementImage(train: Train, selectedDelay: SelectedTrainDelay, trainType: TrainType, trackNumber: number) {
    this._trackPosition = trackNumber
    this._selectedDelay = selectedDelay
    this._train = train
    this._trainType = trainType

    switch (this._selectedDelay) {
      case SelectedTrainDelay.NONE:
        this.setVisible(false)
        break

      case SelectedTrainDelay.ON_DAY:
        if (isMostDelayedTrainOnDay(this._train)) {
          this.setMostDelayedChipTexture()
        } else {
          this.setDelayChipTexture(toMinute(this._train.delayOnDay))
        }
        this.setOffsetFromTrainDependingOnTrackAndTrainType(this._trackPosition, this._trainType)
        break

      case SelectedTrainDelay.ON_COURSE:
        if (isMostDelayedTrainOnCourse(this._train)) {
          this.setMostDelayedChipTexture()
        } else {
          this.setDelayChipTexture(toMinute(this._train.delayOnCourse))
        }
        this.setOffsetFromTrainDependingOnTrackAndTrainType(this._trackPosition, this._trainType)
        break
    }
  }

  setTexture(key: SvgImagesType, frame?: string | integer): this {
    return super.setTexture(key, frame)
  }

  private setMostDelayedChipTexture() {
    this.setVisible(true)
    this.setTexture('LATE_TRAIN_MOST_DELAYED_CHIP')
  }

  private setDelayChipTexture(delayInMinutes: number) {
    if (delayInMinutes === 0) {
      this.setVisible(false)
    } else {
      this.setVisible(true)
      if (delayInMinutes > 0) {
        this.setTexture('LATE_TRAIN_CHIP')
      } else {
        this.setTexture('EARLY_TRAIN_CHIP')
      }
    }
  }

  private setOffsetFromTrainDependingOnTrackAndTrainType(trackPosition: number, trainType: TrainType) {
    if (trackPosition === 1) {
      switch (trainType) {
        case TrainType.METRO:
          this.setX(-21)
          break

        case TrainType.RER:
          this.setX(34)
          break
      }
      this.setY(-7)
    } else {
      switch (trainType) {
        case TrainType.METRO:
          this.setX(21)
          break
        case TrainType.RER:
          this.setX(-34)
          break
      }
      this.setY(5)
    }
    this.setX(this.x + TRAIN_ELEMENTS_OFFSET_X)
  }
}
