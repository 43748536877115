import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import createPanZoom, { PanZoom } from 'panzoom'
import { ReferentialLine } from '../../core/models/lines'
import { BehaviorSubject } from 'rxjs'
import { filter, flatMap, shareReplay, tap } from 'rxjs/operators'
import { PlatformService } from '../../core/services/platform.service'
import { SafeStringSvg } from './safe-string-svg'

@Component({
  selector: 'svg-display',
  templateUrl: './svg-display.component.html',
  styleUrls: ['./svg-display.component.scss'],
})
export class SvgDisplayComponent implements OnDestroy, AfterViewInit {
  safeSvg: SafeStringSvg
  panZoom: PanZoom
  private referentialLineSubject: BehaviorSubject<ReferentialLine> = new BehaviorSubject<ReferentialLine>(null)
  private svgLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  count = 0

  constructor(public platformService: PlatformService, private sanitizer: DomSanitizer) {}

  @Input()
  set svg(textSvg: SafeHtml) {
    this.safeSvg = new SafeStringSvg(textSvg as string, this.sanitizer)
  }

  @Input()
  set tcoReferentialLine(referentialLine: ReferentialLine) {
    this.referentialLineSubject.next(referentialLine)
  }

  ngAfterViewInit(): void {
    const svgDiv = document.getElementById('svgDiv')
    const containerDiv = document.getElementById('container')
    if (svgDiv) {
      new MutationObserver(() => {
        if (!this.svgLoadingSubject.getValue()) {
          this.svgLoadingSubject.next(true)
        }
      }).observe(svgDiv, {
        childList: true,
      })
    }

    this.svgLoadingSubject
      .asObservable()
      .pipe(
        shareReplay(1),
        filter<true>(Boolean),
        flatMap((load) =>
          this.referentialLineSubject.pipe(
            filter<ReferentialLine>(Boolean),
            tap((ref) => {
              if (this.panZoom) {
                this.panZoom.pause()
                this.panZoom.dispose()
              }
              this.panZoom = createPanZoom(svgDiv, {
                maxZoom: 2,
                minZoom: containerDiv.getBoundingClientRect().width / this.safeSvg.width,
                zoomDoubleClickSpeed: 1,
                smoothScroll: !(this.platformService.isPlatformMobile() || this.platformService.isPlatformNative()),
                onDoubleClick: () => {
                  this.reset()
                  return false
                },
                onTouch: () => {
                  return false
                },
              })
              this.panZoom.getTransform().scale = ref.type === 'RER' ? 0.65 : 1
              this.panZoom.moveTo(-ref.initialMapPosition.x, -ref.initialMapPosition.y)
            })
          )
        )
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    if (this.panZoom) {
      this.panZoom.dispose()
    }
  }

  public reset() {
    this.panZoom.getTransform().scale = this.referentialLineSubject.getValue().type === 'RER' ? 0.65 : 1
    this.panZoom.moveTo(-this.referentialLineSubject.getValue().initialMapPosition.x, -this.referentialLineSubject.getValue().initialMapPosition.y)
  }

  public clickEvent(): void {
    this.count++
    setTimeout(() => {
      if (this.count === 1) {
        this.count = 0
      }
      if (this.count > 1) {
        this.count = 0
        this.reset()
      }
    }, 250)
  }
}
