<ion-row>
  <ion-col class="ion-no-padding">
    <ion-row [class.ion-justify-content-start]="platformService.isPlatformDesktop()" class="ion-no-padding">
      <ion-col class="ion-no-padding" size-lg="3" size-md="3" size-sm="6">
        <ion-item [routerLinkActive]="'active'" [routerLink]="[urlService.liveTrafficUrls.URL_REAL_TIME_TRAFFIC_MAP]" detail="false" lines="none">
          <ion-label>
            {{ 'app.shared.live.traffic.button.switcher.line.map' | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding" size-lg="3" size-md="3" size-sm="6">
        <ion-item [routerLinkActive]="'active'" [routerLink]="[urlService.liveTrafficUrls.URL_REAL_TIME_TRAFFIC_NEXT_DEPARTURES]" detail="false" lines="none">
          <ion-label>{{ 'app.shared.live.traffic.button.switcher.next.departure' | translate }}</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
