import { Component, Input } from '@angular/core'
import { PeakPeriodSchedules } from 'src/app/core/models/peak-periodConfig'

@Component({
  selector: 'app-peak-period-schedule',
  templateUrl: './peak-period-schedule.component.html',
  styleUrls: ['./peak-period-schedule.component.scss'],
})
export class PeakPeriodScheduleComponent {
  @Input() schedules: PeakPeriodSchedules

  constructor() {}
}
