import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { CoreModule } from './core/core.module'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { HttpLoaderFactory, SharedModule } from './shared/shared.module'
import { MenuTopComponent } from './shared/menu-top/menu-top.component'
import { MenuSideComponent } from './shared/menu-side/menu-side.component'
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { TokenInterceptorService } from './core/security/token-interceptor.service'
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx'
import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import { ErrorInterceptorService } from './core/interceptors/error-interceptor.service'
import { SnackBarComponent } from './shared/snake-bar/snack-bar.component'
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger'
import { environment } from '../environments/environment'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx'
import { InitializerService } from './core/services/initializer.service'
import { ApkVersionService } from './core/services/apk-version.service'
import { AppVersion } from '@ionic-native/app-version/ngx'
import { ApkVersionComponent } from './shared/apk-version/apk-version.component'
import { AngularFireModule } from '@angular/fire'
import { AngularFireAnalyticsModule, CONFIG } from '@angular/fire/analytics'
import { ReactiveFormsModule } from '@angular/forms'
import { appInitializerFactory } from './app.internationalization.initializer'
import { AngularFireMessagingModule } from '@angular/fire/messaging'
import { FcmService } from './core/services/notifications/fcm.service'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFireAuthModule } from '@angular/fire/auth'

registerLocaleData(localeFr)

export function init(config: ApkVersionService) {
  return () => config.load()
}

@NgModule({
  declarations: [AppComponent, MenuTopComponent, MenuSideComponent, SnackBarComponent, ApkVersionComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'fr',
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    ReactiveFormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
      // serverLogLevel
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
  ],
  providers: [
    FcmService,
    SnackBarComponent,
    {
      provide: CONFIG,
      useValue: {
        send_page_view: false,
        allow_ad_personalization_signals: false,
        anonymize_ip: true,
      },
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    ScreenOrientation,
    Platform,
    InAppBrowser,
    UniqueDeviceID,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppVersion,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [InitializerService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
