export const appProperties = {
  // peakPeriod$$
  peakPeriodHours: 16,
  peakPeriodMinutes: 30,

  // auth
  token: 'token-lt-20211210',
  sso: true,
  frame: false,
  stationLengthMaxSize: 15,

  toastDuration: 5000,
  logout: 'logout_ok',

  langToSet: 'fr',
}
