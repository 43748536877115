<ion-grid>
  <ion-row>
    <ion-col>
      <ion-row>
        <ion-col>
          <h4>
            {{ 'app.shared.dashboard.production.interval.line.detail.title' | translate }}
          </h4>
        </ion-col>
      </ion-row>
      <ion-row class="separator interval-detail-list-first-header">
        <ion-col class="custom-padding" sizeLg="1" sizeMd="1" sizeXs="1"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.one' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.two' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.sum' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2"><span></span></ion-col>
      </ion-row>
      <ion-row class="separator interval-detail-list-second-header">
        <ion-col class="custom-padding" sizeLg="1" sizeMd="1" sizeXs="1"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.theoretical' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.real' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.percentage' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.theoretical' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.real' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.percentage' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.theoretical' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.real' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ 'app.shared.dashboard.production.interval.line.detail.table.header.track.percentage' | translate }}</span>
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let line of lines" class="separator interval-detail-list-row">
        <ion-col class="custom-padding" sizeLg="1" sizeMd="1" sizeXs="1">
          <ion-icon [src]="'assets/icon/lines/' + line.lineName + '.svg'"></ion-icon>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.averageTheoreticalTrackOneInterval | customTime }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.averageRealTrackOneInterval | customTime }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.percentageTrackOneInterval * 100 | number: '2.0-0' }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.averageTheoreticalTrackTwoInterval | customTime }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.averageRealTrackTwoInterval | customTime }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.percentageTrackTwoInterval * 100 | number: '2.0-0' }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.sumTheoreticalInterval | customTime }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.sumRealInterval | customTime }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="2">
          <span>{{ line.sumPercentageInterval * 100 | number: '2.0-0' }}</span>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
