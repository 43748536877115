import { Component, EventEmitter, Output } from '@angular/core'
import { FullscreenService } from '../../core/services/fullscreen.service'

export type Display = 'FULLSCREEN' | 'NORMAL'

@Component({
  selector: 'app-full-screen-button',
  templateUrl: './full-screen-button.component.html',
  styleUrls: ['./full-screen-button.component.scss'],
})
export class FullScreenButtonComponent {
  @Output() fullScreenEvent: EventEmitter<Display> = new EventEmitter<Display>()
  fullScreenActivated: boolean

  constructor(private fullscreenService: FullscreenService) {}

  toggleFullscreen() {
    this.fullscreenService.fullscreenState.next(this.fullScreenActivated ? 'FULLSCREEN' : 'NORMAL')
  }
}
