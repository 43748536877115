import { NgModule } from '@angular/core'
import { EmptyPipe } from '../../shared/pipe/empty.pipe'
import { CustomTimePipe } from '../../shared/pipe/customTime.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { SecondsToMinutesPipe } from '../../shared/pipe/seconds-to-minutes.pipe'
import { NonZeroDisplayPipe } from '../../shared/pipe/non-zero-display.pipe'
import { AbsPipe } from '../../shared/pipe/abs.pipe'
import { CapitalizePipe } from '../../shared/pipe/capitalize.pipe'
import { TruncatePipe } from '../../shared/pipe/truncate.pipe'
import { SafeHtmlPipe } from './safe-html.pipe'

@NgModule({
  declarations: [EmptyPipe, CustomTimePipe, SecondsToMinutesPipe, NonZeroDisplayPipe, AbsPipe, CapitalizePipe, TruncatePipe, SafeHtmlPipe],
  exports: [TranslateModule, EmptyPipe, CustomTimePipe, SecondsToMinutesPipe, NonZeroDisplayPipe, AbsPipe, CapitalizePipe, TruncatePipe, SafeHtmlPipe],
})
export class PipesModule {}
