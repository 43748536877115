import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-information-tag',
  templateUrl: './information-tag.component.html',
  styleUrls: ['./information-tag.component.scss'],
})
export class InformationTagComponent {
  @Input() message: string
  @Input() class: string
}
