import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { CanActivateTMVisitorGrantedAccessGuard } from './core/security/can-activate-tm-visitor-guard.service'
import { CanActivateTMTrafficLiveGrantedAccessGuard } from './core/security/can-activate-tm-traffic-live-granted-guard.service'
import { CanActivateTcoGrantedAccessGuard } from './core/security/can-activate-tco-granted-access-guard.service'
import { CanActivateRestrictedGrantedAccessGuard } from './core/security/can-activate-restricted-granted-access.guard'
import {
  CanActivatePeakPeriodGrantedAccessGuard
} from "./core/security/can-activate-peak-period-granted-access-guard.service";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'real-time-traffic/map',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./shared/logout-button/logout-routing.module').then((m) => m.LogoutRoutingModule),
  },
  {
    path: 'restricted-access',
    loadChildren: () => import('./pages/restricted-access/restricted-access.module').then((m) => m.RestrictedAccessPageModule),
    canActivate: [CanActivateRestrictedGrantedAccessGuard],
  },
  {
    path: 'real-time-traffic',
    loadChildren: () => import('./pages/real-time-traffic/real-time-traffic.module').then((m) => m.RealTimeTrafficPageModule),
    canActivate: [CanActivateTMTrafficLiveGrantedAccessGuard],
  },
  {
    path: 'production-dashboard',
    loadChildren: () => import('./pages/production-dashboard/production-dashboard.module').then((m) => m.ProductionDashBoardPageModule),
    canActivate: [CanActivatePeakPeriodGrantedAccessGuard],
  },
  {
    path: 'interval-dashboard',
    loadChildren: () => import('./pages/interval-dashboard/interval-dashboard.module').then((m) => m.IntervalDashBoardPageModule),
    canActivate: [CanActivateTMVisitorGrantedAccessGuard],
  },
  {
    path: 'tco-network',
    loadChildren: () => import('./pages/tco-network/tco-network.module').then((m) => m.TcoNetworkPageModule),
    canActivate: [CanActivateTcoGrantedAccessGuard],
  },
  {
    path: 'information-traffic',
    loadChildren: () => import('./pages/information-traffic/information-traffic.module').then((m) => m.InformationTrafficPageModule),
    canActivate: [CanActivateTMVisitorGrantedAccessGuard],
  },
  {
    path: 'information-traffic/:type/:line',
    loadChildren: () => import('./pages/information-traffic/information-traffic.module').then((m) => m.InformationTrafficPageModule),
    canActivate: [CanActivateTMVisitorGrantedAccessGuard],
  },
  {
    path: 'gdpr',
    loadChildren: () => import('./pages/gdpr/gdpr.module').then((m) => m.GdprPageModule),
    canActivate: [CanActivateTMVisitorGrantedAccessGuard],
  },
  {
    path: 'server-errors',
    loadChildren: () => import('./pages/server-errors/server-errors.module').then((m) => m.ServerErrorsPageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CanActivateTMVisitorGrantedAccessGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notification/notification.module').then((m) => m.NotificationPageModule),
    canActivate: [CanActivateTMVisitorGrantedAccessGuard],
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpPageModule),
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then((m) => m.PageNotFoundPageModule),
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
