<ion-card>
  <ion-card-content>
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-justify-content-between">
        <ion-col>
          <div class="cartridge-type">
            {{ 'app.shared.dashboard.production.tk.cartridge.metro' | translate }}
          </div>
        </ion-col>
      </ion-row>

      <!-- DESKTOP & TABLET -->
      <div *ngIf="tkProductionSubject.value == 'KILOMETER'">
        <ion-row [class.tablet-detail]="platformService.isPlatformTablet()">
          <ion-col size="5">
            <div *ngIf="productionInvalid===false ; else invalidProductionTemplate" [ngClass]="getColor(tkStatistics.productionPercentage)" class="cartridge-percentage">
              <span>{{ tkStatistics.productionPercentage * 100 | number: '2.0-0' | NonZeroDisplay }}</span>
              <span *ngIf="tkStatistics.productionPercentage" class="percentage-symbol">%</span>
            </div>
          </ion-col>
          <ion-col class="col-detail" size="7">
            <ion-row>
              <ion-col>
                <div class="cartridge-title">
                  {{ 'app.shared.dashboard.production.cartridge.theoretical' | translate }}
                </div>
              </ion-col>
              <ion-col>
                <div class="cartridge-title">
                  {{ 'app.shared.dashboard.production.cartridge.real' | translate }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="cartridge-detail">
                  {{ tkStatistics.productionTheoretical | NonZeroDisplay }}
                </div>
              </ion-col>
              <ion-col>
                <div *ngIf="productionInvalid===false ; else invalidProductionTemplate" [ngClass]="getColor(tkStatistics.productionPercentage)" class="cartridge-detail">
                  {{ tkStatistics.productionReal | NonZeroDisplay }}
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
      <div *ngIf="tkProductionSubject.value == 'LAP'">
        <ion-row [class.tablet-detail]="platformService.isPlatformTablet()">
          <ion-col size="5">
            <div *ngIf="productionInvalid===false ; else invalidProductionTemplate" [ngClass]="getColor(tkStatistics.lapsPercentage)" class="cartridge-percentage">
              <span>{{ tkStatistics.lapsPercentage * 100 | number: '2.0-0' | NonZeroDisplay }}</span>
              <span *ngIf="tkStatistics.lapsPercentage" class="percentage-symbol">%</span>
            </div>
          </ion-col>
          <ion-col class="col-detail" size="7">
            <ion-row>
              <ion-col>
                <div class="cartridge-title">
                  {{ 'app.shared.dashboard.production.cartridge.theoretical' | translate }}
                </div>
              </ion-col>
              <ion-col>
                <div class="cartridge-title">
                  {{ 'app.shared.dashboard.production.cartridge.real' | translate }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="cartridge-detail">
                  {{ tkStatistics.lapsTheoretical | round | NonZeroDisplay }}
                </div>
              </ion-col>
              <ion-col>
                <div *ngIf="this.productionInvalid===false; else invalidProductionTemplate" [ngClass]="getColor(tkStatistics.lapsPercentage)" class="cartridge-detail">
                  {{ tkStatistics.lapsReal | round | NonZeroDisplay }}
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </ion-card-content>
</ion-card>
<ng-template #invalidProductionTemplate>
  -
</ng-template>
