import { Component, Input } from '@angular/core'
import { ActionSheetController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'
import { ReferentialLine } from '../../core/models/lines'
import { UserPreferences, userPreferences } from '../../core/configurations/user/user.preferences'
import { SelectedTrainDelay } from '../../core/configurations/user/selected.train.delay'
import { PlatformService } from '../../core/services/platform.service'
import { AnalyticsService } from '../../core/services/analytics.service'

@Component({
  selector: 'app-delay-switch',
  templateUrl: './delay-switch.component.html',
  styleUrls: ['./delay-switch.component.scss'],
})
export class DelaySwitchComponent {
  selectedDelayType = SelectedTrainDelay
  referentialLineBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)

  constructor(public actionSheetCtrl: ActionSheetController, private translateService: TranslateService, private analyticsService: AnalyticsService, public platformService: PlatformService) {}

  private _userPreferences = userPreferences

  get userPreferences(): UserPreferences {
    return this._userPreferences
  }

  private _selectedLine: ReferentialLine

  @Input()
  get selectedLine(): ReferentialLine {
    return this._selectedLine
  }

  set selectedLine(value: ReferentialLine) {
    this.referentialLineBehaviorSubject.next(value.name)
  }

  async openMenu() {
    const delayOptions = [
      {
        text: this.translateService.instant('app.shared.action.sheet.delay.switch.options.delay.on.course'),
        role: userPreferences.selectedTrainDelay === SelectedTrainDelay.ON_COURSE ? 'selected' : '',
        icon: './assets/icon/switch/delay/train.svg',
        handler: () => {
          userPreferences.selectedTrainDelay = SelectedTrainDelay.ON_COURSE
          this.analyticsService.pushLogEvent({
            eventName: 'boutton_selection_retard',
            eventParameter: {
              train_delay_selection: SelectedTrainDelay.ON_COURSE,
            },
          })
        },
      },
      this.referentialLineBehaviorSubject.getValue() === 'A' || this.referentialLineBehaviorSubject.getValue() === 'B'
        ? null
        : {
            text: this.translateService.instant('app.shared.action.sheet.delay.switch.options.delay.on.day'),
            role: userPreferences.selectedTrainDelay === SelectedTrainDelay.ON_DAY ? 'selected' : '',
            icon: './assets/icon/switch/delay/train.svg',
            handler: () => {
              userPreferences.selectedTrainDelay = SelectedTrainDelay.ON_DAY
              this.analyticsService.pushLogEvent({
                eventName: 'boutton_selection_retard',
                eventParameter: {
                  train_delay_selection: SelectedTrainDelay.ON_DAY,
                },
              })
            },
          },
      {
        text: this.translateService.instant('app.shared.action.sheet.no.display'),
        role: userPreferences.selectedTrainDelay === SelectedTrainDelay.NONE ? 'selected' : '',
        icon: './assets/icon/switch/eye.svg',
        handler: () => {
          userPreferences.selectedTrainDelay = SelectedTrainDelay.NONE
          this.analyticsService.pushLogEvent({
            eventName: 'boutton_selection_retard',
            eventParameter: { train_delay_selection: SelectedTrainDelay.NONE },
          })
        },
      },
    ]

    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateService.instant('app.shared.action.sheet.delay.switch.options.title'),
      cssClass: 'action-sheet',
      buttons: delayOptions.filter((value) => !!value),
    })
    await actionSheet.present()
  }
}
