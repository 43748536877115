import { Train } from '../../../modeles/trains'

export function toMinute(seconds: number): number {
  return Math.round(seconds / 60)
}

export function isMostDelayedTrainOnCourse(train: Train) {
  return train.windowType === 'C' || train.windowType === 'B'
}

export function isMostDelayedTrainOnDay(train: Train) {
  return train.windowType === 'R' || train.windowType === 'V'
}
