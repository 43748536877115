import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { paths } from '../../../environments/paths'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ApiMongoAnalyticsService {
  constructor(private httpClient: HttpClient) {}

  saveAnalytics(): Observable<any> {
    return this.httpClient.post<any>(paths.analytics, null)
  }
}
