import { Component } from '@angular/core'
import { IntervalService } from '../../core/services/interval.service'
import { PlatformService } from '../../core/services/platform.service'

@Component({
  selector: 'app-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss'],
})
export class MenuTopComponent {
  date: number

  constructor(public platformService: PlatformService, private intervalService: IntervalService) {
    intervalService.setInterval(1, () => {
      this.date = Date.now()
    })
  }
}
