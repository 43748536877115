import { Injectable } from '@angular/core'
import { SocketService } from './socket.service'
import { Tk } from '../../models/tk'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Subscription } from 'stompjs'
import { SocketConnectionService } from './socket-connection.service'
import { LocalStorageService } from '../../local-storage.service'
import { paths } from '../../../../environments/paths'
import { shareReplay, takeUntil, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ApiTkGlobalService extends SocketService<Tk> {
  tkOffPeakHour: BehaviorSubject<Tk> = new BehaviorSubject<Tk>(null)
  public state$: Observable<Tk> = this.tkOffPeakHour.asObservable()
  private tkOffPeakHourSubscription: Subscription[]
  private destroy: Subject<boolean> = new Subject<boolean>()

  constructor(socketConnectionService: SocketConnectionService, localStorageService: LocalStorageService) {
    super(socketConnectionService, localStorageService)
  }

  async getTkStreamMessages(): Promise<Subscription[]> {
    if (!!this.tkOffPeakHourSubscription) {
      this.unSubscriptionTkStreamMessages()
    }
    this.tkOffPeakHour.next(null)
    return super
      .subscriptionWithAutoReconnection([paths.tkGlobal], this.tkOffPeakHour)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionTkStreamMessages()),
        tap((subscription) => (this.tkOffPeakHourSubscription = [...subscription]))
      )
      .toPromise()
  }

  unSubscriptionTkStreamMessages(): void {
    if (!!this.tkOffPeakHourSubscription) {
      super.unSubscriptionAfterConnection(this.tkOffPeakHourSubscription)
    }
  }
}
