import { GlobalStatistics, MetroStatistics, PeakPeriodGlobal, PeakPeriodRaw, RerStatistics } from '../../core/models/peak-period'
import { PeakPeriodConfig } from '../../core/models/peak-periodConfig'
import { getLevelPeakPeriod } from '../../pages/real-time-traffic/info-line/info-line.component'
import { UnaryFunction } from 'rxjs'
import { PeriodType } from '../production-switcher-button/production-switcher-button.component'

function checkPeriodInformationMorning(peakPeriodRaw: PeakPeriodRaw, peakPeriodConfig: PeakPeriodConfig): PeakPeriodGlobal {
  return !!peakPeriodRaw.metroStatistics && !!peakPeriodRaw.metroStatistics.eveningTotalTheoretical
    ? new PeakPeriodGlobal(
        new MetroStatistics(peakPeriodRaw.metroStatistics.eveningTotalTheoretical, peakPeriodRaw.metroStatistics.eveningTotalReal, peakPeriodRaw.metroStatistics.eveningPercentage, getLevelPeakPeriod(peakPeriodRaw.metroStatistics.eveningPercentage, peakPeriodConfig)),
        new RerStatistics(peakPeriodRaw.rerStatistics.eveningTotalTheoretical, peakPeriodRaw.rerStatistics.eveningTotalReal, peakPeriodRaw.rerStatistics.eveningPercentage, getLevelPeakPeriod(peakPeriodRaw.rerStatistics.eveningPercentage, peakPeriodConfig)),
        new GlobalStatistics(peakPeriodRaw.globalStatistics.eveningTotalTheoretical, peakPeriodRaw.globalStatistics.eveningTotalReal, peakPeriodRaw.globalStatistics.eveningPercentage, getLevelPeakPeriod(peakPeriodRaw.globalStatistics.eveningPercentage, peakPeriodConfig))
      )
    : null
}

function checkPeriodInformationEvening(peakPeriodRaw: PeakPeriodRaw, peakPeriodConfig: PeakPeriodConfig): PeakPeriodGlobal {
  return !!peakPeriodRaw.metroStatistics && !!peakPeriodRaw.metroStatistics.morningTotalTheoretical
    ? new PeakPeriodGlobal(
        new MetroStatistics(peakPeriodRaw.metroStatistics.morningTotalTheoretical, peakPeriodRaw.metroStatistics.morningTotalReal, peakPeriodRaw.metroStatistics.morningPercentage, getLevelPeakPeriod(peakPeriodRaw.metroStatistics.morningPercentage, peakPeriodConfig)),
        new RerStatistics(peakPeriodRaw.rerStatistics.morningTotalTheoretical, peakPeriodRaw.rerStatistics.morningTotalReal, peakPeriodRaw.rerStatistics.morningPercentage, getLevelPeakPeriod(peakPeriodRaw.rerStatistics.morningPercentage, peakPeriodConfig)),
        new GlobalStatistics(peakPeriodRaw.globalStatistics.morningTotalTheoretical, peakPeriodRaw.globalStatistics.morningTotalReal, peakPeriodRaw.globalStatistics.morningPercentage, getLevelPeakPeriod(peakPeriodRaw.globalStatistics.morningPercentage, peakPeriodConfig))
      )
    : null
}

export function setPeakPeriodMorningAndEvening(peakPeriodRaw: PeakPeriodRaw): UnaryFunction<PeakPeriodConfig, { peakPeriodMorning: PeakPeriodGlobal; peakPeriodEvening: PeakPeriodGlobal }> {
  return (peakPeriodConfig: PeakPeriodConfig): any => {
    return {
      peakPeriodMorning: checkPeriodInformationEvening(peakPeriodRaw, peakPeriodConfig),
      peakPeriodEvening: checkPeriodInformationMorning(peakPeriodRaw, peakPeriodConfig),
    }
  }
}

export function extractPeakPeriodFromPeriod(periodType: PeriodType): UnaryFunction<any, PeakPeriodGlobal> {
  return (peakPeriodGlobal: { peakPeriodMorning: PeakPeriodGlobal; peakPeriodEvening: PeakPeriodGlobal }) => {
    return periodType === 'EVENING' ? peakPeriodGlobal.peakPeriodEvening : peakPeriodGlobal.peakPeriodMorning
  }
}
