import { Injectable, Injector } from '@angular/core'
import { ApkVersionService } from './apk-version.service'

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  private apkService: ApkVersionService

  constructor(private injector: Injector) {
    this.apkService = injector.get(ApkVersionService)
  }

  async load() {
    await this.apkService.load()
  }
}
