import Phaser from 'phaser'
import { Train } from '../../../modeles/trains'
import { isMostDelayedTrainOnCourse, isMostDelayedTrainOnDay, toMinute } from './delay.utils'
import { ColorPhaser } from '../../../modeles/color'
import { TrainType } from '../../../modeles/line.type.model'
import { TRAIN_ELEMENTS_OFFSET_X } from '../train/train.view.config'
import { SelectedTrainDelay } from '../../../../app/core/configurations/user/selected.train.delay'
import { TRAIN_DELAY_TEXT_SIZE, TRAIN_NUMBER_TEXT_FONT } from '../../../configs/configuration.text'

export class TrainDelayText extends Phaser.GameObjects.BitmapText {
  private _selectedDelay: SelectedTrainDelay
  private _trackPosition: number
  private _train: Train
  private _trainType: TrainType

  constructor(scene: Phaser.Scene, train: Train, selectedDelay: SelectedTrainDelay, trainType: TrainType, trackPosition: number) {
    super(scene, 0, 0, TRAIN_NUMBER_TEXT_FONT, '', TRAIN_DELAY_TEXT_SIZE)
    this.updateDelayElementText(train, selectedDelay, trainType, trackPosition)
  }

  get trainDelayOnCourse(): number {
    return toMinute(this._train.delayOnCourse)
  }

  get trainDelayOnDay(): number {
    return toMinute(this._train.delayOnDay)
  }

  updateDelayElementText(train: Train, selectedDelay: SelectedTrainDelay, trainType: TrainType, trackPosition: number) {
    this._selectedDelay = selectedDelay
    this._trackPosition = trackPosition
    this._train = train
    this._trainType = trainType

    switch (this._selectedDelay) {
      case SelectedTrainDelay.NONE:
        this.setVisible(false)
        break

      case SelectedTrainDelay.ON_DAY:
        const delayOnDay: number = this.trainDelayOnDay

        const onDayTextValue = this.formatTextToDisplay(delayOnDay)
        this.setText(onDayTextValue)

        if (isMostDelayedTrainOnDay(this._train)) {
          this.showTextForMostDelayed()
        } else {
          this.showTextFromDelay(delayOnDay)
        }
        this.setOffsetFromTrainDependingOnTrackAndTrainType(this._trackPosition, this._trainType)
        break

      case SelectedTrainDelay.ON_COURSE:
        const delayOnCourse = this.trainDelayOnCourse

        const onCourseTextValue = this.formatTextToDisplay(delayOnCourse)
        this.setText(onCourseTextValue)

        if (isMostDelayedTrainOnCourse(this._train)) {
          this.showTextForMostDelayed()
        } else {
          this.showTextFromDelay(delayOnCourse)
        }
        this.setOffsetFromTrainDependingOnTrackAndTrainType(this._trackPosition, this._trainType)
        break
    }
  }

  private showTextForMostDelayed() {
    this.setVisible(true)
    this.setTint(ColorPhaser.WHITE)
  }

  private formatTextToDisplay(delay: number): string {
    const number = Math.abs(delay)
    if (number < 10) {
      return ' ' + number + "'"
    } else {
      return number + "'"
    }
  }

  private setOffsetFromTrainDependingOnTrackAndTrainType(trackPosition: number, trainType: TrainType) {
    if (trackPosition === 1) {
      switch (trainType) {
        case TrainType.METRO:
          this.setX(-28)
          break

        case TrainType.RER:
          this.setX(27)
          break
      }
      this.setY(-12)
    } else {
      switch (trainType) {
        case TrainType.METRO:
          this.setX(13)
          break
        case TrainType.RER:
          this.setX(-42)
          break
      }
      this.setY(0)
    }
    this.setX(this.x + TRAIN_ELEMENTS_OFFSET_X)
  }

  private showTextFromDelay(delay: number) {
    if (delay === 0) {
      this.setVisible(false)
    } else {
      this.setVisible(true)
      if (delay > 0) {
        this.setTint(ColorPhaser.LATE)
      } else {
        this.setTint(ColorPhaser.EARLY)
      }
    }
  }
}
