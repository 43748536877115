import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Subscription } from 'stompjs'
import { SocketConnectionService } from './socket-connection.service'
import { LocalStorageService } from '../../local-storage.service'
import { ReferentialLine } from '../../models/lines'
import { SocketService } from './socket.service'
import { paths } from '../../../../environments/paths'
import { shareReplay, takeUntil, tap } from 'rxjs/operators'
import { LineSituations, LineTrafficSituations } from '../../models/trafic-situation'

@Injectable({
  providedIn: 'root',
})
export class ApiDisruptionTrafficSituationService extends SocketService<LineTrafficSituations | LineSituations> {
  disruptionsByLine: BehaviorSubject<LineTrafficSituations> = new BehaviorSubject<LineTrafficSituations>(null)
  disruptionsByLineObservable: Observable<LineTrafficSituations> = this.disruptionsByLine.asObservable()
  disruptionLineSubscription: Subscription[]

  disruptionsAllLine: BehaviorSubject<LineSituations> = new BehaviorSubject<LineSituations>(null)
  disruptionsAllLineObservable: Observable<LineSituations> = this.disruptionsAllLine.asObservable()
  disruptionAllLinesSubscription: Subscription[]

  private destroy: Subject<boolean> = new Subject<boolean>()

  constructor(socketConnectionService: SocketConnectionService, localStorageService: LocalStorageService) {
    super(socketConnectionService, localStorageService)
  }

  async getStreamMessagesAllLine(): Promise<Subscription[]> {
    if (!!this.disruptionAllLinesSubscription) {
      this.unSubscriptionStreamMessages()
    }
    this.disruptionsAllLine.next(null)
    return super
      .subscriptionWithAutoReconnection([`${paths.disruptionAllTrafficSituations}`], this.disruptionsAllLine)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionStreamMessages()),
        tap((subscription) => (this.disruptionAllLinesSubscription = [...subscription]))
      )
      .toPromise()
  }

  async getStreamMessagesByLine(referentialLine: ReferentialLine): Promise<Subscription[]> {
    if (!!this.disruptionLineSubscription) {
      this.unSubscriptionStreamMessages()
    }
    this.disruptionsByLine.next(null)
    return super
      .subscriptionWithAutoReconnection([`${paths.disruptionTrafficSituations}${referentialLine.name}`], this.disruptionsByLine)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionStreamMessages()),
        tap((subscription) => (this.disruptionLineSubscription = [...subscription]))
      )
      .toPromise()
  }

  unSubscriptionStreamMessages(): void {
    if (!!this.disruptionLineSubscription) {
      super.unSubscriptionAfterConnection(this.disruptionLineSubscription)
    }
  }

  unSubscriptionAllLinesStreamMessages(): void {
    if (!!this.disruptionAllLinesSubscription) {
      super.unSubscriptionAfterConnection(this.disruptionAllLinesSubscription)
    }
  }
}
