<ion-col class="no-data-message-col">
  <ion-row *ngIf="load; else noData" class="ion-justify-content-center no-data">
    <span *ngIf="show | async; else noData">
      <app-spinner></app-spinner>
    </span>
  </ion-row>
</ion-col>
<ng-template #noData>
  <span>
    {{ message }}
  </span>
</ng-template>
