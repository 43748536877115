import { Component } from '@angular/core'
import { ActionSheetController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { SelectedPlatformInfo } from '../../core/configurations/user/selected.platform.info'
import { UserPreferences, userPreferences } from '../../core/configurations/user/user.preferences'
import { PlatformService } from '../../core/services/platform.service'
import { AnalyticsService } from '../../core/services/analytics.service'

@Component({
  selector: 'app-time-switch',
  templateUrl: './time-switch.component.html',
  styleUrls: ['./time-switch.component.scss'],
})
export class TimeSwitchComponent {
  platformInfoType = SelectedPlatformInfo

  constructor(public actionSheetCtrl: ActionSheetController, private translateService: TranslateService, public platformService: PlatformService, private analyticsService: AnalyticsService) {}

  private _userPreferences = userPreferences

  get userPreferences(): UserPreferences {
    return this._userPreferences
  }

  async openMenu() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateService.instant('app.shared.action.sheet.time.switch.options.title'),
      cssClass: 'action-sheet',
      buttons: [
        {
          text: this.translateService.instant('app.shared.action.sheet.time.switch.options.delay.waiting'),
          role: userPreferences.selectedPlatformInfo === SelectedPlatformInfo.WAITING ? 'selected' : '',
          icon: './assets/icon/switch/time/wait.svg',
          handler: () => {
            userPreferences.selectedPlatformInfo = SelectedPlatformInfo.WAITING
            this.analyticsService.pushLogEvent({
              eventName: 'boutton_temps',
              eventParameter: { time_selection: SelectedPlatformInfo.WAITING },
            })
          },
        },
        {
          text: this.translateService.instant('app.shared.action.sheet.time.switch.options.delay.elapsed'),
          role: userPreferences.selectedPlatformInfo === SelectedPlatformInfo.ELAPSED ? 'selected' : '',
          icon: './assets/icon/switch/time/spend.svg',
          handler: () => {
            userPreferences.selectedPlatformInfo = SelectedPlatformInfo.ELAPSED
            this.analyticsService.pushLogEvent({
              eventName: 'boutton_temps',
              eventParameter: { time_selection: SelectedPlatformInfo.ELAPSED },
            })
          },
        },
        {
          text: this.translateService.instant('app.shared.action.sheet.no.display'),
          role: userPreferences.selectedPlatformInfo === SelectedPlatformInfo.NONE ? 'selected' : '',
          icon: './assets/icon/switch/eye.svg',
          handler: () => {
            userPreferences.selectedPlatformInfo = SelectedPlatformInfo.NONE
            this.analyticsService.pushLogEvent({
              eventName: 'boutton_temps',
              eventParameter: { time_selection: SelectedPlatformInfo.NONE },
            })
          },
        },
      ],
    })
    await actionSheet.present()
  }
}
