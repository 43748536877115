import { Injectable } from '@angular/core'
import { UserService } from './security/user.service'
import * as jwt_decode from 'jwt-decode'
import { appProperties } from '../../environments/app.properties'
import { AuthenticationInformation } from './models/authentication-information'
import { Preferences } from '@capacitor/preferences'

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * initialization du context user on application loading
   */
  constructor(private userService: UserService) {
    this.getItem(appProperties.token).then((res) => this.userServiceTrigger(res.value))
  }

  async setObject(key: string, value: any) {
    await Preferences.set({
      key,
      value: JSON.stringify(value),
    })
  }

  async getObject(key: string): Promise<any> {
    const ret = await Preferences.get({ key })
    return JSON.parse(ret.value)
  }

  async setItem(key: string, value: string) {
    this.userServiceTrigger(value)
    await Preferences.set({
      key,
      value,
    })
  }

  public getCurrentUser(): Promise<AuthenticationInformation> {
    return this.getItem(appProperties.token).then((res) => (!!res && !!res.value ? jwt_decode(res.value) : null))
  }

  private userServiceTrigger(res: string) {
    this.userService.currentUserSubject.next(!!res ? jwt_decode(res) : null)
  }

  async getItem(key: string): Promise<{ value: any }> {
    return await Preferences.get({ key })
  }

  async removeItem(key: string) {
    this.userServiceTrigger(null)
    await Preferences.remove({ key })
  }

  async keys(): Promise<any> {
    return await Preferences.keys()
  }

  async clear() {
    await Preferences.clear()
  }
}
