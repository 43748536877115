import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ContainerTrackElement } from '../../../phaser/game-objects/groups/track/track.element'
import { PlatformService } from '../../core/services/platform.service'
import { Agent, Incident } from '../../core/models/live-event'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-station-information',
  templateUrl: './station-information.component.html',
  styleUrls: ['./station-information.component.scss'],
})
export class StationInformationComponent implements OnChanges {
  @Input() stationInformation: ContainerTrackElement
  @Input()
  display: BehaviorSubject<string>
  private activeEventIndex = 0

  activeAgent: Agent = null
  activeIncident: Incident = null
  activeAgentsAndIncidents: (Agent | Incident)[] = []
  displayLineNameValue = ''
  numberOfAgents = 0
  numberOfIncidents = 0
  eventVisible = false
  sizes: { [key: string]: string } = {}
  agentEvent = false
  incidentEvent = false
  agentAndIncidentEvent = false
  rightArrowClass = ''
  leftArrowClass = ''
  activeIncidentEvent = false

  constructor(public platformService: PlatformService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stationInformation) {
      this.resetActiveEventIndex()
      this.updateView()
      this.calculateDisplayLineName()
      this.calculateNumbers()
      this.checkEventVisibility()
      this.calculateSizes()
    }
  }

  private resetActiveEventIndex() {
    this.activeEventIndex = 0
  }

  private updateView() {
    this.updateActiveElements()
    this.updateEventType()
    this.updateArrowClass()
  }

  private updateActiveElements(): void {
    this.activeAgentsAndIncidents = this.displayAgentsAndIncidents()
    this.activeAgent = this.activeAgentsAndIncidents[this.activeEventIndex] as Agent
    this.activeIncident = this.activeAgentsAndIncidents[this.activeEventIndex] as Incident
  }

  private displayAgentsAndIncidents(): (Agent | Incident)[] {
    return [...this.displayAgents(), ...this.displayIncidents()]
  }

  private displayAgents(): Agent[] {
    return this.stationInformation?.liveEventElement?.displayAgents() ?? []
  }

  private displayIncidents(): Incident[] {
    return this.stationInformation?.liveEventElement?.displayIncidents() ?? []
  }

  private calculateDisplayLineName(): void {
    this.displayLineNameValue = this.stationInformation?.liveEventElement?.displayLineName() ?? ''
  }

  private calculateNumbers(): void {
    this.numberOfAgents = this.stationInformation?.liveEventElement?.displayNumberOfAgents() ?? 0
    this.numberOfIncidents = this.stationInformation?.liveEventElement?.displayNumberOfIncidents() ?? 0
  }

  private checkEventVisibility(): void {
    this.eventVisible = this.stationInformation?.liveEventElement?.isLiveEventDetailVisible() ?? false
  }

  private calculateSizes(): void {
    this.sizes = {
      noConnexion: this.eventVisible ? '5' : '12',
      title: this.eventVisible ? '5' : '11',
      connexionsTrackContainer: this.eventVisible ? '5' : '8',
      connexionsTrack: this.eventVisible ? '5' : '12',
    }
  }

  private updateEventType() {
    this.isAgentEvent()
    this.isIncidentEvent()
    this.isAgentAndIncidentEvent()
    this.isActiveIncidentEvent()
  }

  private isAgentEvent() {
    this.agentEvent = this.stationInformation?.liveEventElement?.isAgentLiveEvent() ?? false
  }

  private isIncidentEvent() {
    this.incidentEvent = this.stationInformation?.liveEventElement?.isIncidentLiveEvent() ?? false
  }

  private isAgentAndIncidentEvent() {
    this.agentAndIncidentEvent = this.stationInformation?.liveEventElement?.isAgentAndIncidentLiveEvent() ?? false
  }

  private isActiveIncidentEvent() {
    this.activeIncidentEvent = (this.activeAgentsAndIncidents[this.activeEventIndex] as Incident)?.eventId !== undefined
  }

  onClickLeftArrow(): void {
    if (this.activeEventIndex > 0) {
      this.activeEventIndex--
      this.updateView()
    }
  }

  onClickRightArrow(): void {
    if (this.activeEventIndex < this.activeAgentsAndIncidents.length - 1) {
      this.activeEventIndex++
      this.updateView()
    }
  }

  private updateArrowClass() {
    this.getLeftArrowClass()
    this.getRightArrowClass()
  }

  private getLeftArrowClass() {
    this.leftArrowClass = this.activeEventIndex > 0 ? 'left-arrow' : ''
  }

  private getRightArrowClass() {
    this.rightArrowClass = this.activeEventIndex < this.activeAgentsAndIncidents.length - 1 ? 'right-arrow' : ''
  }

  redirectToLiveEvent(): string {
    return this.activeIncidentEvent ? `${environment.liveEventsBaseUrl}/incidents/${this.activeIncident.eventId}/filactu` : ''
  }
}
