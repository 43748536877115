<ion-grid>
  <ion-row class="ion-align-self-center">
    <ion-col size-sm="9" size-lg="4" size-md="5" size-xs="10" class="ion-align-items-center">
      <ion-card class="ion-align-items-center">
        <ion-card-header>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-icon src="./assets/icon/reload.svg"></ion-icon>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label>
                  {{ 'app.reload.label.title' | translate }}
                </ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-header>
        <ion-card-content>
          <ion-grid>
            <ion-row>
              <ion-col>
                {{ 'app.reload.content.message' | translate }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
