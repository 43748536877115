import { stompConfig } from '../../configurations/stomp'
import { LocalStorageService } from '../../local-storage.service'
import { StompStoreService } from './stomp-store.service'
import { paths } from '../../../../environments/paths'
import { appProperties } from '../../../../environments/app.properties'
import { Injectable } from '@angular/core'
import { filter, map, shareReplay } from 'rxjs/operators'
import SockJS from 'sockjs-client'
import { fromEvent, Observable } from 'rxjs'
import { ActivationState , Stomp, Client } from "@stomp/stompjs";

@Injectable({
  providedIn: 'root',
})
export class SocketConnectionService {
  private promise: Promise<Client>
  private client: Client

  public reConnectionStateS$ = this.stompStoreService.connectionStateS$.pipe(filter((activationState: ActivationState) => activationState === ActivationState.ACTIVE))

  visibilityState$: Observable<VisibilityState>

  constructor(private localStorageService: LocalStorageService, private stompStoreService: StompStoreService) {
    localStorageService.getItem(appProperties.token).then((token) => {
      this.client = Stomp.over(() => new SockJS(paths.endPointWebSocket(token.value)))
      this.client.configure(stompConfig(this.stompStoreService))
    })
    /**
     * this is needed to cut ws when background
     */
    this.visibilityState$ = fromEvent(window, 'visibilitychange').pipe(
      shareReplay(1),
      map((windowEvent: any) => {
        if (window.document.visibilityState === 'hidden') {
          this.client.deactivate().then(() => (this.promise = null))
        } else {
          if ( this.client.state === ActivationState.DEACTIVATING) {
            console.log("STILL DEACTIVATING")
          } else {
            this.client.activate()
          }
        }
        return window.document.visibilityState
      })
    )
  }

  public async getClient(token: string): Promise<Client> {
    if (!this.promise) {
      const headers = {
        Authorization: token,
      }
      return (this.promise = new Promise((resolve) => {
            if (this.client.state === ActivationState.DEACTIVATING) {
              console.log("getClient : STILL DEACTIVATING => reload")
              window.location.reload()
            }
            this.client.activate();
            this.client.onConnect = () => {
              resolve(this.client);
            }
          }
      ))
    } else {
      return this.promise
    }
  }
}
