import { environment } from './environment'

export const trafficMobileApi = '/trafic-mobile-api'
export const trafficMobileNotificationApi = '/notification-api'

export function getEndPointWebSocket(token) {
  return `${environment.trafficMobileApiBaseUrl}/ws?token=${token}`
}

export const paths = {
  samlLogout: `${environment.authenticationApiBaseUrl}/saml/logout`,
  samlLoginUrl: `${environment.authenticationApiBaseUrl}/saml/login`,
  samlCheckOrigin: `${environment.authenticationApiBaseUrl}/saml/SSO`,
  authUrl: `${environment.authenticationApiBaseUrl}/auth/token`, // search api is responsible for jwt authentication
  refreshTokenUrl: `${environment.authenticationApiBaseUrl}/auth/refresh-token`,
  login: `${environment.authenticationApiBaseUrl}/login`,
  logout: `${environment.authenticationApiBaseUrl}/logout`,

  endPointWebSocket: getEndPointWebSocket,

  tcoSvg: `${environment.trafficMobileApiBaseUrl}${trafficMobileApi}/line-tco-mapping`,
  referentialLines: `${environment.trafficMobileApiBaseUrl}${trafficMobileApi}/referential/lines`,
  peakPeriodConfig: `${environment.trafficMobileApiBaseUrl}${trafficMobileApi}/referential/peak-period-config`,
  intervalConfig: `${environment.trafficMobileApiBaseUrl}${trafficMobileApi}/referential/interval-config`,
  tkOffPeakHourConfig: `${environment.trafficMobileApiBaseUrl}${trafficMobileApi}/referential/tk-off-peak-hour-config`,
  overParkingConfig: `${environment.trafficMobileApiBaseUrl}${trafficMobileApi}/referential/over-parking-config`,
  gdpr: `${environment.trafficMobileApiBaseUrl}${trafficMobileApi}/gdpr`,

  // websockets
  peakPeriod: '/peak-hours',
  peakPeriodGlobal: '/topic/peak-hours/statistics',
  peakPeriodLine: '/topic/peak-hours/lines',
  interval: '/interval',
  intervalLine: '/topic/interval/lines',
  intervalStatistics: '/topic/interval/statistics',
  trainTracking: '/topic/train-tracking/',
  networkNextDeparture: '/topic/next-departure/',
  peakPeriodByLine: '/topic/peak-hours/line/',
  intervalByLine: '/topic/interval/line/',
  platformInfoTraffic: '/topic/platform-info-trafic/',
  disruptionsCurrent: '/topic/disruptions/current/',
  disruptionsMerged: '/topic/disruptions/merged/',
  disruptionTrafficSituations: '/topic/trafic-situation/',
  disruptionAllTrafficSituations: '/topic/trafic-situation',
  tcoCdv: `/topic/tco-cdv/`,
  tcoCdvRer: `/topic/tco-cdv-rer/`,
  tcoTrafficLight: `/topic/tco-traffic-light/`,
  tcoSubSection: `/topic/tco-sub-section/`,
  tcoSwitch: `/topic/tco-switch/`,
  tkOffPeakHour: '/topic/tk-off-peak-hour',
  tkOffPeakHourByLine: '/topic/tk-off-peak-hour/line/',
  tkGlobal: '/topic/tk-global',
  disruptionLineInfo: '/topic/line-info/',

  // lineMapping
  linesMapping: `${environment.trafficMobileApiBaseUrl}${trafficMobileApi}/line_mapping/`,
  realTimeTrafficLinesMapping: `/real-time-traffic/map/`,

  // notification configuration API
  devices: `${environment.notificationApiBaseUrl}${trafficMobileNotificationApi}/devices`,
  updateDeviceNotification: `${environment.notificationApiBaseUrl}${trafficMobileNotificationApi}/devices/notification`,
  notifications: `${environment.notificationApiBaseUrl}${trafficMobileNotificationApi}/notifications`,
  desktopNotification: `${environment.notificationApiBaseUrl}${trafficMobileNotificationApi}/notifications/desktop`,

  // actuator
  actuator: `${environment.authenticationApiBaseUrl}/actuator/info`,

  // UserProfile
  userProfile: `${environment.authenticationApiBaseUrl}/auth/api/user-profile/`,

  // Analytics
  analytics: `${environment.authenticationApiBaseUrl}/auth/api/analytics/`,
}
