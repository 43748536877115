<ion-grid>
  <ion-row *ngIf="!!intervalStatistics">
    <ion-col size="4">
      <app-interval-cartridge [intervalKey]="'metroStatistics'" [intervalStatistics]="intervalStatistics.metroIntervalStatistics"></app-interval-cartridge>
    </ion-col>
    <ion-col size="4">
      <app-interval-cartridge [intervalKey]="'rerStatistics'" [intervalStatistics]="intervalStatistics.rerIntervalStatistics"></app-interval-cartridge>
    </ion-col>
    <ion-col size="4">
      <app-interval-cartridge [intervalKey]="'globalStatistics'" [intervalStatistics]="intervalStatistics.globalIntervalStatistics"></app-interval-cartridge>
    </ion-col>
  </ion-row>
</ion-grid>
