import Phaser from 'phaser'
import { Subject } from 'rxjs'
import { ContainerTrackElement } from './game-objects/groups/track/track.element'
import { ContainerTrainElement } from './game-objects/groups/train/train.element'
import { GameStateService } from '../app/core/game/services/game-state.service'
import { GameTouchStateService } from '../app/core/game/services/game-touch-state.service'

export class MainGame extends Phaser.Game {
  private readonly _gameStateService: GameStateService
  private readonly _gameTouchStateService: GameTouchStateService

  constructor(gameConfiguration: Phaser.Types.Core.GameConfig, gameStateService: GameStateService, gameTouchStateService: GameTouchStateService) {
    super(gameConfiguration)
    this._gameStateService = gameStateService
    this._gameTouchStateService = gameTouchStateService
  }

  get gameStateService(): GameStateService {
    return this._gameStateService
  }

  get gameTouchStateService(): GameTouchStateService {
    return this._gameTouchStateService
  }

  private _touchedTrackObjectSubject: Subject<ContainerTrackElement> = new Subject<ContainerTrackElement>()
  get touchedTrackObjectSubject(): Subject<ContainerTrackElement> {
    return this._touchedTrackObjectSubject
  }

  private _touchedTrainObjectSubject: Subject<ContainerTrainElement> = new Subject<ContainerTrainElement>()
  get touchedTrainObjectSubject(): Subject<ContainerTrainElement> {
    return this._touchedTrainObjectSubject
  }
}
