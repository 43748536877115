import 'phaser'

import { MainScene } from '../scenes/main'
import { phaserPlugins } from './phaser.plugins'

export const CONFIGURATION_MOBILE = {
  type: Phaser.AUTO,
  backgroundColor: '#F4F4F4',
  scene: [MainScene],
  parent: 'phaser',
  title: 'liveTrafic-mobile',
  height: 300,
  autoCenter: Phaser.Scale.Center.CENTER_BOTH,
  _resolution: window.devicePixelRatio,
  plugins: phaserPlugins,
  scale: {
    mode: Phaser.Scale.ENVELOP,
  },
  render: {
    roundPixels: true,
  },
}
