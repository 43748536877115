import { Injectable } from '@angular/core'
import 'phaser'
import { GameConfigurationService } from './game-configuration.service'
import { MainGame } from '../../../phaser/main.game'
import { GameStateService } from './services/game-state.service'
import { GameTouchStateService } from './services/game-touch-state.service'

@Injectable({
  providedIn: 'root',
})
export class GameProviderService {
  constructor(private gameConfigurationService: GameConfigurationService) {}

  get(gameSateService: GameStateService, gameTouchStateService: GameTouchStateService): Phaser.Game {
    return new MainGame(this.gameConfigurationService.getGameConfiguration(), gameSateService, gameTouchStateService)
  }
}
