<ion-button
  (click)="openMenu()"
  *ngIf="platformService.isPlatformDesktop() || platformService.isPlatformTablet(); else mobile"
  [ngClass]="{
    'button-waiting': userPreferences.selectedPlatformInfo === platformInfoType.WAITING,
    'button-elapsed': userPreferences.selectedPlatformInfo === platformInfoType.ELAPSED,
    'time-selected': userPreferences.selectedPlatformInfo !== platformInfoType.NONE
  }"
  class="time"
>
  <ion-icon
    [name]="userPreferences.selectedPlatformInfo == platformInfoType.NONE || userPreferences.selectedPlatformInfo == platformInfoType.WAITING ? 'time-outline' : 'hourglass-outline'"
    [ngClass]="{
      'icon-selected': userPreferences.selectedPlatformInfo === platformInfoType.WAITING || userPreferences.selectedPlatformInfo === platformInfoType.ELAPSED
    }"
    class="icon"
    slot="start"
  >
  </ion-icon>
  <ion-label
    [ngClass]="{
      selected: userPreferences.selectedPlatformInfo == platformInfoType.WAITING || userPreferences.selectedPlatformInfo == platformInfoType.ELAPSED
    }"
  >
    {{
      userPreferences.selectedPlatformInfo === platformInfoType.NONE
        ? ('app.realtime.traffic.platform.info.button.no.display' | translate)
        : userPreferences.selectedPlatformInfo === platformInfoType.WAITING
        ? ('app.realtime.traffic.platform.info.button.waiting' | translate)
        : ('app.realtime.traffic.platform.info.button.elapsed' | translate)
    }}
  </ion-label>
</ion-button>
<ng-template #mobile>
  <ion-button
    (click)="openMenu()"
    [ngClass]="{
      'button-waiting': userPreferences.selectedPlatformInfo === platformInfoType.WAITING,
      'button-elapsed': userPreferences.selectedPlatformInfo === platformInfoType.ELAPSED,
      'time-selected': userPreferences.selectedPlatformInfo !== platformInfoType.NONE
    }"
    class="time-mobile"
  >
    <ion-icon
      [name]="userPreferences.selectedPlatformInfo === platformInfoType.NONE || userPreferences.selectedPlatformInfo == platformInfoType.WAITING ? 'time-outline' : 'hourglass-outline'"
      [ngClass]="{
        'icon-selected': userPreferences.selectedPlatformInfo === platformInfoType.WAITING || userPreferences.selectedPlatformInfo === platformInfoType.ELAPSED
      }"
      class="icon icon-mobile"
    >
    </ion-icon>
  </ion-button>
</ng-template>
