import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { InterfaceConfig } from '../models/interval'
import { HttpClient } from '@angular/common/http'
import { paths } from '../../../environments/paths'

@Injectable({
  providedIn: 'root',
})
export class ApiIntervalConfigService {
  public intervalConfig$: Observable<InterfaceConfig>

  constructor(private httpClient: HttpClient) {
    this.intervalConfig$ = this.httpClient.get<InterfaceConfig>(paths.intervalConfig)
  }
}
