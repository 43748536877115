<ion-grid [class.no-padding]="platformService.isPlatformMobile()">
  <ion-row [class.no-padding]="platformService.isPlatformMobile()">
    <ion-col>
      <h4>
        {{ 'app.shared.dashboard.production.peak.period.detail.title' | translate }}
      </h4>
    </ion-col>
  </ion-row>
  <ion-row [class.no-padding]="platformService.isPlatformMobile()" class="parent-row">
    <ion-col [class.no-padding]="platformService.isPlatformMobile()">
      <ion-row class="separator peak-period-detail-list-header">
        <ion-col class="custom-padding" sizeLg="1" sizeMd="1" sizeXs="1.5"><span></span></ion-col>
        <ion-col class="custom-padding" sizeLg="8" sizeMd="8" sizeXs="8" *ngIf="platformService.isPlatformDesktop()">
          <span>{{ 'app.shared.dashboard.production.peak.period.detail.table.header.station.referential.desktop' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="8" sizeMd="8" sizeXs="8" *ngIf="!platformService.isPlatformDesktop()">
          <span>{{ 'app.shared.dashboard.production.peak.period.detail.table.header.station.referential.mobile' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2.5" sizeMd="2.5" sizeXs="2.5">
          <span>{{ 'app.shared.dashboard.production.peak.period.detail.table.header.theoretical' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2.5" sizeMd="2.5" sizeXs="2.5">
          <span>{{ 'app.shared.dashboard.production.peak.period.detail.table.header.real' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2.5" sizeMd="2.5" sizeXs="2.5">
          <span>{{ 'app.shared.dashboard.production.peak.period.detail.table.header.percentage' | translate }}</span>
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let peakPeriodDetailLine of peakPeriodDetailLines; let i = index" [class.white-line]="i % 2 == 0" class="separator peak-period-detail-list-row">
        <ion-col class="custom-padding-icon" sizeLg="1" sizeMd="1" sizeXs="1.5">
          <ion-icon [src]="'assets/icon/lines/' + peakPeriodDetailLine.lineName + '.svg'" class="ion-icon-list"></ion-icon>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="8" sizeMd="8" sizeXs="8">
          <span>{{ peakPeriodDetailLine.station }}</span></ion-col
        >
        <ion-col class="custom-padding" sizeLg="2.5" sizeMd="2.5" sizeXs="2.5">
          <span>{{ peakPeriodDetailLine.theoretical | NonZeroDisplay }}</span></ion-col
        >
        <ion-col class="custom-padding" sizeLg="2.5" sizeMd="2.5" sizeXs="2.5">
          <span>{{ peakPeriodDetailLine.real | NonZeroDisplay }}</span></ion-col
        >
        <ion-col class="custom-padding percent" sizeLg="2.5" sizeMd="2.5" sizeXs="2.5">
          <div [ngClass]="peakPeriodDetailLine.peakPeriodLevelColor">
            <span>{{ peakPeriodDetailLine.percentage * 100 | number: '2.0-0' | NonZeroDisplay }}</span>
            <span *ngIf="peakPeriodDetailLine.percentage">%</span>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
