import { Injectable } from '@angular/core'
import { Train, Trains } from '../../../../phaser/modeles/trains'
import { ContainerTrainElement, getAttendanceBackgroundColor, getAttendanceColor, getAttendanceYPosition, getHeight, getTrainColor, getTrainLineWidth, getTrainTransportColor, GroupTrainsElement } from '../../../../phaser/game-objects/groups/train/train.element'
import { SceneType } from '../../../../phaser/managers/scene.manager'
import { MainScene } from '../../../../phaser/scenes/main'
import { ContainerTrackElement, GroupTrackElement } from '../../../../phaser/game-objects/groups/track/track.element'
import { NGXLogger } from 'ngx-logger'
import { getTrainTypeFromLineName, TrainType } from '../../../../phaser/modeles/line.type.model'
import { userPreferences } from '../../configurations/user/user.preferences'
import { ATTENDANCE_BACKGROUND_HEIGHT, TRAIN_ELEMENTS_OFFSET_X } from 'src/phaser/game-objects/groups/train/train.view.config'

@Injectable({
  providedIn: 'root',
})
export class TrainsService {
  constructor(private logger: NGXLogger) {}

  private static trainHaveChangedTrack(containerTrainElement: ContainerTrainElement, correspondingTrack: ContainerTrackElement) {
    return containerTrainElement.trackNumber !== correspondingTrack.track
  }

  // This is the entry point, called as a refresh
  /**
   * Main entry point to refresh view of trains, this will take already drawn object as previous state
   * @param trains list of train to show , this is the nex state
   * @param game the Phaser game
   */
  drawTrains(trains: Trains, game: Phaser.Game): void {
    const mainScene = game.scene.getScene(SceneType.MAIN) as MainScene
    // on supprime tous les tweens pour libérer de la mémoire.
    if (!!mainScene) {
      mainScene.tweens.getAllTweens().forEach((tween) => tween.remove())
      const groupTrainsElement: GroupTrainsElement = mainScene.groupTrainsElement
      const groupTrackElement: GroupTrackElement = mainScene.groupTrackElementLineMapping

      if (groupTrainsElement && groupTrackElement) {
        this.updateTrains(trains, groupTrainsElement, groupTrackElement, mainScene)
      }
    }
  }

  /**
   * Clear All trains views and associated childs
   */
  clearTrainsGroup(phaserGame: Phaser.Game): void {
    const mainScene: MainScene = phaserGame.scene.getScene(SceneType.MAIN) as MainScene
    if (!!mainScene && !!mainScene.groupTrainsElement) {
      mainScene.tweens.getAllTweens().forEach((t) => t.remove()) // on supprime tous les tweens pur libérer de la mémoire.
      mainScene.groupTrainsElement.clear(true, true)
    }
  }

  // Main update view loop
  private updateTrains(newTrainsState: Trains, groupTrainsElement: GroupTrainsElement, groupTrackElement: GroupTrackElement, scene: MainScene) {
    const groupedDrawnByID: Map<string, Array<ContainerTrainElement>> = groupTrainsElement.getGroupedContainerById()
    const groupedTrainsByTrainNumber: Map<string, Array<Train>> = this.getGroupedTrainsById(newTrainsState.trains)

    const trainType: TrainType = getTrainTypeFromLineName(newTrainsState.lineName)

    // First move or remove already drawn Train
    const movedTrainsIds = this.moveAndUpdateAlreadyDrawnTrains(groupedDrawnByID, groupedTrainsByTrainNumber, groupTrackElement, scene, trainType)
    this.removeAllTrainsWhichHaveNotBeenMoved(movedTrainsIds, groupTrainsElement)
    // Draw all train not moved
    this.createTrainsWhichHaveNotBeenMoved(groupedTrainsByTrainNumber, movedTrainsIds, scene, groupTrainsElement, trainType)
  }

  /**
   * This method will move train container having a unique train id and a unique correspondence in trains entry data.
   * All other will be removed
   */
  private moveAndUpdateAlreadyDrawnTrains(groupedDrawnByID: Map<string, Array<ContainerTrainElement>>, groupedTrainsByTrainNumber: Map<string, Array<Train>>, groupTrackElement: GroupTrackElement, scene: MainScene, trainType: TrainType) {
    const movedTrainsNumbers: Array<string> = new Array<string>()

    groupedDrawnByID.forEach((trainsContainers, id) => {
      const correspondingTrains = groupedTrainsByTrainNumber.get(id)

      if (trainsContainers.length === 1 && correspondingTrains !== undefined && correspondingTrains.length === 1) {
        const correspondingTrain: Train = correspondingTrains[0]
        const containerTrainElement: ContainerTrainElement = trainsContainers[0]
        const correspondingTrack: ContainerTrackElement = groupTrackElement.getContainerByTrafficWindow(correspondingTrain.windowNumber)

        if (correspondingTrack) {
          if (!TrainsService.trainHaveChangedTrack(containerTrainElement, correspondingTrack)) {
            this.updateTrainContainerFromTrain(containerTrainElement, correspondingTrain, trainType, correspondingTrack.track)
            containerTrainElement.moveToTheNextTrackWithAnimation(correspondingTrack, scene)
            movedTrainsNumbers.push(containerTrainElement.id)
          }
        } else {
          this.logger.warn('No corresponding track to put the train on: ' + correspondingTrain.windowNumber + ' train will stay at current location x: ' + containerTrainElement.x + ' y: ' + containerTrainElement.y)
        }
      }
    })
    return movedTrainsNumbers
  }

  /**
   * Create all trains view container that have not been moved before.
   */
  private createTrainsWhichHaveNotBeenMoved(groupedTrainsByTrainNumber: Map<string, Array<Train>>, movedTrainsIds: Array<string>, scene: MainScene, groupTrainsElement: GroupTrainsElement, trainType: TrainType) {
    groupedTrainsByTrainNumber.forEach((trainsWithSameNumber, trainNumber) => {
      if (movedTrainsIds.indexOf(trainNumber) === -1) {
        trainsWithSameNumber.forEach((train) => {
          const correspondingTrack = scene.groupTrackElementLineMapping.getContainerByTrafficWindow(train.windowNumber)
          if (!!correspondingTrack) {
            const containerTrainElement = new ContainerTrainElement(scene, train, { x: correspondingTrack.x, y: correspondingTrack.positionYOfCdv }, trainType, correspondingTrack.track, userPreferences.selectedTrainAffluence)
            groupTrainsElement.add(containerTrainElement, true)
          } else {
            this.logger.warn('No corresponding track to put the train on: ' + train.number + ' incorrect track id is : ' + train.windowNumber)
          }
        })
      }
    })
  }

  private getGroupedTrainsById(trains: Train[]): Map<string, Array<Train>> {
    const map: Map<string, Array<Train>> = new Map<string, Array<Train>>()
    trains.forEach((train) => {
      let trainsWithSameId: Array<Train> = map.get(train.number)
      if (trainsWithSameId == null) {
        trainsWithSameId = new Array<Train>()
        map.set(train.number, trainsWithSameId)
      }
      trainsWithSameId.push(train)
    })
    return map
  }

  private removeAllTrainsWhichHaveNotBeenMoved(movedTrainsIds: Array<string>, groupTrainsElement: GroupTrainsElement) {
    groupTrainsElement.getAllContainerTrainElement().forEach((trainContainer) => {
      if (!movedTrainsIds.includes(trainContainer.id)) {
        groupTrainsElement.clearContainerTrainElement(trainContainer)
      }
    })
  }

  private updateTrainContainerFromTrain(containerTrainElement: ContainerTrainElement, correspondingTrain: Train, trainType: TrainType, track: number) {
    containerTrainElement.train = correspondingTrain
    containerTrainElement.trainElement.setTint(getTrainColor(correspondingTrain))
    containerTrainElement.trainElementBackground.setStrokeStyle(getTrainLineWidth(correspondingTrain), getTrainColor(correspondingTrain))
    containerTrainElement.trainElementBackground.setFillStyle(getTrainTransportColor(correspondingTrain))

    if (correspondingTrain.attendanceLevel !== 'NO_INFO' && userPreferences.selectedTrainAffluence) {
      const height = getHeight(correspondingTrain.attendanceLevel)
      const width = trainType === TrainType.METRO ? 20 : 45
      const yPosition = getAttendanceYPosition(correspondingTrain.attendanceLevel)

      containerTrainElement.addAt(containerTrainElement.trainElementAttendanceBackground, 2)
      containerTrainElement.addAt(containerTrainElement.trainElementAttendance, 3)

      // Attention! Keep this order, background color must be updated before attendanceLevel
      const backgroundColor = getAttendanceBackgroundColor(correspondingTrain.attendanceLevel)
      containerTrainElement.trainElementAttendanceBackground.setFillStyle(backgroundColor)
      containerTrainElement.trainElementAttendanceBackground.setPosition(TRAIN_ELEMENTS_OFFSET_X, 0)
      containerTrainElement.trainElementAttendanceBackground.resize(width, ATTENDANCE_BACKGROUND_HEIGHT)

      const color = getAttendanceColor(correspondingTrain.attendanceLevel)
      containerTrainElement.trainElementAttendance.setFillStyle(color)
      containerTrainElement.trainElementAttendance.setPosition(TRAIN_ELEMENTS_OFFSET_X, yPosition)
      containerTrainElement.trainElementAttendance.resize(width, height)
    } else {
      containerTrainElement.remove(containerTrainElement.trainElementAttendanceBackground)
      containerTrainElement.remove(containerTrainElement.trainElementAttendance)
    }

    containerTrainElement.containerDelayDisplayElement.updateView(userPreferences.selectedTrainDelay, correspondingTrain, trainType, track)
  }
}
