import { Component } from '@angular/core'
import { UserPreferences, userPreferences } from '../../core/configurations/user/user.preferences'
import { PlatformService } from '../../core/services/platform.service'
import { AnalyticsService } from '../../core/services/analytics.service'

@Component({
  selector: 'app-incident-switch',
  templateUrl: './incident-switch.component.html',
  styleUrls: ['./incident-switch.component.scss'],
})
export class IncidentSwitchComponent {
  displayIncident = true

  constructor(public platformService: PlatformService, private analyticsService: AnalyticsService) {}

  private _userPreferences = userPreferences

  get userPreferences(): UserPreferences {
    return this._userPreferences
  }

  get iconUrl(): string {
    return this.displayIncident ? '../assets/svgs/live-events/icon_mobile_team.svg' : '../assets/svgs/live-events/icon_mobile_team_activated.svg'
  }

  async switchDisplayIncident() {
    this.displayIncident = !this.displayIncident
    userPreferences.selectedIncidents = this.displayIncident
    this.analyticsService.pushLogEvent({
      eventName: 'button_display_incident',
      eventParameter: {
        incident_selection: this.displayIncident,
      },
    })
  }
}
