import 'phaser'
import { DelayDisplayElement } from './delay.element.image'
import { TrainDelayText } from './delay.element.text'
import { Train } from '../../../modeles/trains'
import { TrainType } from '../../../modeles/line.type.model'
import { SelectedTrainDelay } from '../../../../app/core/configurations/user/selected.train.delay'

export class ContainerDelayDisplayElement extends Phaser.GameObjects.Container {
  private readonly _delayDisplayElement: DelayDisplayElement
  private readonly _trainDelayText: TrainDelayText

  constructor(scene: Phaser.Scene, train: Train, selectedDelay: SelectedTrainDelay, trainType: TrainType, trackNumber: number) {
    super(scene)
    this._delayDisplayElement = new DelayDisplayElement(scene, train, selectedDelay, trainType, trackNumber)
    this._trainDelayText = new TrainDelayText(scene, train, selectedDelay, trainType, trackNumber)
    this.add([this._delayDisplayElement, this._trainDelayText])
  }

  get trainDelayText(): TrainDelayText {
    return this._trainDelayText
  }

  /**
   * Permet de mettre à jour l'affichage du retard d'un train
   * @param selectedDelay La du retard à afficher (NO_DISPLAY, ON_COURSE, ON_DAY)
   * @param train Le train à mettre à jour
   * @param trainType RER OR METRO
   * @param trackPosition the position on track, 1 or 2
   */
  updateView(selectedDelay: SelectedTrainDelay, train: Train, trainType: TrainType, trackPosition: number) {
    this._delayDisplayElement.updateDelayElementImage(train, selectedDelay, trainType, trackPosition)
    this._trainDelayText.updateDelayElementText(train, selectedDelay, trainType, trackPosition)
  }
}
