<div class="line-selector-container" *ngIf="allLines$ | async as lines">
  <ion-select
    multiple="true"
    [formControl]="ionSelect"
    [interfaceOptions]="{
      header: translate.get('app.shared.lineselector.title') | async
    }"
    [placeholder]="translate.get('app.shared.lineselector.placeholder') | async"
    [okText]="translate.get('app.shared.lineselector.okText') | async"
    [cancelText]="translate.get('app.shared.lineselector.cancelText') | async"
  >
    <ion-select-option *ngFor="let line of lines" [value]="line">{{ 'app.shared.lineselector.row' | translate: { line: line } }} </ion-select-option>
  </ion-select>
</div>
