<ion-row *ngIf="peakPeriodGlobalTimeSubject | async as time" [class.ion-justify-content-start]="platformService.isPlatformDesktop()" class="ion-no-padding">
  <div class="button" (click)="selectPeriod('MORNING')" [class.select]="time == 'MORNING'">
    <ion-label>
      {{ 'app.shared.dashboard.production.peak.period.mobile.tab.peak.period.morning' | translate }}
    </ion-label>
  </div>
  <div class="button" (click)="selectPeriod('EVENING')" [class.select]="time == 'EVENING'">
    <ion-label>{{ 'app.shared.dashboard.production.peak.period.mobile.tab.peak.period.evening' | translate }}</ion-label>
  </div>
</ion-row>
