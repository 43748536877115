import { Component } from '@angular/core'
import { UrlService } from '../../core/services/url.service'
import { PlatformService } from '../../core/services/platform.service'

export type LiveTrafficSwitcherType = 'NEXT_DEPARTURE' | 'LINE_MAPPING'

@Component({
  selector: 'app-live-traffic-switcher-button',
  templateUrl: './live-traffic-switcher-button.component.html',
  styleUrls: ['./live-traffic-switcher-button.component.scss'],
})
export class LiveTrafficSwitcherButtonComponent {
  constructor(public platformService: PlatformService, public urlService: UrlService) {}
}
