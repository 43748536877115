import { Component, Injector } from '@angular/core'
import { Platform, ToastController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { from, Observable, OperatorFunction, Subject } from 'rxjs'
import { debounceTime, flatMap, map, tap } from 'rxjs/operators'

@Component({
  selector: 'app-snake-bar',
  templateUrl: './snack-bar.component.html',
})
export class SnackBarComponent {
  messageSubject: Subject<string> = new Subject<string>()
  private translateService: TranslateService
  private toast

  constructor(public toastController: ToastController, private platform: Platform, private injector: Injector) {
    platform.ready().then(() => {
      this.translateService = injector.get(TranslateService)
      this.init().then()
    })

    this.messageSubject
      .pipe(
        this.bufferDebounceTime(500),
        flatMap((message) => from(this.presentToastWithOptions(message, 'bottom')))
      )
      .subscribe()
  }

  /**
   * little hack to load component and avoid isproxied du to lake of loading component without network
   */
  async init() {
    const toast = await this.toastController.create({ animated: false })
    await toast.present()
    await toast.dismiss()
  }

  async presentToastWithOptions(messages: string[], position: 'top' | 'bottom' | 'middle'): Promise<boolean> {
    if (!!this.toast) {
      this.toastController.dismiss().then()
    }
    const messagesTranslated = messages.map((message) => this.translateService.instant(message)).reduce((p, c) => (p.includes(c) ? p : [...p, c]), [])
    const messageTranslated = messagesTranslated.join('\n')
    this.toast = await this.toastController.create({
      message: messageTranslated,
      position,
      cssClass: 'toast-custom-class',
      buttons: [
        {
          text: this.translateService.instant('app.shared.toast.button'),
          role: 'close',
          side: 'end',
          handler: () => {},
        },
      ],
    })
    await this.toast.present()
    await this.toast.onDidDismiss()
    this.toast = null
    return true
  }

  bufferDebounceTime<T>(time: number = 0): OperatorFunction<T, T[]> {
    return (source: Observable<T>) => {
      let bufferedValues: T[] = []

      return source.pipe(
        tap((value) => bufferedValues.push(value)),
        debounceTime(time),
        map(() => bufferedValues),
        tap(() => (bufferedValues = []))
      )
    }
  }
}
