<ion-button
  (click)="switchDisplayAffluence()"
  *ngIf="platformService.isPlatformDesktop() || platformService.isPlatformTablet(); else mobile"
  [ngClass]="{
    'button-selected': userPreferences.selectedTrainAffluence,
    'affluence-selected': userPreferences.selectedTrainAffluence
  }"
  class="affluence"
>
  <ion-icon [name]="'people'" [ngClass]="{ 'icon-selected': userPreferences.selectedTrainAffluence }" class="icon" slot="start"> </ion-icon>
  <ion-label [ngClass]="{ selected: userPreferences.selectedTrainAffluence }">
    {{ 'app.realtime.traffic.platform.info.button.affluence' | translate }}
  </ion-label>
</ion-button>
<ng-template #mobile>
  <ion-button
    (click)="switchDisplayAffluence()"
    [ngClass]="{
      'button-selected': userPreferences.selectedTrainAffluence,
      'affluence-selected': userPreferences.selectedTrainAffluence
    }"
    class="affluence-mobile"
  >
    <ion-icon [name]="'people'" [ngClass]="{ 'icon-selected': userPreferences.selectedTrainAffluence }" class="icon icon-mobile"> </ion-icon>
  </ion-button>
</ng-template>
