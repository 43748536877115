import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

export class SafeStringSvg {
  private readonly _safeHtml: SafeHtml
  private readonly _width: number
  private readonly _height: number

  constructor(svgText: string, sanitizer: DomSanitizer) {
    this._safeHtml = sanitizer.bypassSecurityTrustHtml(svgText)
    if (svgText) {
      const dom = new DOMParser().parseFromString(svgText, 'application/xml')
      const svgElement = dom.querySelector('svg')
      this._width = parseFloat(svgElement.getAttribute('width'))
      this._height = parseFloat(svgElement.getAttribute('height'))
    } else {
      this._width = 0
      this._height = 0
    }
  }

  get safeHtml(): SafeHtml {
    return this._safeHtml
  }

  get width(): number {
    return this._width
  }

  get height(): number {
    return this._height
  }
}
