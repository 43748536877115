import { Component, Input } from '@angular/core'
import { PlatformService } from '../../core/services/platform.service'
import { TkStatistics } from '../../core/models/tk'
import { PeakPeriodConfig } from '../../core/models/peak-periodConfig'
import { BehaviorSubject } from 'rxjs'
import { TkProductionType } from '../tk-switcher-button/tk-switcher-button.component'

@Component({
  selector: 'app-tk-statistics',
  templateUrl: './tk-statistics.component.html',
  styleUrls: ['./tk-statistics.component.scss'],
})
export class TkStatisticsComponent {
  @Input() tkStatistics: TkStatistics

  @Input() peakPeriodConfig: PeakPeriodConfig

  @Input() tkProductionSubject: BehaviorSubject<TkProductionType>

  @Input() productionInvalid: boolean

  constructor(public platformService: PlatformService) {}

  getEmptyTkStatistics(): TkStatistics {
    return {
      productionTheoretical: 0,
      productionReal: 0,
      productionPercentage: 0,
      lapsTheoretical: 0,
      lapsReal: 0,
      lapsPercentage: 0,
    }
  }
}
