import { MainScene } from '../scenes/main'

export class SceneController {
  static DURATION_PAN_CAMERA = 0

  private scene: MainScene

  constructor(scene: MainScene) {
    this.scene = scene
  }

  scrollCameraToCenterOfTheMap(positionX: number, positionY: number) {
    const mainCamera = this.scene.cameras.main
    mainCamera.pan(positionX, positionY, SceneController.DURATION_PAN_CAMERA)
  }

  resetPinch() {
    const mainCamera = this.scene.cameras.main
    mainCamera.zoom = 1
  }
}
