import { Injectable } from '@angular/core'
import { SocketService } from './socket.service'
import { Tk, TkLine } from '../../models/tk'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Subscription } from 'stompjs'
import { SocketConnectionService } from './socket-connection.service'
import { LocalStorageService } from '../../local-storage.service'
import { paths } from '../../../../environments/paths'
import { shareReplay, takeUntil, tap } from 'rxjs/operators'
import { ReferentialLine } from '../../models/lines'

@Injectable({
  providedIn: 'root',
})
export class ApiTkService extends SocketService<Tk | TkLine> {
  tkOffPeakHour: BehaviorSubject<Tk> = new BehaviorSubject<Tk>(null)
  tKOffPeakLineHour: BehaviorSubject<TkLine> = new BehaviorSubject<TkLine>(null)
  public state$: Observable<Tk> = this.tkOffPeakHour.asObservable()
  public stateByLine$: Observable<TkLine> = this.tKOffPeakLineHour.asObservable()
  private tkOffPeakHourSubscription: Subscription[]
  private tKOffPeakHourLinesSubscription: Subscription[]
  private destroy: Subject<boolean> = new Subject<boolean>()

  constructor(socketConnectionService: SocketConnectionService, localStorageService: LocalStorageService) {
    super(socketConnectionService, localStorageService)
  }

  async getTkStreamMessages(): Promise<Subscription[]> {
    if (!!this.tkOffPeakHourSubscription) {
      this.unSubscriptionTkStreamMessages()
    }
    this.tkOffPeakHour.next(null)
    return super
      .subscriptionWithAutoReconnection([paths.tkOffPeakHour], this.tkOffPeakHour)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionTkStreamMessages()),
        tap((subscription) => (this.tkOffPeakHourSubscription = [...subscription]))
      )
      .toPromise()
  }

  async getTkStreamMessagesByLine(referentialLine: ReferentialLine): Promise<Subscription[]> {
    if (!!this.tKOffPeakHourLinesSubscription) {
      this.unSubscriptionTkLinesStreamMessages()
    }
    this.tKOffPeakLineHour.next(null)
    return super
      .subscriptionWithAutoReconnection([`${paths.tkOffPeakHourByLine}${referentialLine.name}`], this.tKOffPeakLineHour)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionTkLinesStreamMessages()),
        tap((subscription) => (this.tKOffPeakHourLinesSubscription = [...subscription]))
      )
      .toPromise()
  }

  unSubscriptionTkStreamMessages(): void {
    if (!!this.tkOffPeakHourSubscription) {
      super.unSubscriptionAfterConnection(this.tkOffPeakHourSubscription)
    }
  }

  unSubscriptionTkLinesStreamMessages(): void {
    if (!!this.tKOffPeakHourLinesSubscription) {
      super.unSubscriptionAfterConnection(this.tKOffPeakHourLinesSubscription)
    }
  }
}
