export interface Train {
  number: string
  windowNumber: string
  windowType: string
  delayOnCourse: number
  delayOnDay: number
  shiftInfo?: ShiftInfo
  transportStatusType: TransportStatusType
  terminusColor?: TerminusColor
  destination?: string
  overParkingInfo?: OverParkingInfo
  attendanceLevel?: AttendanceLevelType
}

export interface Trains {
  lineName: string
  trains: Train[]
}

export interface ShiftInfo {
  number?: string
  terminusSuffix?: string
  operatorPersonalNumber?: string
  operatorLastName?: string
}

export type TransportStatusType = 'FULL_PASSENGER' | 'NO_PASSENGER' | 'UNIDENTIFIED' | 'UNKNOWN'

export type AttendanceLevelType = 'NO_INFO' | 'LOW' | 'MEDIUM' | 'HIGH' | 'VERY_HIGH' | 'FULL'

export enum AttendanceLevel {
  NO_INFO = 'NO_INFO',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
  FULL = 'FULL',
}

export type TerminusColor = 'BLUE' | 'YELLOW'

export interface OverParkingInfo {
  overParked?: boolean
  overParkingDelay?: number
}
