import { TrafficWindow } from '../../../modeles/lines'
import { BehaviorSubject } from 'rxjs'
import { TrackBehavior } from './track.behavior.modele'
import { appProperties } from '../../../../environments/app.properties'

export const FIRST_CDV_GAP = 17
export const SPLIT_SWITCH_GAP = 81
export const PLATFORM_SWITCH_GAP = 33
export const LINE_GAP = 36
export const LOOP_CDV_HEIGHT = 36
export const START_END_TRACK_HEIGHT = 18
export const TRIPLE_PLATFORM_SWITCH_TRACK_2_LEFT_HEIGHT = 100
export const TRIPLE_PLATFORM_SWITCH_TRACK_1_HEIGHT = 100
export const TRIPLE_PLATFORM_SWITCH_TRACK_2_RIGHT_HEIGHT = 132
export const SWITCH_ONE_WAY_TRACK = 33
export const END_TRACK_MARGIN = 1
export const START_TRACK_MARGIN = -5
export const SPLIT_SWITCH_LEFT_HEIGHT = 41
export type ElementType =
  | 'TRACK_CDV'
  | 'TRACK_CDV_TIME'
  | 'FIRST_CDV'
  | 'TRACK_EMPTY'
  | 'TRACK_SWITCH'
  | 'TRACK_SWITCH_UP'
  | 'TRACK_SWITCH_SMALL_RIGHT'
  | 'TRACK_SWITCH_SMALL_LEFT'
  | 'CDV'
  | 'PLATFORM'
  | 'PLATFORM_SWITCH'
  | 'PLATFORM_SWITCH_LEFT'
  | 'PLATFORM_SWITCH_RIGHT'
  | 'PLATFORM_SWITCH_TRACK_2_RIGHT'
  | 'PLATFORM_SWITCH_TRACK_2_LEFT'
  | 'PLATFORM_SWITCH_TRACK_1_RIGHT'
  | 'PLATFORM_SWITCH_TRACK_1_LEFT'
  | 'PLATFORM_SWITCH_ONE_WAY_TRACK'
  | 'PLATFORM_SWITCH_WITH_TIME_LEFT'
  | 'PLATFORM_SWITCH_WITH_TIME_RIGHT'
  | 'SWITCH_ONE_WAY_TRACK_1_RIGHT'
  | 'SWITCH_ONE_WAY_TRACK_2_RIGHT'
  | 'PLATFORM_SWITCH_RIGHT_NO_CHANGE'
  | 'TRIPLE_PLATFORM_SWITCH_TRACK_2_LEFT'
  | 'TRIPLE_PLATFORM_SWITCH_TRACK_2_RIGHT'
  | 'TRIPLE_PLATFORM_SWITCH_TRACK_1_LEFT'
  | 'TRIPLE_PLATFORM_SWITCH_TRACK_1_RIGHT'
  | 'IGNORED_SWITCH'
  | 'UNDEFINED'
  | 'SPLIT_SWITCH_RIGHT'
  | 'SPLIT_SWITCH_RIGHT_UP'
  | 'SPLIT_SWITCH_LEFT'
  | 'SPLIT_SWITCH_LEFT_UP'
  | 'SPLIT_SWITCH_LEFT_NO_CHANGE'
  | 'SPLIT_SWITCH_LEFT_NO_CHANGE_UP'
  | 'PLATFORM_SWITCH_SWITCH'
  | 'SPLIT_SWITCH_SINGLE_LEFT_TRACK_1'
  | 'SPLIT_SWITCH_SINGLE_LEFT_TRACK_2'
  | 'SERVICE_SWITCH'
  | 'END_TRACK'
  | 'START_TRACK'
  | 'LOOP_WITH_PLATFORM'
  | 'LOOP_RIGHT'
  | 'LOOP_LEFT'
  | 'START_TRACK_DOUBLE_PLATFORM'
  | 'START_TRACK_SWITCH'

export function isPlatformSwitch(tw: TrafficWindow) {
  return (
    tw.type === 'PLATFORM_SWITCH_LEFT' ||
    tw.type === 'PLATFORM_SWITCH_RIGHT' ||
    tw.type === 'PLATFORM_SWITCH_TRACK_2_RIGHT' ||
    tw.type === 'PLATFORM_SWITCH_TRACK_2_LEFT' ||
    tw.type === 'PLATFORM_SWITCH_TRACK_1_RIGHT' ||
    tw.type === 'PLATFORM_SWITCH_TRACK_1_LEFT' ||
    tw.type === 'PLATFORM_SWITCH_ONE_WAY_TRACK' ||
    tw.type === 'PLATFORM_SWITCH_SWITCH'
  )
}

export function isPlatformSwitchTime(elementType: ElementType) {
  return elementType === 'PLATFORM_SWITCH_WITH_TIME_LEFT' || elementType === 'PLATFORM_SWITCH_WITH_TIME_RIGHT'
}

export function isTriplePlatformSwitch(elementType: ElementType) {
  return elementType === 'TRIPLE_PLATFORM_SWITCH_TRACK_2_LEFT' || elementType === 'TRIPLE_PLATFORM_SWITCH_TRACK_1_LEFT' || elementType === 'TRIPLE_PLATFORM_SWITCH_TRACK_1_RIGHT'
}

export type PlatFormType = 'PLATFORM' | 'PLATFORM_WITH_CORRESPONDENCE' | 'NO_PLATFORM'

export function getSizeElement(type: ElementType) {
  switch (type) {
    case 'CDV':
      return 22
    case 'PLATFORM':
      return 46
    case 'TRACK_CDV':
      return 22
    case 'TRACK_CDV_TIME':
      return 23
    case 'TRACK_SWITCH':
      return 22
    case 'TRACK_SWITCH_UP':
      return 22
    case 'TRACK_SWITCH_SMALL_LEFT':
      return 22
    case 'TRACK_SWITCH_SMALL_RIGHT':
      return 22
    case 'SPLIT_SWITCH_RIGHT':
      return 82
    case 'SPLIT_SWITCH_LEFT':
      return 82
    case 'SPLIT_SWITCH_LEFT_NO_CHANGE':
      return 82
    case 'SPLIT_SWITCH_LEFT_NO_CHANGE_UP':
      return 82
    case 'SPLIT_SWITCH_SINGLE_LEFT_TRACK_1':
      return 82
    case 'SPLIT_SWITCH_SINGLE_LEFT_TRACK_2':
      return 82
    case 'SPLIT_SWITCH_RIGHT_UP':
      return 82
    case 'SPLIT_SWITCH_LEFT_UP':
      return 82
    case 'PLATFORM_SWITCH_TRACK_2_RIGHT':
      return 22
    case 'PLATFORM_SWITCH':
      return 22
    case 'PLATFORM_SWITCH_TRACK_2_LEFT':
      return 22
    case 'PLATFORM_SWITCH_TRACK_1_RIGHT':
      return 22
    case 'PLATFORM_SWITCH_TRACK_1_LEFT':
      return 22
    case 'PLATFORM_SWITCH_ONE_WAY_TRACK':
      return 22
    case 'PLATFORM_SWITCH_RIGHT_NO_CHANGE':
      return 22
    case 'PLATFORM_SWITCH_WITH_TIME_LEFT':
      return 22
    case 'PLATFORM_SWITCH_WITH_TIME_RIGHT':
      return 22
    case 'SWITCH_ONE_WAY_TRACK_1_RIGHT':
      return 22
    case 'SWITCH_ONE_WAY_TRACK_2_RIGHT':
      return 22
    case 'TRIPLE_PLATFORM_SWITCH_TRACK_2_LEFT':
      return 22
    case 'TRIPLE_PLATFORM_SWITCH_TRACK_2_RIGHT':
      return 22
    case 'TRIPLE_PLATFORM_SWITCH_TRACK_1_LEFT':
      return 22
    case 'TRIPLE_PLATFORM_SWITCH_TRACK_1_RIGHT':
      return 22
    case 'END_TRACK':
      return 0
    case 'START_TRACK':
      return 4
    case 'START_TRACK_SWITCH':
      return 4
    case 'LOOP_LEFT':
      return FIRST_CDV_GAP
    case 'FIRST_CDV':
      return 22 + FIRST_CDV_GAP
    default:
      return 0
  }
}

export function levelCalculation(trafficWindow: TrafficWindow): number {
  const level = trafficWindow.level
  const track = trafficWindow.track
  const type = trafficWindow.type
  switch (true) {
    case track === 1 && level === -6:
      return -PLATFORM_SWITCH_GAP * 2
    case track === 2 && type === 'TRIPLE_PLATFORM_SWITCH_TRACK_2_LEFT':
      return TRIPLE_PLATFORM_SWITCH_TRACK_2_LEFT_HEIGHT / 2
    case track === 1 && (type === 'TRIPLE_PLATFORM_SWITCH_TRACK_1_LEFT' || type === 'TRIPLE_PLATFORM_SWITCH_TRACK_1_RIGHT'):
      return TRIPLE_PLATFORM_SWITCH_TRACK_1_HEIGHT * -0.33
    case type === 'LOOP_WITH_PLATFORM':
      return LOOP_CDV_HEIGHT / 2
    case type === 'LOOP_RIGHT':
      return LOOP_CDV_HEIGHT / 2
    case type === 'LOOP_LEFT':
      return LOOP_CDV_HEIGHT / 2
    case track === 2 && type === 'TRIPLE_PLATFORM_SWITCH_TRACK_2_RIGHT':
      return TRIPLE_PLATFORM_SWITCH_TRACK_2_RIGHT_HEIGHT / 2
    case track === 2 && type === 'SWITCH_ONE_WAY_TRACK_2_RIGHT':
      return SWITCH_ONE_WAY_TRACK / 2
    case track === 1 && type === 'SWITCH_ONE_WAY_TRACK_2_RIGHT':
      return SWITCH_ONE_WAY_TRACK * -0.5
    case track === 1 && type === 'SWITCH_ONE_WAY_TRACK_1_RIGHT':
      return -SWITCH_ONE_WAY_TRACK / 2
    case track === 2 && type === 'SWITCH_ONE_WAY_TRACK_1_RIGHT':
      return -SWITCH_ONE_WAY_TRACK * -0.5
    case level === 6 && track === 2:
      return PLATFORM_SWITCH_GAP * 3.01
    case level === 5 && track === 2:
      return PLATFORM_SWITCH_GAP * 2
    case level === -5 && track === 1:
      return -PLATFORM_SWITCH_GAP * 2
    case (level === 0 || level === 1) && (type === 'SPLIT_SWITCH_SINGLE_LEFT_TRACK_1' || type === 'SPLIT_SWITCH_SINGLE_LEFT_TRACK_2'):
      return -SPLIT_SWITCH_LEFT_HEIGHT
    case type === 'TRACK_SWITCH_SMALL_RIGHT' && track === 2 && level === 0:
      return PLATFORM_SWITCH_GAP - 22
    case type === 'TRACK_SWITCH_SMALL_RIGHT' && track === 1 && level === 2:
      return PLATFORM_SWITCH_GAP - 55
    case type === 'TRACK_SWITCH_SMALL_LEFT':
      return PLATFORM_SWITCH_GAP + 70
    case level === 0:
      return 0
    case level === -3 && track === 1:
      return -PLATFORM_SWITCH_GAP - SPLIT_SWITCH_GAP
    case level > 2 && track === 2:
      return PLATFORM_SWITCH_GAP + SPLIT_SWITCH_GAP
    case level > 2 && track === 1:
      return -PLATFORM_SWITCH_GAP + SPLIT_SWITCH_GAP
    case level < -2:
      return PLATFORM_SWITCH_GAP - SPLIT_SWITCH_GAP
    case level > 1 && track === 1:
      return -PLATFORM_SWITCH_GAP
    case level > 1 && track === 2:
      return PLATFORM_SWITCH_GAP
    case level < -1:
      return -PLATFORM_SWITCH_GAP
    default:
      return level * SPLIT_SWITCH_GAP
  }
}

export function getPosition(tw: TrafficWindow, position: BehaviorSubject<TrackBehavior>) {
  const _level = Math.abs(tw.level)
  return tw.track === 2
    ? tw.level === -1
      ? position.value.track2.secondaryBranch
      : _level === 2
      ? position.value.track2.primaryBranchDoublePlatform
      : _level === 3
      ? position.value.track2.secondaryBranchDoublePlatform
      : position.value.track2.primaryBranch
    : tw.level === -1
    ? position.value.track1.secondaryBranch
    : _level === 2
    ? position.value.track1.primaryBranchDoublePlatform
    : _level === 3
    ? position.value.track1.secondaryBranchDoublePlatform
    : _level === 6
    ? position.value.track1.primaryBranchTriplePlatform
    : position.value.track1.primaryBranch
}

export function splitLengthRule(platFormName: string): string {
  return !!platFormName && platFormName.length > appProperties.stationLengthMaxSize ? `${platFormName.substr(0, 13)}.` : platFormName
}

export function selectInformationFromElementToDisplay(windows: TrafficWindow): string {
  if (windows.platFormDisplayName) {
    return windows.platFormDisplayName
  } else {
    return !!windows.platFormName ? splitLengthRule(windows.platFormName) : ''
  }
}

export function getPlatformNameLength(windows: TrafficWindow): number {
  if (windows.platFormDisplayName) {
    return windows.platFormDisplayName.length
  } else {
    return !!windows.platFormName ? splitLengthRule(windows.platFormName).length : 0
  }
}

export function setPlateFormType(window: TrafficWindow): ElementType | PlatFormType {
  //
  if (window.platformType !== null) {
    return window.platformType
  }
  //
  if (window.track === 1 && window.type === 'SPLIT_SWITCH_LEFT_NO_CHANGE') {
    window.type = 'SPLIT_SWITCH_LEFT_NO_CHANGE_UP'
  }
  //
  if (window.track === 1 && window.type === 'SPLIT_SWITCH_RIGHT') {
    window.type = 'SPLIT_SWITCH_RIGHT_UP'
  }
  //
  if (window.track === 1 && window.type === 'SPLIT_SWITCH_LEFT') {
    window.type = 'SPLIT_SWITCH_LEFT_UP'
  }
  //
  if (window.track === 1 && window.type === 'TRACK_SWITCH') {
    window.type = 'TRACK_SWITCH_UP'
  }
  //
  if (window.track === 1 && window.type === 'PLATFORM_SWITCH_LEFT') {
    window.type = 'PLATFORM_SWITCH_TRACK_1_LEFT'
  }
  if (window.track === 2 && window.type === 'PLATFORM_SWITCH_LEFT') {
    window.type = 'PLATFORM_SWITCH_TRACK_2_LEFT'
  }
  //
  if (window.track === 1 && window.type === 'PLATFORM_SWITCH_RIGHT') {
    window.type = 'PLATFORM_SWITCH_TRACK_1_RIGHT'
  }
  if (window.track === 2 && window.type === 'PLATFORM_SWITCH_RIGHT') {
    window.type = 'PLATFORM_SWITCH_TRACK_2_RIGHT'
  }
  return window.type
}

export function positionFromNewContainer(tw: TrafficWindow, position: BehaviorSubject<TrackBehavior>) {
  // ici on créer l incrémentation en fonction de s'il y double quai et/ou fourche
  const _level = Math.abs(tw.level)
  return tw.track === 1
    ? {
        track1: {
          primaryBranch: getPrimaryBranch(position, tw, _level),
          primaryBranchDoublePlatform: getPrimaryBranchDoublePlatform(position, _level, tw),
          primaryBranchTriplePlatform: getPrimaryBranchTriplePlatformFirstTrack(position, _level, tw),
          secondaryBranch: getSecondaryBranch(position, tw),
          secondaryBranchDoublePlatform: getSecondaryBranchDoublePlatform(position, _level, tw),
          switchCase: tw.type === 'SPLIT_SWITCH_RIGHT' || tw.type === 'SPLIT_SWITCH_RIGHT_UP' || tw.type === 'SPLIT_SWITCH_LEFT' || tw.type === 'SPLIT_SWITCH_LEFT_UP' ? !position.value.track1.switchCase : position.value.track1.switchCase,
          startingSwitchCase: tw.type === 'START_TRACK_SWITCH' ? true : position.value.track1.startingSwitchCase && (tw.type === 'SPLIT_SWITCH_LEFT_NO_CHANGE' || tw.type === 'SPLIT_SWITCH_LEFT_NO_CHANGE_UP') ? false : position.value.track1.startingSwitchCase,
          platformSwitchCase: isPlatformSwitch(tw) ? !position.value.track1.platformSwitchCase : position.value.track1.platformSwitchCase,
          platformSwitchTimeCase: isPlatformSwitchTime(tw.type) ? !position.value.track1.platformSwitchTimeCase : position.value.track1.platformSwitchTimeCase,
          triplePlatformSwitchCase: isTriplePlatformSwitch(tw.type) ? !position.value.track1.triplePlatformSwitchCase : position.value.track1.triplePlatformSwitchCase,
        },
      }
    : {
        track2: {
          primaryBranch: getPrimaryBranchSecondTrack(position, tw, _level),
          primaryBranchDoublePlatform: getPrimaryBranchDoublePlatformSecondTrack(position, _level, tw),
          primaryBranchTriplePlatform: getPrimaryBranchTriplePlatformSecondTrack(position, _level, tw),
          secondaryBranch: getSecondaryBranchSecondTrack(position, tw),
          secondaryBranchDoublePlatform:
            (position.value.track2.platformSwitchCase || position.value.track2.startingSwitchCase) && _level === 3
              ? position.value.track2.secondaryBranchDoublePlatform + getSizeElement(tw.type)
              : position.value.track2.startingSwitchCase
              ? position.value.track2.secondaryBranch
              : position.value.track2.secondaryBranch + getSizeElement(tw.type),
          switchCase: tw.type === 'SPLIT_SWITCH_RIGHT' || tw.type === 'SPLIT_SWITCH_RIGHT_UP' || tw.type === 'SPLIT_SWITCH_LEFT' || tw.type === 'SPLIT_SWITCH_LEFT_UP' ? !position.value.track2.switchCase : position.value.track2.switchCase,
          startingSwitchCase: tw.type === 'START_TRACK_SWITCH' ? true : position.value.track2.startingSwitchCase && (tw.type === 'SPLIT_SWITCH_LEFT_NO_CHANGE' || tw.type === 'SPLIT_SWITCH_LEFT_NO_CHANGE_UP') ? false : position.value.track2.startingSwitchCase,
          platformSwitchCase: isPlatformSwitch(tw) ? !position.value.track2.platformSwitchCase : position.value.track2.platformSwitchCase,
          triplePlatformSwitchCase: isTriplePlatformSwitch(tw.type) ? !position.value.track2.triplePlatformSwitchCase : position.value.track2.triplePlatformSwitchCase,
        },
      }
}

function getPrimaryBranchDoublePlatformSecondTrack(position: BehaviorSubject<TrackBehavior>, _level: number, tw: TrafficWindow): number {
  return (position.value.track2.triplePlatformSwitchCase && _level === 2) || (position.value.track2.platformSwitchCase && _level === 2)
    ? position.value.track2.primaryBranchDoublePlatform + getSizeElement(tw.type)
    : position.value.track2.triplePlatformSwitchCase && _level !== 2
    ? position.value.track2.primaryBranch
    : position.value.track2.platformSwitchCase
    ? position.value.track2.primaryBranchDoublePlatform
    : position.value.track2.primaryBranch + getSizeElement(tw.type)
}

function getPrimaryBranchTriplePlatformSecondTrack(position: BehaviorSubject<TrackBehavior>, _level: number, tw: TrafficWindow): number {
  return position.value.track2.primaryBranch
}

function getSecondaryBranchDoublePlatform(position: BehaviorSubject<TrackBehavior>, _level: number, tw: TrafficWindow) {
  return tw.level === -3 ? position.value.track1.secondaryBranchDoublePlatform + getSizeElement(tw.type) : position.value.track1.secondaryBranch
}

function getPrimaryBranchDoublePlatform(position: BehaviorSubject<TrackBehavior>, _level: number, tw: TrafficWindow) {
  return position.value.track1.triplePlatformSwitchCase && _level !== 2
    ? position.value.track1.primaryBranchDoublePlatform
    : position.value.track1.triplePlatformSwitchCase && _level === 2
    ? position.value.track1.primaryBranchDoublePlatform + getSizeElement(tw.type)
    : position.value.track1.platformSwitchCase && _level === 2
    ? position.value.track1.primaryBranchDoublePlatform + getSizeElement(tw.type)
    : position.value.track1.platformSwitchCase
    ? position.value.track1.primaryBranchDoublePlatform
    : position.value.track1.primaryBranch + getSizeElement(tw.type)
}

function getPrimaryBranch(position: BehaviorSubject<TrackBehavior>, tw: TrafficWindow, _level: number) {
  return (position.value.track1.triplePlatformSwitchCase && _level !== 0) ||
    (position.value.track1.platformSwitchTimeCase && _level === 5) ||
    (position.value.track1.startingSwitchCase && (tw.level < -1 || tw.level === 1)) ||
    (position.value.track1.switchCase && tw.level < 0) ||
    (position.value.track1.platformSwitchCase && _level >= 2 && _level < 5)
    ? position.value.track1.primaryBranch
    : position.value.track1.primaryBranch + getSizeElement(tw.type)
}

function getSecondaryBranch(position: BehaviorSubject<TrackBehavior>, tw: TrafficWindow) {
  return !position.value.track1.switchCase && !position.value.track1.startingSwitchCase ? position.value.track1.primaryBranch + getSizeElement(tw.type) : tw.level === -1 ? position.value.track1.secondaryBranch + getSizeElement(tw.type) : position.value.track1.secondaryBranch
}

function getPrimaryBranchSecondTrack(position: BehaviorSubject<TrackBehavior>, tw: TrafficWindow, _level: number): number {
  return (position.value.track2.triplePlatformSwitchCase && _level > 4) || (position.value.track2.startingSwitchCase && tw.level === 1)
    ? position.value.track2.primaryBranch
    : position.value.track2.triplePlatformSwitchCase && _level === 2
    ? position.value.track2.primaryBranchDoublePlatform + getSizeElement(tw.type)
    : (position.value.track2.switchCase && tw.level < 0) || (position.value.track2.platformSwitchCase && _level >= 2)
    ? position.value.track2.primaryBranch
    : position.value.track2.primaryBranch + getSizeElement(tw.type)
}

function getSecondaryBranchSecondTrack(position: BehaviorSubject<TrackBehavior>, tw: TrafficWindow): number {
  return !position.value.track2.switchCase && !position.value.track2.startingSwitchCase ? position.value.track2.primaryBranch + getSizeElement(tw.type) : tw.level === -1 ? position.value.track2.secondaryBranch + getSizeElement(tw.type) : position.value.track2.secondaryBranch
}

function getPrimaryBranchTriplePlatformFirstTrack(position: BehaviorSubject<TrackBehavior>, _level: number, tw: TrafficWindow) {
  return !position.value.track1.triplePlatformSwitchCase
    ? position.value.track1.primaryBranch + getSizeElement(tw.type)
    : position.value.track1.triplePlatformSwitchCase && _level === 6
    ? position.value.track1.primaryBranchTriplePlatform + getSizeElement(tw.type)
    : position.value.track1.primaryBranchTriplePlatform
}
