export const environment = {
  authenticationApiBaseUrl: 'https://livetrafic.ratp.io',
  trafficMobileApiBaseUrl: 'https://livetrafic.ratp.io',
  notificationApiBaseUrl: 'https://livetrafic.ratp.io',
  production: true,
  showPayload: false,
  stompDebug: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBisa_cy3LJFLiGJUEH9ZD3foM_-tM0Nok',
    authDomain: 'tm-project-9349e.firebaseapp.com',
    databaseURL: 'https://tm-project-9349e.firebaseio.com',
    projectId: 'tm-project-9349e',
    storageBucket: 'tm-project-9349e.appspot.com',
    messagingSenderId: '429460529798',
    appId: '1:429460529798:web:dc263850ee07da400ee707',
    measurementId: 'G-EBEW4NE5PV',
  },
  trafficSituationLiveIVBaseUrl: 'https://live-iv.ratp.fr/',
  liveEventsBaseUrl : 'https://livevents.ratp.io'
}
