import { ColorPhaser } from '../modeles/color'
import TextStyle = Phaser.Types.GameObjects.Text.TextStyle

export const TRAIN_NUMBER_TEXT_SIZE = 15
export const TIME_WINDOW_NUMBER_TEXT_SIZE = 16
export const PLATFORM_TEXT_SIZE = 16
export const TRAIN_NUMBER_TEXT_FONT = 'ROBOTO_BOLD_72'
export const TIME_WINDOW_NUMBER_TEXT_FONT = 'ROBOTO_REGULAR_32'
export const PLATFORM_NUMBER_TEXT_FONT = 'ROBOTO_MEDIUM_32'

export const TRAIN_DELAY_TEXT_SIZE = 11
export const PLATFORM_INFORMATION: TextStyle = {
  color: ColorPhaser.STATION_NAME_HEX.toString(),
  fontStyle: 'bold',
  fontSize: '13px',
  fontFamily: 'Arial',
  metrics: {
    ascent: 13,
    descent: 3,
    fontSize: 16,
  },
}

export const START_INFORMATION: TextStyle = {
  fontSize: '14px',
  fontFamily: 'Arial',
  metrics: {
    ascent: 13,
    descent: 3,
    fontSize: 16,
  },
}
