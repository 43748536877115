import { Component, Input } from '@angular/core'
import { TkOffPeakHourConfig } from '../../core/models/tk-off-peak-hour-config'

@Component({
  selector: 'app-tk-off-peak-hour-schedule',
  templateUrl: './tk-off-peak-hour-schedule.component.html',
  styleUrls: ['./tk-off-peak-hour-schedule.component.scss'],
})
export class TkOffPeakHourScheduleComponent {
  @Input() schedules: Array<TkOffPeakHourConfig>
  @Input() periodType: string

  constructor() {}

  displayOffPeakHourTimeSlots() {
    return this.schedules.map((value) => value.start + ' - ' + value.end).join(' / ')
  }
}
