import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { PlatformService } from '../../core/services/platform.service'
import { BehaviorSubject, Observable } from 'rxjs'
import { TkProductionType } from '../tk-switcher-button/tk-switcher-button.component'
import { Tk, TkLine } from '../../core/models/tk'
import { ApiTkService } from '../../core/services/sockets/api-tk.service'
import { filter, map } from 'rxjs/operators'
import { PeakPeriodConfig } from '../../core/models/peak-periodConfig'
import { TkOffPeakHourConfig } from '../../core/models/tk-off-peak-hour-config'

@Component({
  selector: 'app-tk-off-peak-hour-display',
  templateUrl: './tk-off-peak-hour-display.component.html',
  styleUrls: ['./tk-off-peak-hour-display.component.scss'],
})
export class TkOffPeakHourDisplayComponent implements OnInit, OnDestroy {
  @Input() peakPeriodConfig: PeakPeriodConfig
  @Input() tkOffPeakHourConfig: Array<TkOffPeakHourConfig>
  tkOffPeakHour$: Observable<Tk>
  tkProductionSubject: BehaviorSubject<TkProductionType> = new BehaviorSubject<TkProductionType>('LAP')

  constructor(public platformService: PlatformService, private apiTkService: ApiTkService) {
    this.tkOffPeakHour$ = apiTkService.state$.pipe(
      filter<Tk>(Boolean),
      filter((e) => !!e.lines),
      map((value) => {
        value.lines = value.lines.sort((acc: TkLine, tkLine: TkLine) =>
          acc.lineName.localeCompare(tkLine.lineName, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        )
        return value
      })
    )
  }

  ngOnInit(): void {
    this.apiTkService.getTkStreamMessages()
  }

  ngOnDestroy(): void {
    this.apiTkService.unSubscriptionTkStreamMessages()
  }
}
