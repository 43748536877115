<div class="station-information-card">
  <ion-button (click)="display.next(null)" class="close-button">
    <ion-icon name="close-outline" slot="icon-only"></ion-icon>
  </ion-button>
  <ion-grid *ngIf="stationInformation">
    <ion-row class="ion-justify-content-between ion-align-items-center">
      <ion-col class="ion-align-self-center" [size]="sizes.title">
        <ion-row class="ion-justify-content-start ion-align-items-center row-station-information-with-icon">
          <span *ngIf="stationInformation.platformState === 'CLOSED'" class="closed">{{ 'app.shared.station.information.closed' | translate }}</span>
          <span class="station-title">{{ stationInformation?.platformName }}</span>
          <div class="wrapper-management">
            <ion-icon *ngIf="!!stationInformation?.management" class="small-size-icon" src="./assets/icon/lines/{{ stationInformation.management }}.svg"></ion-icon>
            <ion-icon *ngIf="!!stationInformation?.management" class="small-size-icon king" src="./assets/icon/station-information/king.svg"></ion-icon>
          </div>
          <ion-icon *ngIf="!!stationInformation?.ciClub" class="xsmall-size-icon" src="./assets/icon/station-information/ci-club.svg"></ion-icon>
          <ion-icon *ngIf="!!stationInformation?.referentialStation" class="medium-size-icon" src="./assets/svgs/platform/stopwatch.svg"></ion-icon>
        </ion-row>
      </ion-col>
      <ion-col *ngIf="eventVisible" size="1"></ion-col>
      <ion-col *ngIf="eventVisible" class="live-event-container" size="5">
        <ion-icon class="small-size-icon" src="./assets/icon/lines/{{ displayLineNameValue }}.svg"></ion-icon>
        <div>
          <ion-text *ngIf="agentEvent || agentAndIncidentEvent">{{ numberOfAgents }} {{'app.shared.event.detail.agent' | translate}} </ion-text>
          <ion-text *ngIf="agentAndIncidentEvent">{{'app.shared.event.detail.and' | translate}} </ion-text>
          <ion-text *ngIf="incidentEvent || agentAndIncidentEvent">{{ numberOfIncidents }} {{'app.shared.event.detail.incident' | translate}}</ion-text>
        </div>
      </ion-col>
      <ion-col *ngIf="eventVisible" size="1"></ion-col>
    </ion-row>
    <ion-row>
      <ion-col *ngIf="stationInformation?.connections && stationInformation?.connections?.length > 0; else noConnexion" [size]="sizes.connexionsTrackContainer">
        <ion-row class="no-padding correspondence-light-gap" [class.correspondence]="platformService.isPlatformDesktop()">
          <ion-col class="ion-no-padding ion-align-self-center" size="12">
            <div class="content-title">
              {{ 'app.shared.station.information.lines' | translate }}
            </div>
          </ion-col>

          <ion-col class="ion-no-padding ion-align-self-center" size="12">
            <ion-icon *ngFor="let connection of stationInformation?.connections" class="medium-size-icon" src="./assets/icon/lines/{{ connection.name }}.svg"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col [class]="leftArrowClass" *ngIf="eventVisible" size="1" (click)="onClickLeftArrow()"></ion-col>
      <ion-col size="5" *ngIf="eventVisible" class="event-container">
        <div *ngIf="!activeIncidentEvent">
          <ion-text>{{ 'app.shared.event.detail.team' | translate }} : <span>{{ activeAgent?.team }}</span></ion-text>
          <br>
          <ion-text>{{ 'app.shared.event.detail.tetraNumber' | translate }} : <span>{{ activeAgent?.tetraNumber }}</span></ion-text>
          <br>
          <ion-text>{{ 'app.shared.event.detail.arrivalDate' | translate }} : <span>{{ activeAgent?.arrivalDate }}</span></ion-text>
        </div>
        <div *ngIf="activeIncidentEvent">
          <ion-text>{{ activeIncident?.typeLabel }}</ion-text>
          <br>
          <ion-text>{{ 'app.shared.event.detail.rangeDate' | translate }} : <span>{{ activeIncident?.startTime }}</span></ion-text>
        </div>
      </ion-col>
      <ion-col [class]="rightArrowClass" *ngIf="eventVisible" size="1" (click)="onClickRightArrow()"></ion-col>
      <a *ngIf="eventVisible && activeIncidentEvent" target="_blank" href="{{ redirectToLiveEvent() }}">
        <ion-icon src="./assets/svgs/live-events/live_event_logo.svg"></ion-icon>
        <span>+ d'info</span>
      </a>
    </ion-row>
  </ion-grid>
</div>

<ng-template #noConnexion>
  <ion-col class="no-connexion-col" [size]="sizes.noConnexion">
    <ion-row>
      <ion-icon src="./assets/icon/station-information/no-connection.svg"></ion-icon>
    </ion-row>
    <ion-row>
      {{ 'app.shared.station.information.no.connection' | translate }}
    </ion-row>
  </ion-col>
</ng-template>
