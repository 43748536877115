<ion-header>
  <ion-grid>
    <ion-row [class.ion-justify-content-center]="platformService.isPlatformDesktop()">
      <div [class.wrapper-header]="platformService.isPlatformDesktop()" class="wrapper-header-mobile">
        <ion-toolbar>
          <ion-menu-button id="burger-menu-button" slot="start"></ion-menu-button>
          <ion-icon [slot]="'end'" class="calendar" src="./assets/icon/calendar.svg"></ion-icon>
          <div class="date" slot="end">
            {{ date | date: 'EE d/MM' | titlecase }}
          </div>
          <div class="time" slot="end">{{ date | date: 'HH:mm' }}</div>
          <ion-icon [slot]="'end'" src="./assets/icon/ratp-logo.svg"></ion-icon>
        </ion-toolbar>
      </div>
    </ion-row>
  </ion-grid>
</ion-header>
