<ion-grid>
  <ion-row [class.no-padding]="isPlatformMobile()">
    <ion-col [class.no-padding]="isPlatformMobile()">
      <ion-row [class.no-padding]="isPlatformMobile()">
        <app-peak-period-global [peakPeriodGlobal]="peakPeriodFromPeriod$ | async"></app-peak-period-global>
      </ion-row>
      <ion-row *ngIf="peakPeriodDetail$ | async as peakPeriodDetail" [class.no-padding]="isPlatformMobile()">
        <app-peak-period-detail [peakPeriodDetailLines]="peakPeriodDetail"></app-peak-period-detail>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
