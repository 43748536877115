import { Component, Input } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { AttendanceLevel, AttendanceLevelType } from 'src/phaser/modeles/trains'
import { ContainerTrainElement } from '../../../phaser/game-objects/groups/train/train.element'

@Component({
  selector: 'app-train-information',
  templateUrl: './train-information.component.html',
  styleUrls: ['./train-information.component.scss'],
})
export class TrainInformationComponent {
  delayOnDayClass = 'no-delay'
  delayOnCourseClass = 'no-delay'
  attendanceLevelClass = ''

  private _trainInformation: ContainerTrainElement

  @Input() display: BehaviorSubject<string>

  get trainInformation(): ContainerTrainElement {
    return this._trainInformation
  }

  @Input()
  set trainInformation(value: ContainerTrainElement) {
    this._trainInformation = value
    const delayOnDayOnDay = this._trainInformation.containerDelayDisplayElement.trainDelayText.trainDelayOnDay
    const delayOnCourse = this._trainInformation.containerDelayDisplayElement.trainDelayText.trainDelayOnCourse
    const attendanceLevel = this._trainInformation.train.attendanceLevel

    if (delayOnDayOnDay > 0) {
      this.delayOnDayClass = 'delay-on-day'
    } else if (delayOnDayOnDay < 0) {
      this.delayOnDayClass = 'delay-early'
    } else {
      this.delayOnDayClass = 'no-delay'
    }
    if (delayOnCourse > 0) {
      this.delayOnCourseClass = 'delay-on-course'
    } else if (delayOnCourse < 0) {
      this.delayOnCourseClass = 'delay-on-course'
    } else {
      this.delayOnCourseClass = 'no-delay'
    }

    this.generateCorrectAttendanceLevelCssClass(attendanceLevel)
  }

  private generateCorrectAttendanceLevelCssClass(attendanceLevel: AttendanceLevelType) {
    switch (attendanceLevel) {
      case AttendanceLevel.LOW:
      case AttendanceLevel.MEDIUM:
        this.attendanceLevelClass = 'low-medium-attendance'
        break
      case AttendanceLevel.HIGH:
      case AttendanceLevel.VERY_HIGH:
        this.attendanceLevelClass = 'high-attendance'
        break
      case AttendanceLevel.FULL:
        this.attendanceLevelClass = 'full-attendance'
        break
      default:
        this.attendanceLevelClass = ''
    }
  }

  get attendanceLevelCSS(): string {
    this.generateCorrectAttendanceLevelCssClass(this._trainInformation.train.attendanceLevel)
    return this.attendanceLevelClass
  }

  get attendanceLevel(): AttendanceLevelType {
    return this._trainInformation.train.attendanceLevel
  }
}
