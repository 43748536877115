import { Component, EventEmitter, OnDestroy, Output } from '@angular/core'
import { PlatformService } from '../../core/services/platform.service'
import { BehaviorSubject, Subject } from 'rxjs'

export type TkProductionType = 'KILOMETER' | 'LAP'

@Component({
  selector: 'app-tk-switcher-button',
  templateUrl: './tk-switcher-button.component.html',
  styleUrls: ['./tk-switcher-button.component.scss'],
})
export class TkSwitcherButtonComponent implements OnDestroy {
  @Output() tkProductionSelected: EventEmitter<TkProductionType> = new EventEmitter<TkProductionType>()
  tkProductionSubject: BehaviorSubject<TkProductionType> = new BehaviorSubject<TkProductionType>('LAP')
  destroy: Subject<boolean> = new Subject<boolean>()

  constructor(public platformService: PlatformService) {}

  selectTkProductionType(type: TkProductionType) {
    this.tkProductionSubject.next(type)
    this.tkProductionSelected.emit(type)
  }

  ngOnDestroy(): void {
    this.destroy.next(false)
    this.destroy.unsubscribe()
  }
}
