import { Component, Input } from '@angular/core'
import { GlobalStatistics, MetroStatistics, PeakPeriodGlobal, RerStatistics } from '../../core/models/peak-period'
import { PlatformService } from '../../core/services/platform.service'

@Component({
  selector: 'app-peak-period-global',
  templateUrl: './peak-period-global.component.html',
  styleUrls: ['./peak-period-global.component.scss'],
})
export class PeakPeriodGlobalComponent {
  @Input() peakPeriodGlobal: PeakPeriodGlobal
  objectKeys = Object.keys

  readonly emptyPeakPeriodGlobal = new PeakPeriodGlobal(new MetroStatistics(0, 0, 0, ''), new RerStatistics(0, 0, 0, ''), new GlobalStatistics(0, 0, 0, ''))

  constructor(public platformService: PlatformService) {}
}
