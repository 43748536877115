<div class="station-information-card">
  <ion-button (click)="display.next(null)" class="close-button">
    <ion-icon name="close-outline" slot="icon-only"></ion-icon>
  </ion-button>
  <ion-grid *ngIf="trainInformation">
    <ion-row class="ion-justify-content-between">
      <ion-col class="ion-align-self-center" size="11">
        <ion-row class="ion-justify-content-start ion-align-items-center row-station-information-with-icon row-gap">
          <span class="train-title">{{ 'app.shared.train.information.train' | translate }} {{ trainInformation.id }}</span>
          <app-information-tag *ngIf="trainInformation.train.transportStatusType === 'NO_PASSENGER'" [class]="trainInformation.train.transportStatusType | lowercase" [message]="'app.shared.information.tag.message.no.passager'"> </app-information-tag>
          <app-information-tag *ngIf="trainInformation.train.transportStatusType === 'UNIDENTIFIED'" [class]="trainInformation.train.transportStatusType | lowercase" [message]="'app.shared.information.tag.message.unidentified'"> </app-information-tag>
          <span *ngIf="!!trainInformation.train.terminusColor">
            <ion-icon name="arrow-forward-outline" slot="icon-only" class="icon" [ngClass]="trainInformation.train.terminusColor === 'BLUE' ? 'icon-blue' : 'icon-yellow'"> </ion-icon>
          </span>
          <span *ngIf="!!trainInformation.train.destination" class="train-destination">
            {{ trainInformation.train.destination | truncate: [13, '.'] }}
          </span>
          <span *ngIf="!!trainInformation.train.overParkingInfo.overParked" class="over-parking">
            {{ 'app.shared.station.information.over.parking' | translate }}
          </span>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="information-row">
      <!-- SERVICE -->
      <ion-col class="info-line-content-col info-line-content" size="2">
        <ion-row>
          <ion-text class="info-line-col-title">
            {{ 'app.shared.train.information.shift' | translate }}
          </ion-text>
        </ion-row>

        <ion-row class="info-train-row">
          <div class="info-line-detail">
            {{ [trainInformation.train.shiftInfo?.number, trainInformation.train.shiftInfo?.terminusSuffix] | empty: '-' }}
          </div>
        </ion-row>
      </ion-col>
      <!-- MATRICULE -->
      <ion-col class="info-line-content-col info-line-content info-train-border" size="3">
        <ion-row>
          <ion-text class="info-line-col-title">
            {{ trainInformation.train.shiftInfo?.operatorPersonalNumber | empty: 'Matricule' }}
            {{ 'app.shared.train.information.theoretical' | translate }}
          </ion-text>
        </ion-row>

        <ion-row class="info-train-row">
          <div class="info-line-detail">
            {{ trainInformation.train.shiftInfo?.operatorLastName | titlecase | empty: '-' | truncate: [12] }}
          </div>
        </ion-row>
      </ion-col>
      <!-- RETARD PE -->
      <ion-col class="info-line-content-col info-line-content info-train-border" size="2.5">
        <ion-row>
          <ion-text class="info-line-col-title">
            {{ 'app.shared.train.information.delay.on.day' | translate }}
          </ion-text>
        </ion-row>
        <ion-row class="info-train-row">
          <div [ngClass]="delayOnDayClass" class="info-line-detail">
            {{ trainInformation.containerDelayDisplayElement.trainDelayText.trainDelayOnDay | abs }}
            ’
          </div>
        </ion-row>
      </ion-col>
      <!-- RETARD COURSE -->
      <ion-col class="info-line-content-col info-line-content info-train-border" size="2">
        <ion-row>
          <ion-text class="info-line-col-title">
            {{ 'app.shared.train.information.delay.on.course' | translate }}
          </ion-text>
        </ion-row>
        <ion-row class="info-train-row">
          <div [ngClass]="delayOnCourseClass" class="info-line-detail">
            {{ trainInformation.containerDelayDisplayElement.trainDelayText.trainDelayOnCourse | abs }}
            ’
          </div>
        </ion-row>
      </ion-col>
      <!-- AFFLUENCE -->
      <ion-col class="info-line-content-col info-line-content info-train-border" size="2">
        <ion-row>
          <ion-text class="info-line-col-title">
            {{ 'app.shared.train.information.attendance.title' | translate }}
          </ion-text>
        </ion-row>
        <ion-row class="info-train-row">
          <div [ngClass]="attendanceLevelCSS" class="info-line-detail low-font-size">
            {{ 'app.shared.train.information.attendance.on.' + attendanceLevel | empty: '-' | translate }}
          </div>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
