import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { filter, map, shareReplay } from 'rxjs/operators'
import { ApiLinesSliderService } from '../../core/services/api-line-slider.service'
import { TranslateService } from '@ngx-translate/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-line-selector',
  templateUrl: './line-selector.component.html',
  styleUrls: ['./line-selector.component.scss'],
})
export class LineSelectorComponent implements OnDestroy {
  @Input()
  set selectedLines(value: string[]) {
    this.ionSelect.setValue(value.sort(this.sortAlphaNum()), {
      emitEvent: false,
    })
  }

  @Output()
  newSelectedLines = new EventEmitter<string[]>()

  ionSelect = new FormControl()

  destroy: Subject<boolean> = new Subject<boolean>()
  allLines$: Observable<string[]>

  constructor(private apiLineSliderService: ApiLinesSliderService, public translate: TranslateService) {
    this.allLines$ = this.apiLineSliderService.state$.pipe(
      shareReplay(1),
      filter((e) => !!e),
      map((referentialLines) => referentialLines.lines.map((line) => line.name))
    )

    this.ionSelect.valueChanges.subscribe((value) => {
      this.newSelectedLines.emit(value)
    })
  }

  ngOnDestroy(): void {
    this.destroy.next(true)
    this.destroy.complete()
  }

  sortAlphaNum() {
    return (first, second) => {
      const aA = first.replace(/[a-zA-Z]+/g, '')
      const bA = second.replace(/[a-zA-Z]+/g, '')
      const firstNumber = Number(aA)
      const secondNumber = Number(bA)
      if (firstNumber === 0) {
        return 1
      }
      return firstNumber < secondNumber ? -1 : 1
    }
  }
}
