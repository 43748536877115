import { Component, Input } from '@angular/core'
import { StatisticsInterval } from '../../core/models/interval'
import { PlatformService } from '../../core/services/platform.service'

@Component({
  selector: 'app-interval-cartridge',
  templateUrl: './interval-cartridge.component.html',
  styleUrls: ['./interval-cartridge.component.scss'],
})
export class IntervalCartridgeComponent {
  @Input() intervalStatistics: StatisticsInterval
  @Input() intervalKey: string

  constructor(public platformService: PlatformService) {}
}
