import { Component } from '@angular/core'
import { ActionSheetController } from '@ionic/angular'
import { UserPreferences, userPreferences } from '../../core/configurations/user/user.preferences'
import { PlatformService } from '../../core/services/platform.service'
import { AnalyticsService } from '../../core/services/analytics.service'

@Component({
  selector: 'app-affluence-switch',
  templateUrl: './affluence-switch.component.html',
  styleUrls: ['./affluence-switch.component.scss'],
})
export class AffluenceSwitchComponent {
  trainAffluenceSelected = false

  constructor(public actionSheetCtrl: ActionSheetController, public platformService: PlatformService, private analyticsService: AnalyticsService) {}

  private _userPreferences = userPreferences

  get userPreferences(): UserPreferences {
    return this._userPreferences
  }

  async switchDisplayAffluence() {
    this.trainAffluenceSelected = !this.trainAffluenceSelected
    userPreferences.selectedTrainAffluence = this.trainAffluenceSelected
    this.analyticsService.pushLogEvent({
      eventName: 'button_train_affluence',
      eventParameter: {
        train_affluence_selection: this.trainAffluenceSelected,
      },
    })
  }
}
