import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { Router } from '@angular/router'
import { Device } from '@capacitor/device'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { AlertController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs/internal/Observable'
import { take, flatMap } from 'rxjs/operators'
import { LocalStorageService } from 'src/app/core/local-storage.service'
import { AuthenticationInformation } from 'src/app/core/models/authentication-information'
import { UserService } from 'src/app/core/security/user.service'
import { AnalyticsService } from 'src/app/core/services/analytics.service'
import { PlatformService } from 'src/app/core/services/platform.service'
import { UrlService } from 'src/app/core/services/url.service'
import { appProperties } from 'src/environments/app.properties'
import { paths } from 'src/environments/paths'
import { ApiNotificationService } from '../../core/services/notifications/api-notification.service';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
})
export class LogoutButtonComponent implements OnInit, OnDestroy {
  user$: Observable<AuthenticationInformation>
  messageListener: () => void

  constructor(
    private localStorageService: LocalStorageService,
    public alertController: AlertController,
    public translate: TranslateService,
    public platformService: PlatformService,
    private inAppBrowser: InAppBrowser,
    private router: Router,
    private renderer: Renderer2,
    private apiNotificationServiceService: ApiNotificationService,
    public urlService: UrlService,
    public userService: UserService,
    private analyticsService: AnalyticsService
  ) {
    this.user$ = this.userService.currentUserState$
  }

  ngOnDestroy(): void {
    this.messageListener()
  }

  ngOnInit(): void {
    const state = new URLSearchParams(document.location.search).get('state');
    if (state ) {
      this.getListenerMessage({data: state})
    }

  }

  async logout() {
    const alert = await this.alertController.create({
      cssClass: 'logout-alert',
      header: this.translate.instant('app.profile.alert.title'),
      message: this.translate.instant('app.profile.alert.message'),
      buttons: [
        {
          text: this.translate.instant('app.profile.alert.dismiss'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: this.translate.instant('app.profile.alert.validation'),
          handler: () => {
            this.localStorageService.getItem(appProperties.token).then((jwtToken) => this.hiddenLogout(jwtToken.value))
          },
        },
      ],
    })
    await alert.present()
  }

  /**
   * set src of iframe to make logout call with token
   */
  hiddenLogout(token: string) {
    if (this.platformService.isPlatformNative()) {
      const inAppBrowserObject = this.inAppBrowser.create(`${paths.samlLogout}?Authorization=${token}`  , '_blank', 'location=no')
      inAppBrowserObject.on('loadstop').subscribe((postMessage) => {
       if (postMessage.url.match("logout")) {
          this.getListenerMessage({ data: appProperties.logout }).then(() => inAppBrowserObject.close())
        }
      })
    } else {
      window.location.replace(`${paths.samlLogout}?Authorization=${token}&redirectTo=` + paths.logout)
    }
  }

  /**
   * ask for new authentication
   */
  async getNewAuthentication(): Promise<boolean> {
    return this.router.navigate([this.urlService.liveTrafficUrls.URL_LOGIN])
  }

  private getListenerMessage = async (message) => {
    if (message.data === appProperties.logout) {
      await this.removeDeviceToNotificationUserAccount()
      await this.removeToken()
      await this.analyticsService.pushLogoutEvent()
      return this.getNewAuthentication()
    }
  }

  private async removeDeviceToNotificationUserAccount() {
    const device = await Device.getId()

    this.user$
      .pipe(
        take(1),
        flatMap((user) => this.apiNotificationServiceService.removeDeviceFromCurrentUser(device.identifier)
            )
      )
      .subscribe()
  }

  private removeToken(): Promise<void> {
    return this.localStorageService.removeItem(appProperties.token)
  }
}
