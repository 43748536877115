import { Component, ElementRef, OnInit } from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import { ApiLinesSliderService } from '../../core/services/api-line-slider.service'
import { ReferentialLine, ReferentialLines } from '../../core/models/lines'
import {filter, flatMap, tap} from 'rxjs/operators'
import { ApiIntervalService } from '../../core/services/sockets/api-interval.service'
import { Interval, IntervalLine } from '../../core/models/interval'
import { IntervalConfig } from '../../core/models/interval-config'
import { ApiIntervalConfigService } from '../../core/services/api-interval-config.service'
import { PlatformService } from '../../core/services/platform.service'
import { userPreferences } from '../../core/configurations/user/user.preferences'
import { SelectedPlatformInfo } from '../../core/configurations/user/selected.platform.info'
import { LineSituations, LineTrafficSituations, TrafficSituation } from '../../core/models/trafic-situation'
import { ApiDisruptionTrafficSituationService } from '../../core/services/sockets/api-disruption-traffic-situation.service'

@Component({
  selector: 'app-lines-slider',
  templateUrl: './lines-slider.component.html',
  styleUrls: ['./lines-slider.component.scss'],
})
export class LinesSliderComponent implements OnInit {
  referentialLines$: Observable<ReferentialLines>
  currentLine: BehaviorSubject<ReferentialLine>
  stateNetworkLine: [] = []

  disruptionsAllLines$: Observable<LineSituations>
  stateTrafficInfoLine: any[] = []
  disruptionLines = []
  referentialLines: ReferentialLine[]

  constructor(
    private linesSliderService: ApiLinesSliderService,
    private apiIntervalConfigService: ApiIntervalConfigService,
    private apiDisruptionTrafficSituationService: ApiDisruptionTrafficSituationService,
    public platformService: PlatformService,
    private apiIntervalService: ApiIntervalService,
    public elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.referentialLines$ = this.linesSliderService.state$
    this.currentLine = this.linesSliderService.currentLine

    this.disruptionsAllLines$ = this.apiDisruptionTrafficSituationService.disruptionsAllLineObservable

    this.apiIntervalService.getStreamMessages()
    this.apiDisruptionTrafficSituationService.getStreamMessagesAllLine()

    this.referentialLines$.subscribe(referentialLines => {
      if (!!referentialLines) {
        this.referentialLines = referentialLines.lines
      }
    })

    this.apiIntervalConfigService.intervalConfig$
      .pipe(
        flatMap((config) =>
          this.apiIntervalService.stateIntervals$.pipe(
            filter((res) => !!res),
            tap((intervals: Interval) =>
              intervals.lines.forEach((interval) => {
                if (!!config) {
                  this.stateNetworkLine[interval.lineName] = this.calculationOfState(interval, config)
                }
              })
            )
          )
        )
      )
      .subscribe()

    this.apiDisruptionTrafficSituationService.disruptionsAllLineObservable
        .pipe(
            filter((res) => !!res),
            tap((lineTrafficSituations: LineSituations) => {
              this.disruptionLines = []
              if (Object.entries(lineTrafficSituations.lineSituations).length > 0) {
                for (const [key, lineTrafficSituation] of Object.entries(lineTrafficSituations.lineSituations)) {
                  this.disruptionLines.push(key)
                  const trafficSituations = this.sortByDateForTrafficSituation(lineTrafficSituation)
                  if (trafficSituations.length > 0) {
                    const trafficSituation = trafficSituations[0]
                    if (trafficSituation.classificationId !== 'works' && trafficSituation.conditionId === 'ServiceCondition:noService') {
                      this.stateTrafficInfoLine[lineTrafficSituation.line] = 'close'
                    } else if (trafficSituation.classificationId === 'publicEvents' && trafficSituation.conditionId !== 'ServiceCondition:noService') {
                      this.stateTrafficInfoLine[lineTrafficSituation.line] = 'manif'
                    } else if (trafficSituation.classificationId === 'works') {
                      this.stateTrafficInfoLine[lineTrafficSituation.line] = 'works'
                    } else if (trafficSituation.classificationId !== 'works' && trafficSituation.classificationId !== 'publicEvents' && trafficSituation.conditionId !== 'ServiceCondition:noService') {
                      this.stateTrafficInfoLine[lineTrafficSituation.line] = 'error'
                    } else {
                      this.stateTrafficInfoLine[lineTrafficSituation.line] = ''
                    }
                  }
                }
              }
            })
        )
        .subscribe( () => {
          if (!!this.referentialLines) {
            this.referentialLines.forEach(line => {
              if (!this.disruptionLines.includes(line.name)) {
                this.stateTrafficInfoLine[line.name] = ''
              }
            })
          }
        })



  }

  private sortByDateForTrafficSituation(lineTrafficSituations: LineTrafficSituations): TrafficSituation[] {
    if (!!lineTrafficSituations) {
      let trafficSituationSorted: TrafficSituation[]
      trafficSituationSorted = [...lineTrafficSituations.events]
      trafficSituationSorted.sort((a, b) => {
        return new Date(b.changed).getTime() - new Date(a.changed).getTime()
      })
      return trafficSituationSorted
    }
  }

  calculationOfState(interval: IntervalLine, intervalConfig: IntervalConfig) {
    switch (true) {
      case interval.sumPercentageInterval * 100 < intervalConfig.thresholds.alert:
        return 'alert'
      case interval.sumPercentageInterval * 100 <= intervalConfig.thresholds.warning:
        return 'warning'
      case interval.sumPercentageInterval * 100 > intervalConfig.thresholds.warning:
        return 'clear'
      case interval.sumPercentageInterval === 0:
        return 'interrupted'
      default:
        return ''
    }
  }

  selectCurrentLine(line: ReferentialLine): void {
    this.linesSliderService.currentLine.next(line)
    if (line.type === 'RER') {
      userPreferences.selectedPlatformInfo = SelectedPlatformInfo.WAITING
    }
  }
}
