<ion-app>
    <div *ngIf="(this.userService.isAUserAlreadyConnected$ | async)"
        [hidden]="fullscreenState.getValue() !== 'NORMAL'">
        <app-menu-side appWindowListener></app-menu-side>
        <app-menu-top [hidden]="router.url === urlService.liveTrafficUrls.URL_LOGIN || (titleService.state$ | async) === 'FULLSCREEN'"></app-menu-top>
    </div>
    <ion-content>
        <ion-router-outlet id="content" [swipeGesture]="false"></ion-router-outlet>
    </ion-content>
</ion-app>
