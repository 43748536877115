import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { paths } from '../../../environments/paths'
import { UserProfile } from '../models/user-profile'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ApiUserProfileService {
  constructor(private httpClient: HttpClient) {}

  getUserProfile(): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(`${paths.userProfile}`)
  }
  saveUserProfile(userProfile: UserProfile): Observable<UserProfile> {
    return this.httpClient.post<UserProfile>(paths.userProfile, userProfile)
  }
}
