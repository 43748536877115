<ion-grid [class.no-padding]="platformService.isPlatformMobile()">
  <ion-row *ngIf="!!tkStatistics; else emptyTkStatistics" [class.no-padding]="platformService.isPlatformMobile()">
    <ion-col class="tk-statistics-cartridge" size-lg="6" size-sm="12">
      <app-tk-statistics-cartridge [tkStatistics]="tkStatistics" [peakPeriodConfig]="peakPeriodConfig" [tkProductionSubject]="tkProductionSubject" [productionInvalid]="productionInvalid"> </app-tk-statistics-cartridge>
    </ion-col>
  </ion-row>
</ion-grid>
<ng-template #emptyTkStatistics>
  <ion-row>
    <ion-col size="6">
      <app-tk-statistics-cartridge [tkStatistics]="getEmptyTkStatistics()" [peakPeriodConfig]="peakPeriodConfig" [tkProductionSubject]="tkProductionSubject" [productionInvalid]="productionInvalid"> </app-tk-statistics-cartridge>
    </ion-col>
  </ion-row>
</ng-template>
