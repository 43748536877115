import { Component, ElementRef, Input } from '@angular/core'
import { IntervalLine } from 'src/app/core/models/interval'
import { PeakPeriodLineRaw } from 'src/app/core/models/peak-period'
import { PeakPeriodConfig } from '../../../core/models/peak-periodConfig'
import { ReferentialLine } from '../../../core/models/lines'
import { BehaviorSubject, Observable, of, UnaryFunction } from 'rxjs'
import { filter, flatMap, map, shareReplay, tap } from 'rxjs/operators'
import { IntervalConfig } from '../../../core/models/interval-config'
import { InfoLineDisruption } from '../../../core/models/disruptions'
import { UrlService } from '../../../core/services/url.service'
import { PlatformService } from '../../../core/services/platform.service'
import { UserService } from '../../../core/security/user.service'
import { TkLine } from '../../../core/models/tk'
import { DatePipe } from '@angular/common'
import { generatePerturbationMessage } from '../../../shared/messageTools'

export function setPeakPeriodSplitterTime(referentialLine: ReferentialLine): UnaryFunction<
  { peakPeriodLineRaw; peakPeriodConfig },
  {
    percentage: number
    peakPeriodColor: string
    theorical: number
    real: number
  }
> {
  return ({ peakPeriodLineRaw, peakPeriodConfig }): any => {
    if (!!!referentialLine || !!!peakPeriodLineRaw || !!!peakPeriodConfig) {
      return null
    }
    const peakPeriodSplitter = new Date()
    const [hourPeakPeriodMetro, minutePeakPeriodMetro] = peakPeriodConfig.schedules.metro.peakPeriodEvening.start.split('h')
    const [hourPeakPeriodRer, minutePeakPeriodRer] = peakPeriodConfig.schedules.metro.peakPeriodEvening.start.split('h')
    referentialLine.type === 'METRO' ? peakPeriodSplitter.setHours(Number(hourPeakPeriodMetro), Number(minutePeakPeriodMetro)) : peakPeriodSplitter.setHours(Number(hourPeakPeriodRer), Number(minutePeakPeriodRer))
    const periodOfTheDay = Date.now() > peakPeriodSplitter.getTime() ? 'EVENING' : 'MORNING'
    return periodOfTheDay === 'EVENING'
      ? {
          percentage: peakPeriodLineRaw.eveningPercentage,
          peakPeriodColor: getLevelPeakPeriod(peakPeriodLineRaw.eveningPercentage, peakPeriodConfig),
          theorical: peakPeriodLineRaw.eveningTheoretical,
          real: peakPeriodLineRaw.eveningReal,
        }
      : {
          percentage: peakPeriodLineRaw.morningPercentage,
          peakPeriodColor: getLevelPeakPeriod(peakPeriodLineRaw.morningPercentage, peakPeriodConfig),
          theorical: peakPeriodLineRaw.morningTheoretical,
          real: peakPeriodLineRaw.morningReal,
        }
  }
}

export function getLevelPeakPeriod(percentage: number, peakPeriodConfig: PeakPeriodConfig): string {
  if (!percentage){
    return ''
  }
  switch (true) {
    case percentage * 100 < peakPeriodConfig.thresholds.alert:
      return 'red peak-period'
    case percentage * 100 <= peakPeriodConfig.thresholds.warning:
      return 'yellow peak-period'
    case percentage * 100 > peakPeriodConfig.thresholds.warning:
      return 'green peak-period'
    default:
      return ''
  }
}

export function calcIntervalColorLevel(intervalConfig: IntervalConfig, sumPercentage: number): string {
  switch (true) {
    case sumPercentage * 100 < intervalConfig.thresholds.alert:
      return 'orange'
    case sumPercentage * 100 <= intervalConfig.thresholds.warning:
      return 'yellow'
    case sumPercentage * 100 > intervalConfig.thresholds.warning:
      return 'green'
    case sumPercentage * 100 === 0:
      return 'red'
    default:
      return ''
  }
}

@Component({
  selector: 'app-info-line',
  templateUrl: './info-line.component.html',
  styleUrls: ['./info-line.component.scss'],
})
export class InfoLineComponent {
  hiddenContent = false
  intervalByLineSubject = new BehaviorSubject<IntervalLine>(null)
  peakPeriodRawWithLevelSubject = new Observable<{
    percentage: number
    peakPeriodColor: string
    theorical: number
    real: number
  }>()
  time: string
  intervalLevel: Observable<string>
  private referentialLineSubject = new BehaviorSubject<ReferentialLine>(null)
  private peakPeriodConfigSubject = new BehaviorSubject<PeakPeriodConfig>(null)
  private peakPeriodByLineSubject = new BehaviorSubject<PeakPeriodLineRaw>(null)
  tkOffPeakHourByLineSubject = new BehaviorSubject<TkLine>(null)
  private intervalConfigSubject = new BehaviorSubject<IntervalConfig>(null)
  public infoLineDisruptionSubject = new BehaviorSubject<InfoLineDisruption>(null)
  public currentUserHasTrainTrackingInformationPrivilegeSubject = new BehaviorSubject<boolean>(null)
  lineType: string

  constructor(public platformService: PlatformService, public urlService: UrlService, public userService: UserService, private _ref: ElementRef, private datePipe: DatePipe) {
    const subscribePeakPeriodByLine: UnaryFunction<PeakPeriodConfig, any> = (peakPeriodConfig: PeakPeriodConfig) => {
      return this.peakPeriodByLineSubject.pipe(
        shareReplay(1),
        map((res) => {
          return { peakPeriodLineRaw: res, peakPeriodConfig }
        })
      )
    }
    const peakConfig = (referentialLine) => this.peakPeriodConfigSubject.pipe(shareReplay(1), filter<PeakPeriodConfig>(Boolean), flatMap(subscribePeakPeriodByLine), map(setPeakPeriodSplitterTime(referentialLine)))

    const intervalConfig = (intervalLine: IntervalLine) =>
      this.intervalConfigSubject.pipe(
        shareReplay(1),
        filter<IntervalConfig>(Boolean),
        map((i: IntervalConfig) => {
          return { intervalConfig: i, intervalLine }
        })
      )

    this.peakPeriodRawWithLevelSubject = this.referentialLineSubject.pipe(shareReplay(1), flatMap(peakConfig))

    this.intervalLevel = this.intervalByLineSubject.pipe(
      shareReplay(1),
      filter<IntervalLine>(Boolean),
      flatMap(intervalConfig),
      tap((next) => {
        next.intervalLine.intervalTrackOneLevelColor = calcIntervalColorLevel(next.intervalConfig, next.intervalLine.percentageTrackOneInterval)
        next.intervalLine.intervalTrackTwoLevelColor = calcIntervalColorLevel(next.intervalConfig, next.intervalLine.percentageTrackTwoInterval)
      }),
      map((next) => calcIntervalColorLevel(next.intervalConfig, next.intervalLine.sumPercentageInterval))
    )
  }

  private _currentUserHasTrainTrackingInformationPrivilege: boolean
  @Input()
  get currentUserHasTrainTrackingInformationPrivilege(): boolean {
    return this._currentUserHasTrainTrackingInformationPrivilege
  }

  set currentUserHasTrainTrackingInformationPrivilege(bool: boolean) {
    this.currentUserHasTrainTrackingInformationPrivilegeSubject.next(bool)
  }

  private _intervalConfig: IntervalConfig

  @Input()
  get intervalConfig(): IntervalConfig {
    return this._intervalConfig
  }

  set intervalConfig(value: IntervalConfig) {
    this.intervalConfigSubject.next(value)
  }

  private _intervalByLine: IntervalLine

  @Input()
  get intervalByLine(): IntervalLine {
    return this._intervalByLine
  }

  set intervalByLine(value: IntervalLine) {
    this.intervalByLineSubject.next(value)
  }

  private _peakPeriodByLine: PeakPeriodLineRaw

  @Input()
  get peakPeriodByLine(): PeakPeriodLineRaw {
    return this._peakPeriodByLine
  }

  set peakPeriodByLine(value: PeakPeriodLineRaw) {
    this.peakPeriodByLineSubject.next(value)
  }

  private _peakPeriodConfig: PeakPeriodConfig

  @Input()
  get peakPeriodConfig(): PeakPeriodConfig {
    return this._peakPeriodConfig
  }

  set peakPeriodConfig(value: PeakPeriodConfig) {
    this.peakPeriodConfigSubject.next(value)
  }

  private _referentialLine: ReferentialLine

  @Input()
  get referentialLine(): ReferentialLine {
    return this._referentialLine
  }

  set referentialLine(value: ReferentialLine) {
    this.displayContent(false)
    this.referentialLineSubject.next(value)
    if(value){
      this.lineType = value.type
    }
  }

  private _tkLine: TkLine

  @Input()
  get tkLine(): TkLine {
    return this._tkLine
  }

  set tkLine(value: TkLine) {
    this.tkOffPeakHourByLineSubject.next(value)
  }

  private _infoLineDisruption: InfoLineDisruption

  @Input()
  get infoLineDisruption(): InfoLineDisruption {
    return this._infoLineDisruption
  }

  set infoLineDisruption(value: InfoLineDisruption) {
    this.infoLineDisruptionSubject.next(value)
  }

  getTitleDisruptionText(infoLineDisruption: InfoLineDisruption): string {
    if (infoLineDisruption && infoLineDisruption.status !== 'NORMAL') {
      if (infoLineDisruption.status === 'DISRUPTION_EVENT') {
        return infoLineDisruption.disruptionEvent.cause
      } else {
        return infoLineDisruption.traficSituationEvent.conditionLabel
      }
    }
    return ''
  }

  getMessageDisruptionText(infoLineDisruption: InfoLineDisruption): string {
    if (infoLineDisruption && infoLineDisruption.status !== 'NORMAL') {
      if (infoLineDisruption.status === 'DISRUPTION_EVENT') {
        return infoLineDisruption.disruptionEvent.detail
      } else {
        const text = infoLineDisruption.traficSituationEvent.natureLabel
        const type = infoLineDisruption.traficSituationEvent.impactType
        const label1 = infoLineDisruption.traficSituationEvent.impactedObjects[0].objectLabel
        let label2 = ''
        if (type === 'SECTION') {
          label2 = infoLineDisruption.traficSituationEvent.impactedObjects[1].objectLabel
        }
        const date = this.datePipe.transform(infoLineDisruption.traficSituationEvent.created, 'HH:mm', 'ZZZZZ', 'fr')
        return generatePerturbationMessage(type, text, label1, label2, date)
      }
    }
  }

  getDisruptionColor(infoLineDisruption: InfoLineDisruption): Observable<string> {
    if (infoLineDisruption) {
      if (infoLineDisruption.status === 'DISRUPTION_EVENT') {
        return this.intervalLevel
      } else if (infoLineDisruption.status === 'TRAFIC_SITUATION_EVENT') {
        switch (infoLineDisruption.traficSituationEvent.severity) {
          case 'SLIGHT':
            return of('slight')
          case 'SEVERE':
            return of('severe')
          case 'VERY_SEVERE':
            return of('very_severe')
          default:
            return of('')
        }
      } else {
        return of('')
      }
    } else {
      return of('')
    }
  }

  displayContent(value: boolean) {
    this.hiddenContent = value
  }
}
