import { Component, Input, OnDestroy } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { ApiLinesSliderService } from '../../core/services/api-line-slider.service'
import { takeUntil, tap } from 'rxjs/operators'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnDestroy {
  @Input()
  load: boolean
  @Input()
  message: string
  show: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  destroy: Subject<boolean> = new Subject<boolean>()

  constructor(private sliderService: ApiLinesSliderService) {
    this.sliderService.currentLineState$
      .pipe(
        takeUntil(this.destroy),
        tap((r) => {
          this.show.next(true)
          setTimeout(() => this.show.next(false), 3000)
        })
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this.destroy.next(false)
    this.destroy.complete()
  }
}
