<ion-card>
  <ion-card-content>
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-justify-content-between">
        <ion-col>
          <div class="cartridge-type">
            {{ 'app.shared.dashboard.production.cartridge.' + intervalKey | translate }}
          </div>
        </ion-col>
      </ion-row>

      <!-- DESKTOP & TABLET -->
      <ion-row *ngIf="!platformService.isPlatformMobile(); else mobile" [class.tablet-detail]="platformService.isPlatformTablet()">
        <ion-col size="5">
          <div [ngClass]="intervalStatistics.peakPeriodLevelColor" class="cartridge-percentage">
            <span>{{ intervalStatistics.sumPercentageInterval * 100 | number: '2.0-0' }}</span
            ><span class="percentage-symbol">%</span>
          </div>
        </ion-col>
        <ion-col class="col-detail" size="7">
          <ion-row>
            <ion-col>
              <div class="cartridge-title">
                {{ 'app.shared.dashboard.production.cartridge.theoretical' | translate }}
              </div>
            </ion-col>
            <ion-col>
              <div class="cartridge-title">
                {{ 'app.shared.dashboard.production.cartridge.real' | translate }}
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <div class="cartridge-detail">
                {{ intervalStatistics.sumTheoreticalInterval | customTime }}
              </div>
            </ion-col>
            <ion-col>
              <div class="cartridge-detail">
                {{ intervalStatistics.sumRealInterval | customTime }}
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- MOBILE -->
      <ng-template #mobile>
        <ion-row>
          <ion-col>
            <div [ngClass]="intervalStatistics.peakPeriodLevelColor" class="cartridge-percentage-mobile">
              <span>{{ intervalStatistics.sumPercentageInterval * 100 | number: '2.0-0' }}</span
              ><span class="percentage-symbol">%</span>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-row>
              <ion-col>
                <div class="cartridge-title-mobile">
                  {{ 'app.shared.dashboard.production.cartridge.theoretical' | translate }}
                </div>
              </ion-col>
              <ion-col>
                <div class="cartridge-title-mobile">
                  {{ 'app.shared.dashboard.production.cartridge.real' | translate }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="cartridge-detail-mobile">
                  {{ intervalStatistics.sumTheoreticalInterval | customTime }}
                </div>
              </ion-col>
              <ion-col>
                <div class="cartridge-detail-mobile">
                  {{ intervalStatistics.sumRealInterval | customTime }}
                </div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ng-template>
    </ion-grid>
  </ion-card-content>
</ion-card>
