import { Injectable } from '@angular/core'
import { CONFIGURATION_MOBILE } from '../../../phaser/configs/configuration.mobile'
import { CONFIGURATION_WEB_APP } from '../../../phaser/configs/configuration.webApp'
import { CONFIGURATION_TABLET } from '../../../phaser/configs/configuration.tablet'
import 'phaser'
import { PlatformService } from '../services/platform.service'

@Injectable({
  providedIn: 'root',
})
export class GameConfigurationService {
  constructor(private platformService: PlatformService) {}

  getGameConfiguration(): Phaser.Types.Core.GameConfig {
    switch (true) {
      case this.platformService.isPlatformMobile():
        return CONFIGURATION_MOBILE
      case this.platformService.isPlatformTablet():
        return CONFIGURATION_TABLET
      case this.platformService.isPlatformDesktop():
        return CONFIGURATION_WEB_APP
      default:
        return CONFIGURATION_WEB_APP
    }
  }
}
