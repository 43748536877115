import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(valueToDisplay: any, defaultValue: string): any {
    if (valueToDisplay instanceof Array) {
      valueToDisplay = valueToDisplay.filter((value) => !!value)
      if (valueToDisplay.length > 0) {
        return valueToDisplay.join('')
      } else {
        return defaultValue
      }
    }
    return !!valueToDisplay ? valueToDisplay : defaultValue
  }
}
