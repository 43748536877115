import { TranslateService } from '@ngx-translate/core'
import { Injector } from '@angular/core'
import { LOCATION_INITIALIZED } from '@angular/common'
import { appProperties } from '../environments/app.properties'

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null))
      locationInitialized.then(() => {
        translate.setDefaultLang(appProperties.langToSet)
        translate.use(appProperties.langToSet).subscribe(
          () => {},
          (err) => {
            console.error('Problem with language initialization.')
          },
          () => {
            resolve(null)
          }
        )
      })
    })
}
