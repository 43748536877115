import { Injectable, Injector } from '@angular/core'
import { AppVersion } from '@ionic-native/app-version/ngx'
import { HttpClient } from '@angular/common/http'
import { paths } from '../../../environments/paths'
import { Actuator } from '../models/actuator'
import { environment } from '../../../environments/environment'
import { ModalController } from '@ionic/angular'
import { ApkVersionComponent } from '../../shared/apk-version/apk-version.component'
import { map } from 'rxjs/operators'
import { PlatformService } from './platform.service'
import { SnackBarComponent } from '../../shared/snake-bar/snack-bar.component'

@Injectable({
  providedIn: 'root',
})
export class ApkVersionService {
  private appVersion: AppVersion
  private http: HttpClient
  private platformService: PlatformService
  private modalController: ModalController
  private snackBar: SnackBarComponent
  private messageConnectionState = 'app.shared.window.listener.message.connection.state.initial'

  constructor(private injector: Injector) {}

  async load(): Promise<boolean> {
    this.appVersion = this.injector.get(AppVersion)
    this.http = this.injector.get(HttpClient)
    this.platformService = this.injector.get(PlatformService)
    this.modalController = this.injector.get(ModalController)
    this.modalController = this.injector.get(ModalController)
    this.snackBar = this.injector.get(SnackBarComponent)
    await this.checkNavigatorInternetConnection()
    if (environment.production) {
      if (this.platformService.isPlatform('android') && this.platformService.isPlatformNative()) {
        const ciTagVersion = await this.http
          .get<Actuator>(paths.actuator)
          .pipe(map((actuatorInfo) => actuatorInfo['ci-tag'].split('-')[1]))
          .toPromise()
        const apkVersionNumber = await this.appVersion.getVersionNumber()

        const apkVersionSplit = apkVersionNumber.split('.')
        const apkMajorVersion = apkVersionSplit[0] + '.' + apkVersionSplit[1]

        const apiVersionSplit = ciTagVersion.split('.')
        const apiMajorVersion = apiVersionSplit[0] + '.' + apiVersionSplit[1]
        console.log('ApiMajorVersion', apiMajorVersion)

        if (apkMajorVersion !== apiMajorVersion) {
          return this.presentModal(this.modalController)
        }
      }
    }

    return new Promise((res) => res(true))
  }

  async presentModal(closeModalWindow): Promise<boolean> {
    return await new Promise<boolean>(async (resolve) => {
      const modal = await closeModalWindow.create({
        component: ApkVersionComponent,
        componentProps: {
          resolve,
          closeModalWindow,
        },
        backdropDismiss: false,
      })
      await modal.present()
    })
  }

  async checkNavigatorInternetConnection(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!navigator.onLine) {
        this.snackBar.messageSubject.next(this.messageConnectionState)
        this.checkConnexion(resolve)
      } else {
        resolve(true)
      }
    })
  }

  checkConnexion(resolve) {
    if (!navigator.onLine) {
      window.setTimeout(() => this.checkConnexion(resolve), 2000)
    } else {
      resolve(true)
    }
  }
}
