import { BehaviorSubject, from, Observable, of } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { Subscription } from 'stompjs'
import { SocketConnectionService } from './socket-connection.service'
import { LocalStorageService } from '../../local-storage.service'
import { appProperties } from '../../../../environments/app.properties'
import { flatMap, tap } from 'rxjs/operators'
import { Client } from "@stomp/stompjs";

export class TopicBehaviorSubjectPair<T> {
  topicPath: string
  behaviorSubject: BehaviorSubject<T>

  constructor(topicPath: string, behaviorSubject: BehaviorSubject<T>) {
    this.topicPath = topicPath
    this.behaviorSubject = behaviorSubject
  }
}
export class SocketService<T> {
  private subscriptionsMemory: Map<Subscription, TopicBehaviorSubjectPair<T>> = new Map<Subscription, TopicBehaviorSubjectPair<T>>()

  constructor(private socketConnectionService: SocketConnectionService, private localStorageService: LocalStorageService) {
    if (socketConnectionService.visibilityState$) {
      socketConnectionService.visibilityState$
        .pipe(
          tap((next) => {
            if (next !== 'hidden') {
              const toResubscribes: Map<Subscription, TopicBehaviorSubjectPair<T>> = new Map<Subscription, TopicBehaviorSubjectPair<T>>()
              this.subscriptionsMemory.forEach((value, key) => {
                toResubscribes.set(key, value)
              })
              this.subscriptionsMemory = new Map<Subscription, TopicBehaviorSubjectPair<T>>()
              toResubscribes.forEach((value, key) => {
                this.subscribe([value.topicPath], value.behaviorSubject, key.id).subscribe()
              })
            }
          })
        )
        .subscribe()
    }
  }

  subscribe(topicPath: string[], subject: BehaviorSubject<T>, subscriptionId?: string): Observable<Subscription[]> {
    return from(this.localStorageService.getItem(appProperties.token))
      .pipe(flatMap((token) => from(this.socketConnectionService.getClient(token.value))))
      .pipe(flatMap((client) => of(this.subscriptionAfterConnection(topicPath, subject, client, subscriptionId))))
  }

  subscriptionAfterConnection(topicPath: string[], subject: BehaviorSubject<T>, client: Client, subscriptionId?: string): Subscription[] {
    return topicPath.map((topic) => {
      const subscribtion = client.subscribe(topic, (message) => {
        const payload = JSON.parse(message.body) as T
        if (environment.showPayload) {
          console.log(payload)
        }
        subject.next({ ...subject.value, ...payload })
      })
      if (subscriptionId) {
        subscribtion.id = subscriptionId
      }
      this.subscriptionsMemory.set(subscribtion, new TopicBehaviorSubjectPair<T>(topic, subject))
      return subscribtion
    })
  }

  protected unSubscriptionAfterConnection(subscriptions: Subscription[]): void {
    const toDelete: Array<Subscription> = new Array<Subscription>()
    this.subscriptionsMemory.forEach((value, key) => {
      subscriptions.forEach((subscription) => {
        if (key.id === subscription.id) {
          key.unsubscribe()
          toDelete.push(key)
        }
      })
    })
    toDelete.forEach((subscriptionUnsubscribed) => {
      this.subscriptionsMemory.delete(subscriptionUnsubscribed)
    })
  }

  public subscriptionWithAutoReconnection(topicPath: string[], subject: BehaviorSubject<T>): Observable<Subscription[]> {
    return this.subscribe(topicPath, subject)
  }
}
