import { Component, Input, OnInit } from '@angular/core'
import { PeakPeriodGlobal, PeakPeriodLine, PeakPeriodLineRaw, PeakPeriodRaw } from '../../core/models/peak-period'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, flatMap, map, shareReplay } from 'rxjs/operators'
import { PeakPeriodConfig } from '../../core/models/peak-periodConfig'
import { PeriodType } from '../production-switcher-button/production-switcher-button.component'
import { extractPeakPeriodFromPeriod, setPeakPeriodMorningAndEvening } from './peak-period-mobile.helper'
import { PlatformService } from '../../core/services/platform.service'
import { getLevelColorFromPercentage } from '../colorTools'

@Component({
  selector: 'app-peak-period-mobile',
  templateUrl: './peak-period-mobile.component.html',
  styleUrls: ['./peak-period-mobile.component.scss'],
})
export class PeakPeriodMobileComponent implements OnInit {
  peakPeriodFromPeriod$: Observable<PeakPeriodGlobal>
  peakPeriodGlobalTimeSubject: BehaviorSubject<PeriodType> = new BehaviorSubject<PeriodType>('MORNING')
  peakPeriodRawGlobalWithLevelSubject$: Observable<{
    peakPeriodMorning: PeakPeriodGlobal
    peakPeriodEvening: PeakPeriodGlobal
  }>
  peakPeriodSubject: BehaviorSubject<PeakPeriodRaw> = new BehaviorSubject<PeakPeriodRaw>(null)
  peakPeriodDetail$: Observable<PeakPeriodLine[]>
  peakPeriodConfigSubject: BehaviorSubject<PeakPeriodConfig> = new BehaviorSubject<PeakPeriodConfig>(null)

  constructor(public platformService: PlatformService) {
    const peakConfig = (peakPeriodGlobal) => this.peakPeriodConfigSubject.pipe(shareReplay(1), filter<PeakPeriodConfig>(Boolean), map(setPeakPeriodMorningAndEvening(peakPeriodGlobal)))
    this.peakPeriodRawGlobalWithLevelSubject$ = this.peakPeriodSubject.pipe(shareReplay(1), filter<PeakPeriodRaw>(Boolean), flatMap(peakConfig))

    const setPeakPeriodFromPeriod = (periodType) => this.peakPeriodRawGlobalWithLevelSubject$.pipe(shareReplay(1), filter<PeakPeriodRaw>(Boolean), map(extractPeakPeriodFromPeriod(periodType)))
    this.peakPeriodFromPeriod$ = this.peakPeriodGlobalTimeSubject.pipe(shareReplay(1), filter<PeriodType>(Boolean), flatMap(setPeakPeriodFromPeriod))
  }

  private _peakPeriodConfig: PeakPeriodConfig

  @Input()
  get peakPeriodConfig(): PeakPeriodConfig {
    return this._peakPeriodConfig
  }

  set peakPeriodConfig(value: PeakPeriodConfig) {
    this.peakPeriodConfigSubject.next(value)
  }

  private _peakPeriod: PeakPeriodRaw

  @Input()
  get peakPeriod(): PeakPeriodRaw {
    return this._peakPeriod
  }

  set peakPeriod(value: PeakPeriodRaw) {
    this.peakPeriodSubject.next(value)
  }

  ngOnInit(): void {
    this.peakPeriodDetail$ = this.peakPeriodGlobalTimeSubject.pipe(
      shareReplay(1),
      filter<PeriodType>(Boolean),
      flatMap((period) => {
        return this.peakPeriodSubject.pipe(
          shareReplay(1),
          filter<PeakPeriodRaw>(Boolean),
          filter((e) => !!e.lines),
          map((peakPeriodRaw: PeakPeriodRaw) =>
            peakPeriodRaw.lines
              .map((p: PeakPeriodLineRaw) =>
                period === 'MORNING'
                  ? new PeakPeriodLine(p.lineName, p.scheduleName, p.morningStation, p.morningTheoretical, p.morningReal, p.morningPercentage, getLevelColorFromPercentage(p.morningPercentage, this.peakPeriodConfigSubject.value))
                  : new PeakPeriodLine(p.lineName, p.scheduleName, p.eveningStation, p.eveningTheoretical, p.eveningReal, p.eveningPercentage, getLevelColorFromPercentage(p.eveningPercentage, this.peakPeriodConfigSubject.value))
              )
              .sort((acc: PeakPeriodLineRaw, value: PeakPeriodLineRaw) =>
                acc.lineName.localeCompare(value.lineName, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              )
          )
        )
      })
    )
  }

  public isPlatformMobile() {
    return this.platformService.isPlatformMobile()
  }
}
