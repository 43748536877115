import { Component, Input } from '@angular/core'
import { PeakPeriodLine } from '../../core/models/peak-period'
import { PlatformService } from '../../core/services/platform.service'

@Component({
  selector: 'app-peak-period-detail',
  templateUrl: './peak-period-detail.component.html',
  styleUrls: ['./peak-period-detail.component.scss'],
})
export class PeakPeriodDetailComponent {
  @Input() peakPeriodDetailLines: PeakPeriodLine[]

  constructor(public platformService: PlatformService) {}
}
