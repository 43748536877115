export const TCO_COLORS = {
  UNKNOWN: '#FA00FF',
  // CDV
  FREE: '#393939',
  OCCUPIED: '#FF0000',
  // Sous Section
  POWER_ON: '#56BD45',
  POWER_OFF: '#CC0328',
  // Feux
  GREEN_LIGHT: '#56BD45',
  RED_LIGHT: '#CC0328',
  TURNED_OFF: '#666666',
  // Aiguilles
  OPEN: '#393939',
  CLOSE: '#DADADA',
  ACTIVATED: '#393939',
  SPECIAL: '#000000',
}
