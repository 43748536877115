import { Component, Input, OnInit } from '@angular/core'
import { TkStatistics } from '../../core/models/tk'
import { PlatformService } from '../../core/services/platform.service'
import { getLevelColorFromPercentage } from '../colorTools'
import { PeakPeriodConfig } from '../../core/models/peak-periodConfig'
import { BehaviorSubject } from 'rxjs'
import { TkProductionType } from '../tk-switcher-button/tk-switcher-button.component'

@Component({
  selector: 'app-tk-statistics-cartridge',
  templateUrl: './tk-statistics-cartridge.component.html',
  styleUrls: ['./tk-statistics-cartridge.component.scss'],
})
export class TkStatisticsCartridgeComponent implements OnInit {
  @Input() tkStatistics: TkStatistics
  @Input() peakPeriodConfig: PeakPeriodConfig
  @Input() tkProductionSubject: BehaviorSubject<TkProductionType>
  @Input() productionInvalid: boolean
  constructor(public platformService: PlatformService) {}

  ngOnInit() {}

  getColor(percentage: number): string {
    return getLevelColorFromPercentage(Math.round(percentage), this.peakPeriodConfig)
  }
}
