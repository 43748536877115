export class LiveEvent {
  private readonly platformId: string
  private readonly agents: Agent[]
  private readonly incidents: Incident[]

  constructor({ platformId, agents, incidents }: { platformId: string; agents: Agent[]; incidents: Incident[] }) {
    this.platformId = platformId
    this.incidents = incidents
    this.agents = agents
  }

  public static From({ platformId, agents, incidents }: LiveEventDto) {
    return new LiveEvent({
      platformId,
      agents,
      incidents,
    })
  }

  public hasAgents() {
    return this.agents.length > 0
  }

  public getNumberOfAgents() {
    return this.agents.length
  }

  public hasIncidents() {
    return this.incidents.length > 0
  }

  public getNumberOfIncidents() {
    return this.incidents.length
  }

  public displayAgents(): Agent[] {
    return this.agents
  }

  public displayIncidents(): Incident[] {
    return this.incidents
  }
}

export interface Agent {
  team: string
  tetraNumber: string
  arrivalDate: string
}

export interface Incident {
  startTime: string
  typeLabel: string
  eventId: string
}

export interface LiveEventDto {
  platformId: string
  agents: AgentDto[]
  incidents: IncidentDto[]
}

export interface AgentDto {
  platformId: string
  arrivalDate: string
  team: string
  tetraNumber: string
}

export interface IncidentDto {
  platformId: string
  typeLabel: string
  startTime: string
  eventId: string
}
