import { SelectedPlatformInfo } from './selected.platform.info'
import { SelectedTrainDelay } from './selected.train.delay'

export interface UserPreferences {
  selectedPlatformInfo: SelectedPlatformInfo
  selectedTrainDelay: SelectedTrainDelay
  selectedTrainAffluence: boolean
  selectedIncidents: boolean
}

export const userPreferences: UserPreferences = {
  selectedPlatformInfo: SelectedPlatformInfo.WAITING,
  selectedTrainDelay: SelectedTrainDelay.NONE,
  selectedTrainAffluence: false,
  selectedIncidents: true
}
