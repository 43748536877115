import { Component, Input } from '@angular/core'
import { IntervalLine } from '../../core/models/interval'

@Component({
  selector: 'app-interval-detail',
  templateUrl: './interval-detail.component.html',
  styleUrls: ['./interval-detail.component.scss'],
})
export class IntervalDetailComponent {
  @Input() lines: IntervalLine[]
}
