import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { PeakPeriodConfig } from '../../core/models/peak-periodConfig'
import { PeakPeriodRaw } from '../../core/models/peak-period'
import { Observable } from 'rxjs'
import { ApiPeakPeriodService } from '../../core/services/sockets/api-peak-period.service'
import { filter, flatMap } from 'rxjs/operators'

@Component({
  selector: 'app-peak-period-display',
  templateUrl: './peak-period-display.component.html',
  styleUrls: ['./peak-period-display.component.scss'],
})
export class PeakPeriodDisplayComponent implements OnInit, OnDestroy {
  @Input() peakPeriodConfig$: Observable<PeakPeriodConfig>

  peakPeriod$: Observable<PeakPeriodRaw>
  constructor(private apiPeakPeriodService: ApiPeakPeriodService) {}

  ngOnInit(): void {
    this.apiPeakPeriodService.getStreamMessages()
    const peakPeriodRawObservable = this.apiPeakPeriodService.state$.pipe(filter<PeakPeriodRaw>(Boolean))
    this.peakPeriod$ = this.peakPeriodConfig$.pipe(
      filter<PeakPeriodConfig>(Boolean),
      filter<PeakPeriodConfig>((r) => !!r),
      flatMap(() => peakPeriodRawObservable)
    )
  }

  ngOnDestroy(): void {
    this.apiPeakPeriodService.unSubscriptionStreamMessages()
  }
}
