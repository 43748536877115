export class PeakPeriodRaw {
  metroStatistics?: MetroStatisticsRaw
  rerStatistics?: RerStatisticsRaw
  globalStatistics?: GlobalStatisticsRaw
  lines?: PeakPeriodLineRaw[]
}
export interface Statistics {
  totalTheoretical: number
  totalReal: number
  percentage: number
  peakPeriodLevelColor?: string
}
interface StatisticsRaw {
  morningTotalTheoretical: number
  morningTotalReal: number
  morningPercentage: number
  eveningTotalTheoretical: number
  eveningTotalReal: number
  eveningPercentage: number
}
export class PeakPeriodGlobalRaw {
  metroStatistics: MetroStatisticsRaw
  rerStatistics: RerStatisticsRaw
  globalStatistics: GlobalStatisticsRaw
}

export class MetroStatisticsRaw implements StatisticsRaw {
  morningTotalTheoretical: number
  morningTotalReal: number
  morningPercentage: number
  eveningTotalTheoretical: number
  eveningTotalReal: number
  eveningPercentage: number

  constructor(morningTotalTheoretical: number, morningTotalReal: number, morningPercentage: number, eveningTotalTheoretical: number, eveningTotalReal: number, eveningPercentage: number) {
    this.morningTotalTheoretical = morningTotalTheoretical
    this.morningTotalReal = morningTotalReal
    this.morningPercentage = morningPercentage
    this.eveningTotalTheoretical = eveningTotalTheoretical
    this.eveningTotalReal = eveningTotalReal
    this.eveningPercentage = eveningPercentage
  }
}

export class RerStatisticsRaw extends MetroStatisticsRaw {
  morningTotalTheoretical: number
  morningTotalReal: number
  morningPercentage: number
  eveningTotalTheoretical: number
  eveningTotalReal: number
  eveningPercentage: number

  constructor(morningTotalTheoretical: number, morningTotalReal: number, morningPercentage: number, eveningTotalTheoretical: number, eveningTotalReal: number, eveningPercentage: number) {
    super(morningTotalTheoretical, morningTotalReal, morningPercentage, eveningTotalTheoretical, eveningTotalReal, eveningPercentage)
  }
}

export class GlobalStatisticsRaw implements StatisticsRaw {
  morningTotalTheoretical: number
  morningTotalReal: number
  morningPercentage: number
  eveningTotalTheoretical: number
  eveningTotalReal: number
  eveningPercentage: number

  constructor(morningTotalTheoretical: number, morningTotalReal: number, morningPercentage: number, eveningTotalTheoretical: number, eveningTotalReal: number, eveningPercentage: number) {
    this.morningTotalTheoretical = morningTotalTheoretical
    this.morningTotalReal = morningTotalReal
    this.morningPercentage = morningPercentage
    this.eveningTotalTheoretical = eveningTotalTheoretical
    this.eveningTotalReal = eveningTotalReal
    this.eveningPercentage = eveningPercentage
  }
}
export class PeakPeriodLinesRaw {
  lines: PeakPeriodLineRaw[]
}

export class PeakPeriodLineRaw {
  lineName: string
  scheduleName?: string
  morningStation?: string
  morningTheoretical?: number
  morningReal?: number
  morningPercentage?: number
  eveningStation?: string
  eveningTheoretical?: number
  eveningReal?: number
  eveningPercentage?: number
}

export class PeakPeriod {
  peakPeriodGlobal: PeakPeriodGlobal
  peakPeriodLines: PeakPeriodLine[]
}

export class PeakPeriodGlobal {
  metroStatistics: MetroStatistics
  rerStatistics: RerStatistics
  globalStatistics: GlobalStatistics

  constructor(metroStatistics: MetroStatistics, rerStatistics: RerStatistics, globalStatistics: GlobalStatistics) {
    this.metroStatistics = metroStatistics
    this.rerStatistics = rerStatistics
    this.globalStatistics = globalStatistics
  }
}

export class MetroStatistics implements Statistics {
  totalTheoretical: number
  totalReal: number
  percentage: number
  peakPeriodLevelColor: string

  constructor(totalTheoretical: number, totalReal: number, percentage: number, peakPeriodLevelColor: string) {
    this.totalTheoretical = totalTheoretical
    this.totalReal = totalReal
    this.percentage = percentage
    this.peakPeriodLevelColor = peakPeriodLevelColor
  }
}

export class RerStatistics implements Statistics {
  totalTheoretical: number
  totalReal: number
  percentage: number
  peakPeriodLevelColor: string

  constructor(totalTheoretical: number, totalReal: number, percentage: number, peakPeriodLevelColor: string) {
    this.totalTheoretical = totalTheoretical
    this.totalReal = totalReal
    this.percentage = percentage
    this.peakPeriodLevelColor = peakPeriodLevelColor
  }
}

export class GlobalStatistics implements Statistics {
  totalTheoretical: number
  totalReal: number
  percentage: number
  peakPeriodLevelColor: string

  constructor(totalTheoretical: number, totalReal: number, percentage: number, peakPeriodLevelColor: string) {
    this.totalTheoretical = totalTheoretical
    this.totalReal = totalReal
    this.percentage = percentage
    this.peakPeriodLevelColor = peakPeriodLevelColor
  }
}

export class PeakPeriodLine {
  lineName: string
  scheduleName: string
  station: string
  theoretical: number
  real: number
  percentage: number
  peakPeriodLevelColor?: string

  constructor(lineName: string, scheduleName: string, station: string, theoretical: number, real: number, percentage: number, peakPeriodLevelColor?: string) {
    this.lineName = lineName
    this.scheduleName = scheduleName
    this.station = station
    this.theoretical = theoretical
    this.real = real
    this.percentage = percentage
    this.peakPeriodLevelColor = peakPeriodLevelColor
  }
}
