import { Injectable } from '@angular/core'
import { ApiNotificationService } from './api-notification.service'
import { BehaviorSubject, Observable } from 'rxjs'
import { Router } from '@angular/router'
import { URL_CONFIG } from '../../configurations/urls'
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications'

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  public notificationTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  public notificationToken$: Observable<string> = this.notificationTokenSubject.asObservable()

  token = null

  constructor(private apiNotificationServiceService: ApiNotificationService,
              private router: Router
              ) {
  }

  public createMobileToken() {
    return this.notificationRegistration()
        .then((token: Token) => this.token = token.value)
        .then((token: string) => {
          this.fcmListener().then();
          return token;
        })
        .then((token: string) => {
          this.fcmActionListener().then();
          return token;
        })
  }

  /**
   * Request permission + get fcm token
   */
  async notificationRegistration(): Promise<Token> {
    const result = await PushNotifications.requestPermissions()
    if (result.receive === 'granted') {
      await PushNotifications.register()
    } else {
      throw new Error(`can't register application`)
    }
    return await this.fcmRegistration()
  }

  async fcmRegistration(): Promise<Token> {
    return new Promise<Token>((resolve) => {
      PushNotifications.addListener('registrationError', error => console.log(error));
      PushNotifications.addListener('registration', (token: Token) => resolve(token))
    })
  }

  async fcmListener() {
    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.log('Push received: ' + JSON.stringify(notification))
    })
  }

  async fcmActionListener() {
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      const line = notification.notification.data.line
      if (!!line) {
        this.router
            .navigate([URL_CONFIG.URL_REAL_TIME_TRAFFIC_MAP], {
              queryParams: { line },
            })
            .then()
      }
    })
  }

  /**
   * Clean notifications
   */
  removeAllDeliveredNotifications(): Promise<void> {
    return PushNotifications.removeAllDeliveredNotifications()
  }

  public getToken(): string {
    return this.token
  }

}
