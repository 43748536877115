import { Component, Input, OnInit, Output } from '@angular/core'
import { PlatformService } from '../../core/services/platform.service'
import { BehaviorSubject } from 'rxjs'
import { TkProductionType } from '../tk-switcher-button/tk-switcher-button.component'
import { Tk, TkLine } from '../../core/models/tk'
import { PeakPeriodConfig } from '../../core/models/peak-periodConfig'
import { getLevelColorFromPercentage } from '../colorTools'
import { PeakPeriodLineRaw } from '../../core/models/peak-period'

@Component({
  selector: 'app-tk-detail',
  templateUrl: './tk-detail.component.html',
  styleUrls: ['./tk-detail.component.scss'],
})
export class TkDetailComponent {
  @Input() tk: Tk
  @Input() peakPeriodConfig: PeakPeriodConfig
  @Output() tkProductionSubject: BehaviorSubject<TkProductionType> = new BehaviorSubject<TkProductionType>('LAP')

  constructor(public platformService: PlatformService) {}

  getColor(percentage: number): string {
    return getLevelColorFromPercentage(percentage, this.peakPeriodConfig)
  }
}
