<ion-button
  (click)="openMenu()"
  *ngIf="!platformService.isPlatformMobile(); else mobile"
  [ngClass]="{
    button_on_course: userPreferences.selectedTrainDelay === selectedDelayType.ON_COURSE,
    button_on_day: userPreferences.selectedTrainDelay === selectedDelayType.ON_DAY,
    delay_selected: userPreferences.selectedTrainDelay !== selectedDelayType.NONE
  }"
  class="delay"
>
  <a class="item-background-color">
    <ion-icon
      [ngStyle]="{
        color: userPreferences.selectedTrainDelay === selectedDelayType.NONE ? '#BBBBBB' : 'white'
      }"
      [src]="'/assets/icon/delay-train.svg'"
      class="icon"
      slot="icon-only"
    >
    </ion-icon>
    <ion-label
      [ngClass]="{
        delayed: userPreferences.selectedTrainDelay == selectedDelayType.ON_COURSE || userPreferences.selectedTrainDelay == selectedDelayType.ON_DAY
      }"
    >
      {{
        userPreferences.selectedTrainDelay === selectedDelayType.NONE
          ? ('app.realtime.traffic.delay.button.no.display' | translate)
          : userPreferences.selectedTrainDelay === selectedDelayType.ON_COURSE
          ? ('app.realtime.traffic.delay.button.on.course' | translate)
          : ('app.realtime.traffic.delay.button.on.day' | translate)
      }}
    </ion-label>
  </a>
</ion-button>
<ng-template #mobile>
  <ion-button
    (click)="openMenu()"
    [ngClass]="{
      button_on_course: userPreferences.selectedTrainDelay == selectedDelayType.ON_COURSE,
      button_on_day: userPreferences.selectedTrainDelay == selectedDelayType.ON_DAY,
      delay_selected: userPreferences.selectedTrainDelay !== selectedDelayType.NONE
    }"
    class="delay_mobile"
  >
    <ion-icon
      [ngClass]="{
        delayed: userPreferences.selectedTrainDelay == selectedDelayType.ON_COURSE || userPreferences.selectedTrainDelay == selectedDelayType.ON_DAY
      }"
      [ngStyle]="{
        fill: userPreferences.selectedTrainDelay === selectedDelayType.NONE ? '#BBBBBB' : 'white'
      }"
      [src]="'/assets/icon/delay-train.svg'"
      class="icon"
      slot="icon-only"
      >>
    </ion-icon>
  </ion-button>
</ng-template>
