import { Injectable } from '@angular/core'
import { SocketService } from './socket.service'
import { SocketConnectionService } from './socket-connection.service'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Subscription } from 'stompjs'
import { PeakPeriodLineRaw, PeakPeriodRaw } from '../../models/peak-period'
import { LocalStorageService } from '../../local-storage.service'
import { ReferentialLine } from '../../models/lines'
import { paths } from '../../../../environments/paths'
import { shareReplay, takeUntil, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ApiPeakPeriodService extends SocketService<PeakPeriodRaw | PeakPeriodLineRaw> {
  peakPeriod: BehaviorSubject<PeakPeriodRaw | PeakPeriodLineRaw> = new BehaviorSubject<PeakPeriodRaw | PeakPeriodLineRaw>(null)
  peakPeriodByLine: BehaviorSubject<PeakPeriodRaw | PeakPeriodLineRaw> = new BehaviorSubject<PeakPeriodRaw | PeakPeriodLineRaw>(null)
  public state$: Observable<PeakPeriodRaw | PeakPeriodLineRaw> = this.peakPeriod.asObservable()
  public stateByLine$: Observable<PeakPeriodRaw | PeakPeriodLineRaw> = this.peakPeriodByLine.asObservable()
  private peakPeriodSubscription: Subscription[]
  private peakPeriodByLineSubscription: Subscription[]
  private destroy: Subject<boolean> = new Subject<boolean>()

  constructor(socketConnectionService: SocketConnectionService, localStorageService: LocalStorageService) {
    super(socketConnectionService, localStorageService)
  }

  async getStreamMessages(): Promise<Subscription[]> {
    if (!!this.peakPeriodSubscription) {
      this.unSubscriptionStreamMessages()
    }
    this.peakPeriod.next(null)
    return super
      .subscriptionWithAutoReconnection([paths.peakPeriodLine, paths.peakPeriodGlobal], this.peakPeriod)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionStreamMessages()),
        tap((subscription) => (this.peakPeriodSubscription = [...subscription]))
      )
      .toPromise()
  }

  async getStreamMessagesByLine(referentialLine: ReferentialLine): Promise<Subscription[]> {
    if (!!this.peakPeriodByLineSubscription) {
      this.unSubscriptionStreamMessagesByLine()
    }
    this.peakPeriodByLine.next(null)
    return super
      .subscriptionWithAutoReconnection([`${paths.peakPeriodByLine}${referentialLine.name}`], this.peakPeriodByLine)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionStreamMessagesByLine()),
        tap((subscription) => (this.peakPeriodByLineSubscription = [...subscription]))
      )
      .toPromise()
  }

  unSubscriptionStreamMessages(): void {
    if (!!this.peakPeriodSubscription) {
      super.unSubscriptionAfterConnection(this.peakPeriodSubscription)
    }
  }

  unSubscriptionStreamMessagesByLine(): void {
    if (!!this.peakPeriodByLineSubscription) {
      super.unSubscriptionAfterConnection(this.peakPeriodByLineSubscription)
    }
  }
}
