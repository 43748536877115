<ion-menu [ngClass]="platformService.isPlatformTablet() || platformService.isPlatformDesktop() ? 'ion-menu-tablet' : 'ion-menu-mobile'" [swipeGesture]="platformService.isPlatformNative() && !platformService.isPlatformIos()" contentId="content" menuId="main" side="start" type="overlay">
  <ion-header class="ion-no-border">
    <ion-grid>
      <ion-row>
        <ion-col push="11">
          <ion-buttons>
            <ion-icon (click)="this.close()" name="close-sharp"></ion-icon>
          </ion-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-header>
  <ion-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-list lines="none">
            <ion-menu-toggle>
              <ion-item *ngIf="userService.currentIsNotDenied$ | async" [routerLink]="[urlService.liveTrafficUrls.URL_REAL_TIME_TRAFFIC]" routerDirection="forward">{{ 'app.shared.menu.side.real.time.traffic' | translate }}</ion-item>
              <ion-item *ngIf="(userService.currentUserHasTcoInformationPrivilege$ | async) === true && (userService.currentIsNotDenied$ | async)" [routerLink]="[urlService.liveTrafficUrls.URL_TCO_NETWORK]" routerDirection="forward">{{ 'app.shared.menu.side.tco.network' | translate }}</ion-item>
              <ion-item *ngIf="(userService.currentUserHasPeakPeriodInformationPrivilege$ | async) === true && (userService.currentIsNotDenied$ | async)" [routerLink]="[urlService.liveTrafficUrls.URL_PRODUCTION_DASHBOARD]" routerDirection="forward">{{
                'app.shared.menu.side.dashboard.production' | translate
              }}</ion-item>
              <ion-item *ngIf="userService.currentIsNotDenied$ | async" [routerLink]="[urlService.liveTrafficUrls.URL_INTERVAL_DASHBOARD]" routerDirection="forward">{{ 'app.shared.menu.side.dashboard.interval' | translate }}</ion-item>
              <ion-item *ngIf="userService.currentIsNotDenied$ | async" [routerLink]="[urlService.liveTrafficUrls.URL_INFORMATION_TRAFFIC]" routerDirection="forward">{{ 'app.shared.menu.side.information.incidents' | translate }}</ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col>
          <ion-list lines="none">
            <ion-menu-toggle>
              <ion-item [routerLink]="[urlService.liveTrafficUrls.URL_PROFILE]" routerDirection="forward">{{ 'app.shared.menu.side.profile' | translate }}</ion-item>
              <ion-item *ngIf="userService.currentIsNotDenied$ | async" [routerLink]="[urlService.liveTrafficUrls.URL_HELP]" routerDirection="forward">{{ 'app.shared.menu.side.help' | translate }} </ion-item>
              <ion-item *ngIf="userService.currentIsNotDenied$ | async" [routerLink]="[urlService.liveTrafficUrls.URL_GDPR]" routerDirection="forward">{{ 'app.shared.menu.side.gdpr' | translate }} </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-col>
      </ion-row>
      <hr />
      <ion-row>
        <ion-col>
          <ion-list lines="none">
            <ion-menu-toggle>
              <ion-item [routerLink]="">
                <app-logout-button></app-logout-button>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ion-menu>
