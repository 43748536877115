import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { filter, flatMap, shareReplay, take, tap } from 'rxjs/operators'
import { ReferentialLine, ReferentialLines } from '../models/lines'
import { paths } from '../../../environments/paths'
import { ActivatedRoute, Params } from '@angular/router'
import { ApiUserProfileService } from './api-user-profile.service'
import { UserProfile } from '../models/user-profile'

@Injectable({
  providedIn: 'root',
})
export class ApiLinesSliderService {
  public currentLine = new BehaviorSubject<ReferentialLine>(null)
  public currentLineState$: Observable<ReferentialLine> = this.currentLine.asObservable().pipe(shareReplay(1), filter<ReferentialLine>(Boolean))
  private linesMap = new BehaviorSubject<ReferentialLines>(null)
  public state$: Observable<ReferentialLines> = this.linesMap.asObservable()
  public defaultLine: string
  public userProfile: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null)
  private _lineToShow: ReferentialLine = null

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private userProfileService: ApiUserProfileService) {
    const queryParams$: Observable<Params> = route.queryParams

    this.httpClient
      .get<ReferentialLines>(paths.referentialLines)
      .pipe(
        tap((ref) => {
          this.linesMap.next(ref)
          queryParams$.pipe(filter(({ line }) => line !== null && line !== undefined)).subscribe(({ line }) => {
            const referentialLine = ref.lines.filter((refLine) => refLine.name === line)[0]
            this.currentLine.next(!!referentialLine ? referentialLine : ref.lines[0])
            this._lineToShow = referentialLine
          })
        }),
        flatMap((ref) =>
          this.userProfileService.getUserProfile().pipe(
            take(1),
            tap((profile) => {
              if (profile && (this._lineToShow === null || this._lineToShow === undefined)) {
                this.userProfile.next(profile)
                this.defaultLine = this.userProfile.value.defaultLine
                this.currentLine.next(ref.lines[ref.lines.indexOf(ref.lines.filter((refLineName) => refLineName.name === this.defaultLine)[0])])
              }
            })
          )
        )
      )
      .subscribe()
  }

  updateReferentialLine(line: string) {
    this.httpClient
      .get<ReferentialLines>(paths.referentialLines)
      .pipe(
        tap((ref) => {
          this.linesMap.next(ref)
          const referentialLine = ref.lines.filter((refLine) => refLine.name === line)[0]
          this.currentLine.next(!!referentialLine ? referentialLine : ref.lines[0])
          this._lineToShow = referentialLine
        })
      )
      .subscribe()
  }
}
