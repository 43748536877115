export function generatePerturbationMessage(type: string, text: string, label1: string, label2: string, date: string) {
  if (!!type) {
    if (type === 'STATION') {
      return text + ' à la station ' + label1 + ' (début à ' + date + ').'
    } else if (type === 'SECTION') {
      return text + ' entre les stations ' + label1 + ' - ' + label2 + ' (début à ' + date + ').'
    } else if (type === 'LINE') {
      return text + '. Impact sur l’ensemble de la ligne (début à ' + date + ').'
    }
    return text
  }
  return text
}
