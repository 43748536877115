import 'phaser'
import { LINE_GAP } from '../game-objects/groups/track/track.service'

export function controlConfig(cursors, scene: Phaser.Scene) {
  return {
    camera: scene.cameras.main,
    left: cursors.left,
    right: cursors.right,
    zoomIn: scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q),
    zoomOut: scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.E),
    acceleration: 0.06,
    drag: 0.0005,
    maxSpeed: 1.0,
  }
}

export function controlDeviceConfig(camera: Phaser.Cameras.Scene2D.Camera, scene: any) {
  let startTime: number
  let inertiaPromise: Promise<void>
  const cancelPromiseToken = { cancel: false }
  let accumulatedPan: Array<{ dx: number; dy: number; dt: number }>

  scene.rexGestures.add
    .pan({
      threshold: 20,
    })
    .on('panstart', () => {
      if (inertiaPromise) {
        cancelPromiseToken.cancel = true
        inertiaPromise = null
      }
      accumulatedPan = new Array<{ dx: number; dy: number; dt: number }>()
      startTime = new Date().getTime()
    })
    .on('pan', (pan) => {
      scrollCamera(camera, pan.dx, pan.dy)
      const endTime: number = new Date().getTime()
      accumulatedPan.push({ dx: pan.dx, dy: pan.dy, dt: endTime - startTime })
      startTime = endTime
    })
    .on('panend', (pan) => {
      cancelPromiseToken.cancel = false
      const endTime: number = new Date().getTime()
      accumulatedPan.push({ dx: pan.dx, dy: pan.dy, dt: endTime - startTime })
      inertiaPromise = cameraInertia(camera, accumulatedPan, cancelPromiseToken)
    })

  scene.rexGestures.add
    .pinch({
      threshold: 20,
    })
    .on('pinch', (pinch) => {
      camera.zoom *= pinch.scaleFactor
    })

  scene.input.on('wheel', (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
    const zoom = deltaY * -0.005
    if (camera.zoom + zoom <= 3 && camera.zoom + zoom >= 0.4) {
      camera.zoom += zoom
    }
  })

  let lastTime = 0
  scene.input.on('pointerdown', () => {
    if (inertiaPromise) {
      cancelPromiseToken.cancel = true
      inertiaPromise = null
    }
    const clickDelay = scene.time.now - lastTime
    lastTime = scene.time.now
    if (clickDelay < 350 && clickDelay > 150) {
      camera.zoom = 1
      scene.sceneController.scrollCameraToCenterOfTheMap((scene.groupTrackElementLineMapping.getWidth().max - scene.groupTrackElementLineMapping.getWidth().min) / 2, LINE_GAP)
    }
  })
}

async function cameraInertia(camera, accumulatedPan: Array<{ dx: number; dy: number; dt: number }>, cancelPromiseToken) {
  const accumulator: { deltaX: number; deltaY: number; deltaTime: number } = getAccumulatedValuesForLatest(accumulatedPan, 10)

  const distance = Math.abs(Math.sqrt(Math.pow(accumulator.deltaX, 2) + Math.pow(accumulator.deltaY, 2)))
  const stepX = accumulator.deltaX / distance
  const stepY = accumulator.deltaY / distance
  const calculatedSpeed = distance / accumulator.deltaTime

  const animationRefreshRateMs = 10
  const animationDurationInMs = 1000
  const maxSpeed = 3
  const minSpeed = 0.005
  const minSpeedThresholdToStart = 0.5
  const initialSpeedIncreasePerCent = 0

  const initialMovementSpeed = calculatedSpeed > maxSpeed ? maxSpeed : calculatedSpeed
  if (initialMovementSpeed > minSpeedThresholdToStart) {
    await moveWithInertia(initialMovementSpeed, initialSpeedIncreasePerCent, cancelPromiseToken, minSpeed, animationRefreshRateMs, camera, stepX, stepY, animationDurationInMs)
  }
}

function getAccumulatedValuesForLatest(accumulatedPan: Array<{ dx: number; dy: number; dt: number }>, latest: number) {
  const accumulatedValues = { deltaTime: 0, deltaX: 0, deltaY: 0 }
  const lowerBound: number = accumulatedPan.length < latest ? accumulatedPan.length : latest
  for (let i = 0; i < lowerBound; i++) {
    const latestPan = accumulatedPan.pop()
    accumulatedValues.deltaY += latestPan.dy
    accumulatedValues.deltaX += latestPan.dx
    accumulatedValues.deltaTime += latestPan.dt
  }
  return accumulatedValues
}

async function moveWithInertia(initialMovementSpeed: number, initialSpeedIncreasePerCent: number, cancelPromiseToken, minSpeed: number, animationRefreshRateMs: number, camera, stepX: number, stepY: number, animationDurationInMs: number) {
  const startTime = new Date().getTime()
  let currentSpeed = initialMovementSpeed + initialMovementSpeed * (initialSpeedIncreasePerCent / 100)
  let lastFrame = new Date().getTime()
  while (!cancelPromiseToken.cancel && currentSpeed > minSpeed) {
    await sleep(animationRefreshRateMs)
    const end = new Date().getTime()
    const elapsed = end - lastFrame
    lastFrame = end
    scrollCamera(camera, stepX * currentSpeed * elapsed, stepY * currentSpeed * elapsed)
    const totalElapsed = end - startTime
    currentSpeed = initialMovementSpeed - initialMovementSpeed * (totalElapsed / animationDurationInMs)
  }
}
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
export function scrollCamera(camera, deltaX, deltaY) {
  camera.scrollX = camera.scrollX - deltaX
  camera.scrollY = camera.scrollY - deltaY
}
