<ion-grid [class.no-padding]="platformService.isPlatformMobile()">
  <ion-row [class.no-padding]="platformService.isPlatformMobile()" class="header">
    <div>
      <h4>
        {{ 'app.shared.dashboard.production.peak.period.detail.title' | translate }}
      <span class="red" *ngIf="tk.productionInvalid===true"> {{ 'app.octave.interruption' | translate }}  </span>
      </h4>
    </div>
    <div>
      <app-tk-switcher-button (tkProductionSelected)="tkProductionSubject.next($event)"></app-tk-switcher-button>
    </div>
  </ion-row>
  <!--  TABLE DETAILS-->
  <ion-row [class.no-padding]="platformService.isPlatformMobile()" class="parent-row">
    <ion-col [class.no-padding]="platformService.isPlatformMobile()">
      <ion-row class="separator peak-period-detail-list-header">
        <ion-col class="custom-padding" sizeLg="1" sizeMd="1" sizeXs="2">
          <span></span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="6" sizeMd="6" sizeXs="5">
          <span>{{ 'app.shared.dashboard.production.peak.period.detail.table.header.theoretical' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="6" sizeMd="6" sizeXs="5">
          <span>{{ 'app.shared.dashboard.production.peak.period.detail.table.header.real' | translate }}</span>
        </ion-col>
        <ion-col class="custom-padding" sizeLg="2" sizeMd="2" sizeXs="3">
          <span>{{ 'app.shared.dashboard.production.peak.period.detail.table.header.percentage' | translate }}</span>
        </ion-col>
      </ion-row>
      <div *ngIf="!!tk && tk.lines">
        <ion-row *ngFor="let tkLine of tk.lines; let i = index" [class.white-line]="i % 2 == 0" class="separator peak-period-detail-list-row">
          <ion-col class="custom-padding-icon" sizeLg="1" sizeMd="1" sizeXs="2">
            <ion-icon [src]="'assets/icon/lines/' + tkLine.lineName + '.svg'" class="ion-icon-list"></ion-icon>
          </ion-col>
          <ion-col class="custom-padding" sizeLg="6" sizeMd="6" sizeXs="5">
            <span>{{ tkProductionSubject.value == 'KILOMETER' ? (tkLine.productionTheoretical | NonZeroDisplay) : (tkLine.lapsTheoretical | round | NonZeroDisplay) }}</span>
          </ion-col>
          <ion-col class="custom-padding" sizeLg="6" sizeMd="6" sizeXs="5">
            <span *ngIf="tkLine.productionInvalid===false; else invalidProductionTemplate">{{ tkProductionSubject.value == 'KILOMETER' ? (tkLine.productionReal | NonZeroDisplay) : (tkLine.lapsReal | round | NonZeroDisplay) }}</span>
          </ion-col>
          <ion-col class="custom-padding percent" sizeLg="2" sizeMd="2" sizeXs="3">
            <div *ngIf="tkLine.productionInvalid===false; else invalidProductionTemplate">
              <div *ngIf="tkProductionSubject.value == 'KILOMETER'" [ngClass]="getColor(tkLine.productionPercentage)">
                <span>{{ tkLine.productionPercentage * 100 | number: '2.0-0' | NonZeroDisplay }}</span>
                <span *ngIf="tkLine.productionPercentage">%</span>
              </div>
              <div *ngIf="tkProductionSubject.value == 'LAP'" [ngClass]="getColor(tkLine.lapsPercentage)">
                <span>{{ tkLine.productionPercentage * 100 | number: '2.0-0' | NonZeroDisplay }}</span>
                <span *ngIf="tkLine.lapsPercentage">%</span>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
<ng-template #invalidProductionTemplate>
  -
</ng-template>

