import 'phaser'

export enum TextBitmapEnum {
  ROBOTO_BOLD_72 = 'assets/fonts/roboto-bitmap/roboto-bold-72.png|assets/fonts/roboto-bitmap/roboto-bold-72.xml',
  ROBOTO_REGULAR_32 = 'assets/fonts/roboto-bitmap/roboto-regular-32.png|assets/fonts/roboto-bitmap/roboto-regular-32.xml',
  ROBOTO_MEDIUM_32 = 'assets/fonts/roboto-bitmap/roboto-medium-32.png|assets/fonts/roboto-bitmap/roboto-medium-32.xml',
}

export class TextBitmapManager {
  constructor(scene: Phaser.Scene) {
    Object.keys(TextBitmapEnum).forEach((k) => {
      const pair = TextBitmapEnum[k].split('|')
      scene.load.bitmapFont(k, pair[0], pair[1])
    })
  }
}
