import { Injectable } from '@angular/core'
import { Track, TrafficWindow } from '../../../../phaser/modeles/lines'
import { MainScene } from '../../../../phaser/scenes/main'
import { SceneType } from '../../../../phaser/managers/scene.manager'
import { BehaviorSubject } from 'rxjs'
import { ContainerTrackElement, GroupTrackElement, TrackElement } from '../../../../phaser/game-objects/groups/track/track.element'
import { MainGame } from '../../../../phaser/main.game'
import { Platform, PlatformInfoTraffic, TrafficStateType } from '../../models/platform-info-traffic'
import { ColorPhaser } from '../../../../phaser/modeles/color'
import { userPreferences } from '../../configurations/user/user.preferences'
import { AgentDto, IncidentDto, LiveEvent } from '../../models/live-event'
import { LiveEventElement } from '../../../../phaser/game-objects/groups/liveEvent/liveEvent.element'

@Injectable({
  providedIn: 'root',
})
export class LinesMappingService {
  private previouslyInterruptedPlatformWindowIds: Array<string> = new Array<string>()
  private previouslyColored: Array<ContainerTrackElement> = new Array<ContainerTrackElement>()

  static drawLineMapping(tracks: Track, scene: Phaser.Scene): GroupTrackElement {
    const mainScene: MainScene = scene as MainScene
    const groupTrackElement: GroupTrackElement = mainScene.groupTrackElementLineMapping
    const position = new BehaviorSubject({
      track1: {
        primaryBranch: 0,
        primaryBranchDoublePlatform: 0,
        primaryBranchTriplePlatform: 0,
        secondaryBranch: 0,
        secondaryBranchDoublePlatform: 0,
        switchCase: false,
        startingSwitchCase: false,
        platformSwitchCase: false,
        platformSwitchTimeCase: false,
        triplePlatformSwitchCase: false,
      },
      track2: {
        primaryBranch: 0,
        primaryBranchDoublePlatform: 0,
        primaryBranchTriplePlatform: 0,
        secondaryBranch: 0,
        secondaryBranchDoublePlatform: 0,
        switchCase: false,
        startingSwitchCase: false,
        platformSwitchCase: false,
        triplePlatformSwitchCase: false,
      },
    })

    const stationsNamesByNumber: Map<string, number> = this.getStationNamesByNumber(tracks)
    const containerTrackElements = tracks.trafficWindow.map((elementsTracks) =>
      elementsTracks.map((trafficWindow: TrafficWindow) => new ContainerTrackElement(scene, trafficWindow, tracks.line, position, this.mustShowStationName(trafficWindow.platFormName, trafficWindow.track, stationsNamesByNumber), userPreferences))
    )
    containerTrackElements
      .reduce((acc, r) => acc.concat(r))
      .forEach((value) => {
        groupTrackElement.add(value, true)
      })

    // this.debugCdv(groupTrackElement, scene);
    return groupTrackElement
  }

  // Can be removed safely but is also very useful for development
  private static debugCdv(groupTrackElement: GroupTrackElement, scene: Phaser.Scene) {
    groupTrackElement.children.entries.forEach((value, index) => {
      const container: ContainerTrackElement = value as ContainerTrackElement
      let offset = 6
      if (container.track === 2) {
        offset = -15
      }
      container.add(
        new Phaser.GameObjects.Text(scene, 0, container.positionYOfCdv + offset, String(container.id), {
          color: ColorPhaser.STATION_NAME_HEX.toString(),
          fontStyle: 'bold',
          fontSize: '8px',
          fontFamily: 'Arial',
        })
      )
    })
  }

  private static getStationNamesByNumber(tracks: Track): Map<string, number> {
    const numberOfStationNames: Map<string, number> = new Map<string, number>()
    tracks.trafficWindow.forEach((trafficWindows) => {
      trafficWindows.forEach((trafficWindow) => {
        const platFormName = trafficWindow.platFormName
        const number = numberOfStationNames.get(platFormName)
        if (number) {
          numberOfStationNames.set(platFormName, number + 1)
        } else {
          numberOfStationNames.set(platFormName, 1)
        }
      })
    })
    return numberOfStationNames
  }

  private static mustShowStationName(line: string, track: number, stationsNamesByNumber: Map<string, number>) {
    if (line === 'XXXX') {
      return false
    }
    if (track === 1) {
      return true
    } else {
      return stationsNamesByNumber.get(line) === 1
    }
  }

  clearLine(phaserGame: Phaser.Game): void {
    if (!!(phaserGame.scene.getScene(SceneType.MAIN) as MainScene) && !!(phaserGame.scene.getScene(SceneType.MAIN) as MainScene).groupTrackElementLineMapping) {
      ;(phaserGame.scene.getScene(SceneType.MAIN) as MainScene).groupTrackElementLineMapping.clear(true, true)
      this.previouslyColored = new Array<ContainerTrackElement>()
      this.previouslyInterruptedPlatformWindowIds = new Array<string>()
    }
  }

  updateInterruptedLines(phaserGame: MainGame, platformInfoTraffic: PlatformInfoTraffic) {
    const groupTrackElement: GroupTrackElement = (phaserGame.scene.getScene(SceneType.MAIN) as MainScene).groupTrackElementLineMapping

    const interruptedPlatforms: Platform[] = platformInfoTraffic.platforms.filter((platform) => platform.trafficState === TrafficStateType.INTERRUPTED)

    if (this.haveInterruptedPlatformsChanged(interruptedPlatforms, this.previouslyInterruptedPlatformWindowIds)) {
      this.cleanPreviousInterruptions()
      const platformToColor: Array<ContainerTrackElement> = new Array<ContainerTrackElement>()
      const cdvToColor: Array<ContainerTrackElement> = new Array<ContainerTrackElement>()

      interruptedPlatforms.forEach((interruptedPlatform) => {
        const containerByPlatformTimeId = groupTrackElement.getContainerByPlatformTimeId(interruptedPlatform.windowNumber)
        if (containerByPlatformTimeId) {
          platformToColor.push(containerByPlatformTimeId)
          cdvToColor.push(...this.getLinkedCdvsToColor(containerByPlatformTimeId, groupTrackElement))
        }

        if (containerByPlatformTimeId.platformDouble) {
          const platformDouble = groupTrackElement.getAllContainerByTrafficWindow(containerByPlatformTimeId.platformDouble)
          platformDouble.forEach((p) => {
            platformToColor.push(p as ContainerTrackElement)
          })
          cdvToColor.push(...this.getLinkedCdvsToColor(containerByPlatformTimeId, groupTrackElement))
        }
      })

      this.previouslyInterruptedPlatformWindowIds = interruptedPlatforms.map((value) => value.windowNumber)

      platformToColor.forEach((containerToColor) => {
        this.setInterruptedColor(containerToColor)
        this.previouslyColored.push(containerToColor)
      })

      const cdvIds = cdvToColor.map((value) => value.id)
      cdvToColor.forEach((value) => {
        if (cdvIds.filter((id) => id === value.id).length > 1) {
          this.setInterruptedColor(value)
          this.previouslyColored.push(value)
        }
      })
    }
  }

  updateLiveEvents({ phaserGame, incidents, agents }: { phaserGame: MainGame; incidents: IncidentDto[]; agents: AgentDto[] }) {
    (phaserGame.scene.getScene(SceneType.MAIN) as MainScene).groupTrackElementLineMapping
      .getLiveEvents()
      .forEach((liveEventElement: LiveEventElement) => {
          liveEventElement.updateStateFrom(
            {
              liveEventElement,
              incidents,
              agents
            }
          )
    })
  }

  // tslint:disable-next-line:max-line-length
  private getLinkedCdvsToColor(containerByPlatformTimeId: ContainerTrackElement, groupTrackElement: GroupTrackElement): Array<ContainerTrackElement> {
    const childElementsToColor: Array<ContainerTrackElement> = new Array<ContainerTrackElement>()
    if (containerByPlatformTimeId.platformLinkedCdv) {
      containerByPlatformTimeId.platformLinkedCdv.forEach((linkedCdv) => {
        const linkedCdvContainers = groupTrackElement.getAllContainerByTrafficWindow(linkedCdv)
        if (linkedCdvContainers.length > 0) {
          linkedCdvContainers.forEach((linkedCdvContainer) => {
            childElementsToColor.push(linkedCdvContainer as ContainerTrackElement)
          })
        }
      })
    }
    return childElementsToColor
  }

  private cleanPreviousInterruptions() {
    this.previouslyColored.forEach((previouslyColored) => {
      if (previouslyColored && previouslyColored.trackElement) {
        previouslyColored.trackElement.setColorOfElementIfNotTrackSwitch()
      }
    })
    this.previouslyColored = new Array<ContainerTrackElement>()
  }

  private setInterruptedColor(containerTrackElement: ContainerTrackElement) {
    const trackElement: TrackElement = containerTrackElement.trackElement
    trackElement.tint = ColorPhaser.INTERRUPTED_TRAFFIC
  }

  private haveInterruptedPlatformsChanged(interruptedPlatforms: Platform[], previouslyInterruptedPlatformWindowTimeIds: Array<string>) {
    const current = interruptedPlatforms.map((value) => value.windowNumber)
    return JSON.stringify(current.sort()) !== JSON.stringify(previouslyInterruptedPlatformWindowTimeIds.sort())
  }
}
