import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { SnackBarComponent } from '../../shared/snake-bar/snack-bar.component'
import { Router } from '@angular/router'
import { paths } from '../../../environments/paths'
import { URL_CONFIG } from '../configurations/urls'
import { LocalStorageService } from '../local-storage.service'

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private snackBar: SnackBarComponent, private router: Router, private localStorage: LocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error) => this.switchError(error)))
  }

  switchError(error) {
    const regex = new RegExp(paths.authUrl)
    if (error instanceof HttpErrorResponse && !regex.test(error.url)) {
      let message = 'app.snackbar.error.message.'
      switch (error.status) {
        case 400:
          message += '400'
          break
        case 401:
          message += '401'
          this.router.navigate([URL_CONFIG.URL_LOGIN], { replaceUrl: true }).then(() => window.location.reload())
          break
        case 403:
          message += '403'
          this.router.navigate([URL_CONFIG.URL_RESTRICTED_ACCESS]).then()
          break
        case 404:
          message += '404'
          break
        case 500:
          message += '500'
          this.router.navigate([URL_CONFIG.URL_RESTRICTED_ACCESS]).then()
          break
        case 502:
          message += '502'
          break
        case 503:
          message += '503'
          break
        case 504:
          message += '504'
          break
        default:
          message += 'default'
          break
      }
      this.snackBar.messageSubject.next(message)
    }
    return throwError(error)
  }
}
