import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { ActivationState } from '@stomp/stompjs'
import { filter, skip } from 'rxjs/operators'

export type ConnectionState = 'connected' | 'disconnected' | 'unknown'

@Injectable({
  providedIn: 'root',
})
export class StompStoreService {
  stompStoreSubject: Subject<any> = new Subject<any>()
  state$: Observable<any>
  connectionStateSubject: Subject<ActivationState> = new Subject<ActivationState>()
  connectionStateS$ = this.connectionStateSubject.asObservable().pipe(
    filter((connectionState) => connectionState === ActivationState.ACTIVE || connectionState === ActivationState.INACTIVE),
    skip(1)
  )

  constructor() {}
}
