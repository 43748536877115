import { Component, Input } from '@angular/core'
import { Statistics } from '../../core/models/peak-period'
import { PlatformService } from '../../core/services/platform.service'

@Component({
  selector: 'app-peak-period-cartridge',
  templateUrl: './peak-period-cartridge.component.html',
  styleUrls: ['./peak-period-cartridge.component.scss'],
})
export class PeakPeriodCartridgeComponent {
  @Input() peakPeriodStatistics: Statistics
  @Input() peakPeriodKey: string

  constructor(public platformService: PlatformService) {}
}
