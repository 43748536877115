import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { filter, map, shareReplay, takeUntil, tap } from 'rxjs/operators'
import { PeakPeriodLine, PeakPeriodRaw } from '../../core/models/peak-period'
import { PeakPeriodConfig } from '../../core/models/peak-periodConfig'
import { PlatformService } from '../../core/services/platform.service'

export type PeriodType = 'MORNING' | 'EVENING'

@Component({
  selector: 'app-production-switcher-button',
  templateUrl: './production-switcher-button.component.html',
  styleUrls: ['./production-switcher-button.component.scss'],
})
export class ProductionSwitcherButtonComponent implements OnDestroy {
  @Output() periodSelected: EventEmitter<PeriodType> = new EventEmitter<PeriodType>()
  peakPeriodSubject: BehaviorSubject<PeakPeriodRaw> = new BehaviorSubject<PeakPeriodRaw>(null)
  peakPeriodConfigSubject: BehaviorSubject<PeakPeriodConfig> = new BehaviorSubject<PeakPeriodConfig>(null)
  peakPeriodDetail$: Observable<PeakPeriodLine[]>
  period: PeriodType
  peakPeriodGlobalTimeSubject: BehaviorSubject<PeriodType> = new BehaviorSubject<PeriodType>('MORNING')
  destroy: Subject<boolean> = new Subject<boolean>()

  constructor(public platformService: PlatformService) {
    const setPeakPeriodGlobalTimeSubject = (peakPeriodConfig: PeakPeriodConfig): PeriodType => {
      const peakPeriodSplitter = new Date()
      const [hourPeakPeriodMetro, minutePeakPeriodMetro] = peakPeriodConfig.schedules.metro.peakPeriodEvening.start.split('h')
      peakPeriodSplitter.setHours(Number(hourPeakPeriodMetro), Number(minutePeakPeriodMetro))
      return Date.now() > peakPeriodSplitter.getTime() ? 'EVENING' : 'MORNING'
    }
    this.peakPeriodConfigSubject
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        filter<PeakPeriodConfig>(Boolean),
        map(setPeakPeriodGlobalTimeSubject),
        tap((period: PeriodType) => {
          this.periodSelected.emit(period)
          this.peakPeriodGlobalTimeSubject.next(period)
        })
      )
      .subscribe()
  }

  private _peakPeriod: PeakPeriodRaw

  @Input()
  get peakPeriod(): PeakPeriodRaw {
    return this._peakPeriod
  }

  set peakPeriod(value: PeakPeriodRaw) {
    this.peakPeriodSubject.next(value)
  }

  private _peakPeriodConfig: PeakPeriodConfig

  @Input()
  get peakPeriodConfig(): PeakPeriodConfig {
    return this._peakPeriodConfig
  }

  set peakPeriodConfig(value: PeakPeriodConfig) {
    this.peakPeriodConfigSubject.next(value)
  }

  selectPeriod(type: PeriodType) {
    this.peakPeriodGlobalTimeSubject.next(type)
    this.periodSelected.emit(type)
  }

  ngOnDestroy(): void {
    this.destroy.next(false)
    this.destroy.unsubscribe()
  }
}
