<div class="schedule-peak-hour" *ngIf="periodType == 'HC'">
  <div class="schedule-peak-hour-content">
    <div class="schedule-peak-hour-metro">
      <ion-icon src="./assets/icon/metro.svg"></ion-icon>
      <div class="schedule-metro">
        <ion-text class="strong">
          {{ 'app.shared.dashboard.production.scheduleTk.off.peak.hour' | translate }}
          :
        </ion-text>
        <ion-text>
          {{ displayOffPeakHourTimeSlots() }}
        </ion-text>
      </div>
    </div>
  </div>
</div>
<div class="info-supplement">
  <ion-text>
    {{ 'app.shared.dashboard.production.scheduleTk.info.metro' | translate }}
  </ion-text>
</div>
