import { SocketService } from './socket.service'
import { SocketConnectionService } from './socket-connection.service'
import { LocalStorageService } from '../../local-storage.service'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Subscription } from 'stompjs'
import { Interval, IntervalLine } from '../../models/interval'
import { ReferentialLine } from '../../models/lines'
import { shareReplay, takeUntil, tap } from 'rxjs/operators'
import { paths } from '../../../../environments/paths'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class ApiIntervalService extends SocketService<Interval | IntervalLine> {
  intervalByLine: BehaviorSubject<IntervalLine> = new BehaviorSubject<IntervalLine>(null)
  stateIntervalByLine$: Observable<IntervalLine> = this.intervalByLine.asObservable().pipe(shareReplay(1))
  intervals: BehaviorSubject<Interval> = new BehaviorSubject<Interval>(null)
  stateIntervals$: Observable<Interval> = this.intervals.asObservable().pipe(shareReplay(1))
  private destroy: Subject<boolean> = new Subject<boolean>()

  private intervalSubscription: Subscription[]
  private intervalLineSubscription: Subscription[]

  constructor(socketConnectionService: SocketConnectionService, localStorageService: LocalStorageService) {
    super(socketConnectionService, localStorageService)
  }

  async getStreamMessages(): Promise<Subscription[]> {
    if (!!this.intervalSubscription) {
      this.unSubscriptionStreamMessages()
    }
    this.intervals.next(null)
    return super
      .subscriptionWithAutoReconnection([paths.intervalLine], this.intervals)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionStreamMessages()),
        tap((subscription) => (this.intervalSubscription = [...subscription]))
      )
      .toPromise()
  }

  async getStreamMessagesByLine(referentialLine: ReferentialLine): Promise<Subscription[]> {
    if (!!this.intervalLineSubscription) {
      this.unSubscriptionIntervalLinesStreamMessages()
    }
    this.intervalByLine.next(null)
    return super
      .subscriptionWithAutoReconnection([`${paths.intervalByLine}${referentialLine.name}`], this.intervalByLine)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy),
        tap((subscription) => this.unSubscriptionIntervalLinesStreamMessages()),
        tap((subscription) => (this.intervalLineSubscription = [...subscription]))
      )
      .toPromise()
  }

  unSubscriptionStreamMessages(): void {
    if (!!this.intervalSubscription) {
      super.unSubscriptionAfterConnection(this.intervalSubscription)
    }
  }

  unSubscriptionIntervalLinesStreamMessages(): void {
    if (!!this.intervalLineSubscription) {
      super.unSubscriptionAfterConnection(this.intervalLineSubscription)
    }
  }
}
