import { Component, ElementRef } from '@angular/core'
import { Observable } from 'rxjs'
import { NavigationStart, Router } from '@angular/router'
import { IntervalService } from '../../core/services/interval.service'
import { filter, map, shareReplay } from 'rxjs/operators'
import { PlatformService } from '../../core/services/platform.service'

interface MenuItem {
  name: string
  path: string
  regEx: RegExp
}

@Component({
  selector: 'app-title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.scss'],
})
export class TitleHeaderComponent {
  title: string
  date: number
  routerTitle$: Observable<string>
  menuItems: Array<MenuItem> = [
    {
      name: 'app.shared.menu.side.real.time.traffic',
      path: '/real-time-traffic',
      regEx: new RegExp('(real-time-traffic|real-time-traffic/map|real-time-traffic/next-departures|^/$)'),
    },
    {
      name: 'app.shared.menu.side.network.state',
      path: '/traffic-network-state',
      regEx: new RegExp('traffic-network-state'),
    },
    {
      name: 'app.shared.menu.side.dashboard.production',
      path: '/production-dashboard',
      regEx: new RegExp('production-dashboard'),
    },
    {
      name: 'app.shared.menu.side.dashboard.interval',
      path: '/interval-dashboard',
      regEx: new RegExp('interval-dashboard'),
    },
    {
      name: 'app.shared.menu.side.tco.network',
      path: '/tco-network',
      regEx: new RegExp('tco-network'),
    },
    {
      name: 'app.shared.menu.side.information.incidents',
      path: '/information-traffic',
      regEx: new RegExp('information-traffic'),
    },
    {
      name: 'app.shared.menu.side.gdpr',
      path: '/gdpr',
      regEx: new RegExp('gdpr'),
    },
    {
      name: 'app.shared.menu.side.profile',
      path: '/profile',
      regEx: new RegExp('profile'),
    },
    {
      name: 'app.shared.menu.side.average.interval',
      path: '/notifications/average-interval',
      regEx: new RegExp('average-interval'),
    },
    {
      name: 'app.shared.menu.side.notification',
      path: '/notifications',
      regEx: new RegExp('notifications'),
    },
    {
      name: 'app.shared.menu.side.help',
      path: '/help',
      regEx: new RegExp('help'),
    },
  ]

  constructor(public platformService: PlatformService, public router: Router, private intervalService: IntervalService, public elementRef: ElementRef) {
    intervalService.setInterval(1, () => {
      this.date = Date.now()
    })
    this.routerTitle$ = this.router.events.pipe(
      shareReplay(1),
      filter<NavigationStart>(Boolean),
      filter<NavigationStart>((navigationStart) => !!navigationStart.url),
      map((res) => this.menuItems.filter((menuItem) => res.url.match(menuItem.regEx)).map((menuItem) => menuItem.name)[0])
    )
  }
}
