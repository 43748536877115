<div class="info-line">
  <ion-grid>
    <ion-row class="info-line-header">
      <ion-col class="info-line-title" size="12">
        <ion-row [class.card-title-mobile]="platformService.isPlatformMobile()" class="card-title">
            {{ 'app.realtime.traffic.info.line.card.title.information' | translate }}<span>{{ 'app.realtime.traffic.info.line.card.title.line' | translate }}</span>
          <ion-text *ngIf="this.infoLineDisruptionSubject | async as infoLineDisruption" [hidden]="infoLineDisruption.status === 'NORMAL'" [ngClass]="getDisruptionColor(infoLineDisruption) | async" class="info-line-disruption-title">{{ this.getTitleDisruptionText(infoLineDisruption) | truncate : [35] }}</ion-text>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="this.infoLineDisruptionSubject | async as infoLineDisruption" [hidden]="infoLineDisruption.status === 'NORMAL' || hiddenContent || this.getMessageDisruptionText(infoLineDisruption).length === 0" class="info-line-disruption-content"
             [ngClass]="this.getDisruptionColor(infoLineDisruption) | async">
      <ion-icon (click)="displayContent(true)" class="info-line-disruption-close" [ngClass]="this.getDisruptionColor(infoLineDisruption) | async" name="close-circle-outline"></ion-icon>
      <ion-row class="info-line-disruption-message">
        <ion-text *ngIf="this.getMessageDisruptionText(infoLineDisruption)">{{ this.getMessageDisruptionText(infoLineDisruption) | truncate: [platformService.isPlatformMobile() ? 105 : 125] }}</ion-text>
      </ion-row>
      <ion-row class="info-line-disruption-redirect">
        <ion-text *ngIf="infoLineDisruption.status === 'TRAFIC_SITUATION_EVENT'"
                  [routerLink]="[urlService.liveTrafficUrls.URL_INFORMATION_TRAFFIC+'/voyager/'+infoLineDisruption.line]"> > {{ 'app.realtime.traffic.info.line.card.body.see.more' | translate }} </ion-text>
        <ion-text *ngIf="infoLineDisruption.status === 'DISRUPTION_EVENT'"
                  [routerLink]="[urlService.liveTrafficUrls.URL_INFORMATION_TRAFFIC+'/disruption/'+infoLineDisruption.line]"> > {{ 'app.realtime.traffic.info.line.card.body.see.more' | translate }} </ion-text>
      </ion-row>
    </ion-row>
    <ion-row class="body-content-row">
      <!-- HdP -->
      <ion-col [ngClass]="lineType==='RER' ? 'info-line-content-col': ''" size="{{lineType=='RER'?'4':'2.5'}}">
        <div class="info-line-content-wrapper">
          <div [class.info-line-content-mobile]="platformService.isPlatformMobile()" class="info-line-content">
            <ion-text [ngClass]="platformService.isPlatformMobile() ? 'info-line-col-title-mobile' : 'info-line-col-title'">
              {{ 'app.realtime.traffic.info.line.card.body.production' | translate }}
            </ion-text>
            <div
              *ngIf="((currentUserHasTrainTrackingInformationPrivilegeSubject | async) === true || (userService.currentUserHasPeakPeriodInformationPrivilege$ | async)) && peakPeriodRawWithLevelSubject | async as peakPeriodLineRawWithLevel; else noInformationProvideForSecurityPrivileges"
              [ngClass]="peakPeriodLineRawWithLevel.peakPeriodColor"
              class="info-line-interval"
            >
              <div *ngIf="peakPeriodLineRawWithLevel.percentage; else noInformationProvideForSecurityPrivileges">
                <ion-grid>
                  <ion-row [ngClass]="platformService.isPlatformMobile() ? 'info-line-interval-percent-mobile' : 'info-line-interval-percent'">
                    <div [class.info-line-detail-mobile]="platformService.isPlatformMobile()" [ngClass]="peakPeriodLineRawWithLevel.peakPeriodColor" class="info-line-detail">
                      {{ peakPeriodLineRawWithLevel.percentage * 100 | number: '1.0-0' | empty: '--' }}<span [ngClass]="peakPeriodLineRawWithLevel.peakPeriodColor" class="percentage-symbol">%</span>
                    </div>
                  </ion-row>
                  <div *ngIf="this.infoLineDisruptionSubject | async as infoLineDisruption" [hidden]="infoLineDisruption.status !== 'NORMAL' && !hiddenContent">
                    <ion-row [class.bottom-info-mobile]="platformService.isPlatformMobile()" class="bottom-info">
                      <div [ngClass]="platformService.isPlatformMobile() ? 'info-line-delay-detail-mobile' : 'info-line-delay-detail'" class="track-information">
                        <span [ngClass]="peakPeriodLineRawWithLevel.peakPeriodColor">{{ peakPeriodLineRawWithLevel.real }}</span
                        ><span>/{{ peakPeriodLineRawWithLevel.theorical }}</span>
                      </div>
                    </ion-row>
                  </div>
                </ion-grid>
              </div>
            </div>
          </div>
        </div>
      </ion-col>
      <!-- HC -->
      <ion-col *ngIf="lineType=='METRO'" class="info-line-content-col" size="2.5">
        <div class="info-line-content-wrapper">
          <div  [class.info-line-content-mobile]="platformService.isPlatformMobile()" class="info-line-content">
            <ion-text [ngClass]="platformService.isPlatformMobile() ? 'info-line-col-title-mobile' : 'info-line-col-title'">
              {{ 'app.realtime.traffic.info.line.card.body.tk.off.peak.hour' | translate }}
            </ion-text>
            <div *ngIf="((currentUserHasTrainTrackingInformationPrivilegeSubject | async) === true || (userService.currentUserHasPeakPeriodInformationPrivilege$ | async)) && tkOffPeakHourByLineSubject | async as tkLine; else noInformationProvideForSecurityPrivileges" class="info-line-interval">
              <div *ngIf="tkLine.productionInvalid === false; else noInformationProvideForSecurityPrivileges">
                <ion-grid>
                  <ion-row [ngClass]="platformService.isPlatformMobile() ? 'info-line-interval-percent-mobile' : 'info-line-interval-percent'">
                    <div [class.info-line-detail-mobile]="platformService.isPlatformMobile()" [ngClass]="tkLine.tkLevelColor" class="info-line-detail">{{ tkLine.lapsPercentage * 100  | number: '1.0-0' | empty: '-- ' }}<span [ngClass]="tkLine.tkLevelColor" class="percentage-symbol">%</span></div>
                  </ion-row>
                  <div *ngIf="this.infoLineDisruptionSubject | async as infoLineDisruption" [hidden]="infoLineDisruption.status !== 'NORMAL' && !hiddenContent">
                    <ion-row [class.bottom-info-mobile]="platformService.isPlatformMobile()" class="bottom-info">
                      <div [ngClass]="platformService.isPlatformMobile() ? 'info-line-delay-detail-mobile' : 'info-line-delay-detail'" class="track-information">
                        <span [ngClass]="tkLine.tkLevelColor">{{ tkLine.lapsReal | round | NonZeroDisplay }}</span
                        ><span>/{{ tkLine.lapsTheoretical | round | NonZeroDisplay }}</span>
                      </div>
                    </ion-row>
                  </div>
                </ion-grid>
              </div>
            </div>
          </div>
        </div>
      </ion-col>
      <!-- INTERVALLE -->
      <ion-col class="info-line-content-col" size="{{lineType=='RER'?'4':'3.5'}}">
        <div class="info-line-content-wrapper">
          <div [class.info-line-content-mobile]="platformService.isPlatformMobile()" class="info-line-content">
            <ion-text [ngClass]="platformService.isPlatformMobile() ? 'info-line-col-title-mobile' : 'info-line-col-title'">{{ 'app.realtime.traffic.info.line.card.body.interval' | translate }}</ion-text>
            <div *ngIf="intervalByLineSubject | async as intervalLine; else noInformationProvideForSecurityPrivileges" [ngClass]="intervalLevel | async" class="info-line-interval">
              <ion-grid *ngIf="!!intervalLine.sumPercentageInterval">
                <ion-row [ngClass]="platformService.isPlatformMobile() ? 'info-line-interval-percent-mobile' : 'info-line-interval-percent'">
                  <div>
                    <span [class.info-line-detail-mobile]="platformService.isPlatformMobile()" class="info-line-detail"> {{ intervalLine.sumPercentageInterval * 100 | number: '1.0-0' }}</span>
                    <span class="percentage-symbol">% </span>
                  </div>
                  <div [ngClass]="platformService.isPlatformMobile() ? 'info-line-interval-time-mobile' : 'info-line-interval-time'">
                    <span [ngClass]="intervalLevel | async">&nbsp; {{ intervalLine.sumRealInterval | customTime }}</span>
                  </div>
                </ion-row>
                <div *ngIf="this.infoLineDisruptionSubject | async as infoLineDisruption" [hidden]="infoLineDisruption.status !== 'NORMAL' && !hiddenContent">
                  <ion-row [class.bottom-info-mobile]="platformService.isPlatformMobile()" class="bottom-info">
                    <div [ngClass]="platformService.isPlatformMobile() ? 'info-line-delay-detail-mobile' : 'info-line-delay-detail'" class="track-information">
                      {{ 'app.realtime.traffic.info.line.card.body.delay.track.one' | translate }}
                      <span [ngClass]="intervalLine.intervalTrackOneLevelColor"> {{ intervalLine.percentageTrackOneInterval * 100 | number: '1.0-0' }}<span>%</span> </span>
                      {{ 'app.realtime.traffic.info.line.card.body.delay.track.two' | translate }}
                      <span [ngClass]="intervalLine.intervalTrackTwoLevelColor"> {{ intervalLine.percentageTrackTwoInterval * 100 | number: '1.0-0' }}<span>%</span> </span>
                    </div>
                  </ion-row>
                </div>
              </ion-grid>
            </div>
          </div>
        </div>
      </ion-col>
      <!-- RETARD -->
      <ion-col class="info-line-content-col" size="{{lineType=='RER'?'4':'3.5'}}">
        <div class="info-line-content-wrapper">
          <div [class.info-line-content-mobile]="platformService.isPlatformMobile()" class="info-line-content">
            <ion-text [ngClass]="platformService.isPlatformMobile() ? 'info-line-col-title-mobile' : 'info-line-col-title'">
              {{ 'app.realtime.traffic.info.line.card.body.delay.title' | translate }}
            </ion-text>
            <div
              *ngIf="((currentUserHasTrainTrackingInformationPrivilegeSubject | async) === true || (userService.currentUserHasPeakPeriodInformationPrivilege$ | async)) && intervalByLineSubject | async as intervalLine; else noInformationProvideForSecurityPrivileges"
              [ngClass]="intervalLevel | async"
              class="info-line-interval"
            >
              <ion-grid *ngIf="!!intervalLine.averageDelay">
                <ion-row [ngClass]="platformService.isPlatformMobile() ? 'info-line-interval-percent-mobile' : 'info-line-interval-percent'">
                  <div
                    [ngClass]="{
                      green: intervalLine.averageDelay < 0,
                      'red delay': intervalLine.averageDelay > 0
                    }"
                  >
                    {{ intervalLine.averageDelay | customTime }}
                  </div>
                </ion-row>
                <div *ngIf="this.infoLineDisruptionSubject | async as infoLineDisruption" [hidden]="infoLineDisruption.status !== 'NORMAL' && !hiddenContent">
                  <ion-row [class.bottom-info-mobile]="platformService.isPlatformMobile()" class="bottom-info">
                    <div [ngClass]="platformService.isPlatformMobile() ? 'info-line-delay-detail-mobile' : 'info-line-delay-detail'" class="track-information">
                      {{ 'app.realtime.traffic.info.line.card.body.delay.track.one' | translate }}
                      <span
                        [ngClass]="{
                          green: intervalLine.averageTrackOneDelay < 0,
                          'red delay': intervalLine.averageTrackOneDelay > 0
                        }"
                      >
                        {{ intervalLine.averageTrackOneDelay | customTime }}
                      </span>
                      {{ 'app.realtime.traffic.info.line.card.body.delay.track.two' | translate }}
                      <span
                        [ngClass]="{
                          green: intervalLine.averageTrackTwoDelay < 0,
                          'red delay': intervalLine.averageTrackTwoDelay > 0
                        }"
                      >
                        {{ intervalLine.averageTrackTwoDelay | customTime }}
                      </span>
                    </div>
                  </ion-row>
                </div>
              </ion-grid>
              <ion-grid *ngIf="intervalLine.averageDelay === null">
                <ion-row [ngClass]="platformService.isPlatformMobile() ? 'info-line-interval-percent-mobile' : 'info-line-interval-percent'">
                  <div class="grey">
                    {{ null | empty: '-' }}
                  </div>
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<ng-template #noInformationProvideForSecurityPrivileges>
  <ion-grid>
    <ion-row [ngClass]="platformService.isPlatformMobile() ? 'info-line-interval-percent-mobile' : 'info-line-interval-percent'">
      <span class="no-information-dash">-</span>
    </ion-row>
  </ion-grid>
</ng-template>
