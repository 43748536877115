<div class="schedule-peak-hour">
  <div class="schedule-peak-hour-content">
    <div class="schedule-peak-hour-metro">
      <ion-icon src="./assets/icon/metro.svg"></ion-icon>
      <div class="schedule-metro">
        <ion-text color="dark">
          <span class="schedule-title">{{ 'app.shared.dashboard.production.schedulePeakHour.schedule.peak.hour.morning' | translate }}</span>
          : {{ schedules.metro.peakPeriodMorning.start }} -
          {{ schedules.metro.peakPeriodMorning.end }}
        </ion-text>
        <ion-text>
          <span class="schedule-title">{{ 'app.shared.dashboard.production.schedulePeakHour.schedule.peak.hour.evening' | translate }}</span>
          : {{ schedules.metro.peakPeriodEvening.start }} -
          {{ schedules.metro.peakPeriodEvening.end }}
        </ion-text>
      </div>
    </div>
    <div class="schedule-peak-hour-rer">
      <ion-icon slot="end" src="./assets/icon/rer.svg"></ion-icon>
      <div class="schedule-rer">
        <ion-text color="dark">
          <span class="schedule-title">{{ 'app.shared.dashboard.production.schedulePeakHour.schedule.peak.hour.morning' | translate }}</span>
          : {{ schedules.rer.peakPeriodMorning.start }} -
          {{ schedules.rer.peakPeriodMorning.end }}
        </ion-text>
        <ion-text>
          <span class="schedule-title">{{ 'app.shared.dashboard.production.schedulePeakHour.schedule.peak.hour.evening' | translate }}</span>
          : {{ schedules.rer.peakPeriodEvening.start }} -
          {{ schedules.rer.peakPeriodEvening.end }}
        </ion-text>
      </div>
    </div>
  </div>
</div>
