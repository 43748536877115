import { Component, Input } from '@angular/core'
import { IntervalStatistics } from '../../core/models/interval'
import { PlatformService } from '../../core/services/platform.service'

@Component({
  selector: 'app-interval-global',
  templateUrl: './interval-global.component.html',
  styleUrls: ['./interval-global.component.scss'],
})
export class IntervalGlobalComponent {
  @Input() intervalStatistics: IntervalStatistics

  constructor(public platformService: PlatformService) {}
}
