import { Component, Input } from '@angular/core'
import { Disruption } from '../../core/models/disruptions'
import { PlatformService } from '../../core/services/platform.service'
import { TrafficSituation } from '../../core/models/trafic-situation'
import { environment } from '../../../environments/environment'
import { DatePipe } from '@angular/common'
import { generatePerturbationMessage } from '../messageTools'

export interface DisruptionWithTitle extends Disruption {
  title: string
}

@Component({
  selector: 'app-info-voyager',
  templateUrl: './info-voyager.component.html',
  styleUrls: ['./info-voyager.component.scss'],
})
export class InfoVoyagerComponent {
  @Input() trafficSituations: TrafficSituation[]
  constructor(public platformService: PlatformService, private datePipe: DatePipe) {}

  getLiveIVUrl(id: string) {
    return environment.trafficSituationLiveIVBaseUrl + '?panTo=' + encodeURIComponent(id) + '&panel=detail'
  }

  displayImpactedPlaces(trafficSituation: TrafficSituation) {
    const text = trafficSituation.natureLabel
    const type = trafficSituation.impactType
    const label1 = trafficSituation.impactedObjects[0].objectLabel
    const date = this.datePipe.transform(trafficSituation.created, 'HH:mm', 'ZZZZZ', 'fr')
    let label2 = ''
    if (type === 'SECTION') {
      label2 = trafficSituation.impactedObjects[1].objectLabel
    }
    return generatePerturbationMessage(type, text, label1, label2, date)
  }
}
