<ion-grid [class.no-padding]="platformService.isPlatformMobile()">
  <ion-row *ngIf="!!peakPeriodGlobal && objectKeys(peakPeriodGlobal).length > 0; else emptyGlobalH2P" [class.no-padding]="platformService.isPlatformMobile()">
    <ion-col *ngFor="let key of objectKeys(peakPeriodGlobal)" size="4">
      <app-peak-period-cartridge [peakPeriodKey]="key" [peakPeriodStatistics]="peakPeriodGlobal[key]"></app-peak-period-cartridge>
    </ion-col>
  </ion-row>
</ion-grid>
<ng-template #emptyGlobalH2P>
  <ion-row>
    <ion-col *ngFor="let key of objectKeys(emptyPeakPeriodGlobal)" size="4">
      <app-peak-period-cartridge [peakPeriodKey]="key" [peakPeriodStatistics]="emptyPeakPeriodGlobal[key]"></app-peak-period-cartridge>
    </ion-col>
  </ion-row>
</ng-template>
