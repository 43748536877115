import PathFollower = Phaser.GameObjects.PathFollower

export const tweenTrainConfiguration = (pathFollower: PathFollower) => {
  return {
    targets: pathFollower,
    t: 1,
    ease: 'Cubic', // 'Cubic', 'Elastic', 'Bounce', 'Back'
    duration: 1000,
    onComplete: (tween) => tween.remove(),
  }
}
