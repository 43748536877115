export enum SecurityPrivileges {
  TRAIN_INFO_COMPLETE = 'TRAIN_INFO_COMPLETE',
  TRAIN_INFO_FOR_ATTACHMENT_LINE = 'TRAIN_INFO_FOR_ATTACHMENT_LINE',
  TRAIN_INFO_UNAUTHORIZED = 'TRAIN_INFO_UNAUTHORIZED',
  TRAIN_TRACKING_COMPLETE = 'TRAIN_TRACKING_COMPLETE',
  TRAIN_TRACKING_WITHOUT_TRAIN_INFORMATION = 'TRAIN_TRACKING_WITHOUT_TRAIN_INFORMATION',
  TRAIN_TRACKING_UNAUTHORIZED = 'TRAIN_TRACKING_UNAUTHORIZED',
  TCO_COMPLETE = 'TCO_COMPLETE',
  TCO_UNAUTHORIZED = 'TCO_UNAUTHORIZED',
  LATE_ACTION_COMPLETE = 'LATE_ACTION_COMPLETE',
  LATE_UNAUTHORIZED = 'LATE_UNAUTHORIZED',
  PEAK_PERIOD_COMPLETE = 'PEAK_PERIOD_COMPLETE',
  PEAK_PERIOD_UNAUTHORIZED = 'PEAK_PERIOD_UNAUTHORIZED',
  OVER_PARKING_COMPLETE = 'OVER_PARKING_COMPLETE',
  OVER_PARKING_UNAUTHORIZED = 'OVER_PARKING_UNAUTHORIZED',
  ACCESS_ROLE_DENIED = 'ACCESS_ROLE_DENIED',
}

export interface AuthenticationInformation {
  iss: string
  sub: string
  first_name: string
  last_name: string
  line: string
  attachment: string
  attachment_name: string
  roles: SecurityPrivileges[]
  role_id: number
  management_code: string
  exp: number
  refreshToken?: boolean
  nameid: string
  department_code: string
}
