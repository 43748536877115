export enum TrainType {
  METRO,
  RER,
}

export function getTrainTypeFromLineName(lineName: string): TrainType {
  if (/\d/.test(lineName)) {
    return TrainType.METRO
  } else {
    return TrainType.RER
  }
}
